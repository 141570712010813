import React from "react";
import { reviewAuditJobGridSchema } from "./audit-inventory-review-audit-job-grid-configuration";
import { StringWms } from "../../../../assets/ts/StringWms";
import PropTypes from "prop-types";
import WMSGridCheckBoxSelection from "../../components/WMSGridCheckBoxSelection/WMSGridCheckBoxSelection";

import "./detail-sub-grid.scss";
interface Props {
    dataItem: any;
    gridId: string;
    onSetExpandGridData: Function;
    windowId: string | undefined;
    onSelection: Function;
}

declare var window: any;

const DetailSubGrid: React.FC<Props> = (props: Props) => {
    const titleArray = StringWms.convertSringToOption(
        window.translate("Audit_Inventory_Review_Audit_Job_GrideHeaders")
    );

    const defaultColumns = [
        {
            selectable: true,
            width: "30px",
            attributes: { class: "k-text-center" },
            headerAttributes: { class: "k-text-center" },
        },
        {
            field: "title",
            template: function (dataItem) {
                let str = "";
                if (dataItem?.AuditType !== "Item") {
                    str = str + `<label> ${dataItem.title.toUpperCase()}</label>`;
                }
                if (undefined !== dataItem?.skuble || null !== dataItem?.skuble) {
                    if (dataItem?.skuble?.Qualifier) {
                        str += `Qualifier: ${dataItem?.skuble?.Qualifier}<br/>`;
                    }
                    if (dataItem?.skuble?.LotNumber) {
                        str += `Lot: ${dataItem?.skuble?.LotNumber}<br/>`;
                    }
                    if (dataItem?.skuble?.SerialNumber) {
                        str += `Serial: ${dataItem?.skuble?.SerialNumber}<br/>`;
                    }
                    if (dataItem?.skuble?.ExpirationDate) {
                        str += `Exp Date: ${dataItem?.skuble?.ExpirationDate}<br/>`;
                    }
                }
                return str;
            },
            title: "",
            width: "80px",
            wmsid: 1,
            filterable: true,
        },
        {
            field: "systemCount",
            title: titleArray[1].description,
            width: "80px",
            wmsid: 2,
            filterable: true,
        },
        {
            field: "allocatedCount",
            title: titleArray[2].description,
            width: "80px",
            wmsid: 3,
            filterable: true,
        },
        {
            field: "physicalCount",
            title: titleArray[3].description,
            width: "80px",
            wmsid: 4,
            filterable: true,
        },
        {
            field: "variance",
            template: function (dataItem: any) {
                const color = dataItem.variance === 0 ? "black" : "red";
                return `<span style="color: ${color};">${dataItem.variance}</span>`;
            },
            title: titleArray[4].description,
            width: "80px",
            wmsid: 5,
            filterable: true,
        },
    ];

    const selectRow = (arrOfIds) => {
        props.onSelection(arrOfIds);
    };

    return (
        <div className="audit-inventory-review-audit-job-detail-sub-grid">
            {props.dataItem?.childReview?.length > 0 && (
                <WMSGridCheckBoxSelection
                    id={props.gridId}
                    columns={defaultColumns}
                    schema={reviewAuditJobGridSchema}
                    staticGridData={props.dataItem?.childReview?.length > 0 ? props.dataItem.childReview : []}
                    onSelectRow={selectRow}
                    bindSelectRow={true}
                    keyId={"id"}
                />
            )}
        </div>
    );
};

DetailSubGrid.propTypes = {
    dataItem: PropTypes.object.isRequired,
    gridId: PropTypes.string.isRequired,
    onSetExpandGridData: PropTypes.func,
    windowId: PropTypes.string,
    onSelection: PropTypes.func,
};

DetailSubGrid.defaultProps = {
    onSetExpandGridData: (): void => {},
    windowId: null,
    onSelection: (): void => {},
};

export { DetailSubGrid };
