import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import WmsButton from '../WmsButton/WmsButton.jsx'
import WmsCheckBox from '../WmsCheckBox/WmsCheckBox.jsx';
import WmsButtonDropdown from '../WmsButtonDropdown/WmsButtonDropdown.jsx'
import WmsMenuItem from '../WmsMenuItem/WmsMenuItem.jsx'
import * as GlobalService from '../../global_services';
import { KendoGridWms } from '../../../../assets/ts/KendoGridWms.ts';
import { ObjectWms } from '../../../../assets/ts/ObjectWms.ts';
import { ArrayWms } from '../../../../assets/ts/ArrayWms.ts';
import { StringWms } from '../../../../assets/ts/StringWms.ts';
import WmsContextMenu from '../WmsContextMenu/WmsContextMenu.jsx';
var axios = require('axios');
import style from "./WmsGrid.scss";

class WmsGrid extends React.PureComponent {
    constructor(props) {
        super(props);

        this._ismounted = false;
        this.state = {
            gridDomSelector: () => this.props.windowId ? '.' + this.props.windowId + ' #' + this.props.id : '#' + this.props.id,
            sortArrayGrid: [], // default set of sorts on the grid
            dataBindingInitial: 0,
            totalResult: 0,
            sortColums: '',
            layoutData: this.props.layoutData,
            disabledText: "",
            selectedRows: 0,
            currentSelectionRowsArr: [],
            selectAll: false,
            isHovering: false,
            KendoGrid: new KendoGridWms(this.props.id, this.props.windowId, this.props.gridKeySelection, this.props.getGridSelectionUrl),
            staticGridData: props.staticGridData,
            filterObject: props.filterObject,
            transportData: {},
            gridExpandIndex: null
        }

        this.EventClickResetGrid = this.EventClickResetGrid.bind(this);
        this.EventClickClearFilters = this.EventClickClearFilters.bind(this);
        this.EventClickClearSorts = this.EventClickClearSorts.bind(this);
        this.assembleGridObjectForKendoConsumption = this.assembleGridObjectForKendoConsumption.bind(this);
        this.eventMouseEnter = this.eventMouseEnter.bind(this);
        this.eventMouseLeave = this.eventMouseLeave.bind(this);
        this.beforeContextMenuOpen = this.beforeContextMenuOpen.bind(this);
        this.initGrid = this.initGrid.bind(this);
        this.updateGridSummary = this.updateGridSummary.bind(this);
        this.handleMouseHover = this.handleMouseHover.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.eventClickContextMenu = this.eventClickContextMenu.bind(this);

        // this.state = {
        //     facilityId: props.facilityId,
        //     gridSchema: props.gridSchema,
        //     gridColumn: props.gridColumn,
        //     KendoGrid: props.KendoGrid,
        //     GridOptions: props.GridOptions

        // }

        this.$rootScope = window;
        this.translate = window.translate
        // this.initGrid();

    }

    componentDidMount() {
        this._ismounted = true;
        // clear grid menu dropdown
        $(this.state.gridDomSelector() + "menu").kendoMenu();

        var that = this;
        if (this.props.virtual == true && typeof this.props.transport.read.data != "undefined") {
            this.setState({
                transportData: JSON.parse(JSON.stringify(this.props.transport.read.data))
            });
        }

        // prevent columns sorting when resize columns
        var isResizeHandler = false;
        $(this.state.gridDomSelector()).on('mousedown', '.k-grid-header-wrap', (event) => {
            isResizeHandler = $(event.target).hasClass("k-resize-handle");
        });

        // restring multiple sorting direct click on header
        $(this.state.gridDomSelector()).on("mouseup", "th>a.k-link", function (e) {
            if (!(e.ctrlKey || e.metaKey) && !isResizeHandler) {
                that.setState({
                    sortArrayGrid: []
                });
            }
            console.log("sortArrayGrid ", that.state.sortArrayGrid);
        });

        // lets get our grid options
        if ((this.props.layoutData === null || typeof (this.props.layoutData) === "undefined") && that.state.dataBindingInitial === 0) {
            axios.get('/WebUI/Shared/GridOptions/GetGridOptions?name=' + this.props.gridName).then(function (response) {
                that.initGrid(response.data);

              });
        }
        else {
            that.initGrid(this.props.layoutData);
        }
    }

    componentWillReceiveProps(nextProps) {
        var that = this;
        if (typeof nextProps.staticGridData != "undefined" && nextProps.staticGridData != this.state.staticGridData) {
            var grid = $(this.state.gridDomSelector()).data("kendoGrid");
            if (typeof grid != "undefined") {
                grid.dataSource.data(nextProps.staticGridData);

                that.state.KendoGrid.clearSelections(function () {
                    that.state.KendoGrid.repaintSelections();
                });
            }
            this.eventSelectAll("deselect");
            this.setState({
                staticGridData: nextProps.staticGridData
            })
        }

        if (typeof nextProps.filterObject != "undefined" && nextProps.filterObject != this.state.filterObject) {
            console.log("nextProps.filterObject ", nextProps.filterObject);
            if (this.props.inlineFilter) {
                var dataSource = $(this.state.gridDomSelector()).data("kendoGrid").dataSource;
                dataSource.filter(nextProps.filterObject);
                this.setState({
                    filterObject: nextProps.filterObject
                });
                this.eventSelectAll("deselect");
            } else {
                console.log("nextProps.filterObject : ", nextProps.filterObject[0]);
                GlobalService.GridSearchInfoSend(this.props.searchUrl, nextProps.filterObject[0]).then(
                    function successCallBack(response) {
                        if(!that._ismounted) {
                            // --- used only in Manage Item when reset customer
                            // --- Returning to avoid memory-leak on reset of item grid
                            return;
                        }
                        var grid = $(that.state.gridDomSelector()).data('kendoGrid');
                        GlobalService.refreshGridForChangedNumberOfRows(grid);
                        that.setState({
                            SelectAll: false
                        })
                        that.state.KendoGrid.setSelectAllCheckBox(that.state.SelectAll);
                        that.state.KendoGrid.clearSelections(function () {
                            that.state.KendoGrid.repaintSelections();
                        });
                        that.eventSelectAll("deselect");
                        // $scope.currentSelections = KendoGrid.getNumberOfSelections();
                    },
                    function errorCallBack(response) {
                        console.log(response);
                    }
                );
                this.setState({
                    filterObject: nextProps.filterObject
                });
            }
        }

        //when update transport facility Id for location
        if (typeof nextProps.transport != "undefined" && nextProps.transport.read && nextProps.transport.read.data && JSON.stringify(nextProps.transport.read.data) != JSON.stringify(this.state.transportData)) {
            var grid = $(this.state.gridDomSelector()).data('kendoGrid');
            this.eventSelectAll("deselect");
            this.setState({
                transportData: JSON.parse(JSON.stringify(nextProps.transport.read.data))
            })
            if (typeof grid != "undefined" && grid.dataSource.transport.options) {
                grid.dataSource.transport.options.read.data = nextProps.transport.read.data;
                grid.dataSource.read();
            }
        }
        if(nextProps.isUpdateGridSummary == true &&  nextProps.isUpdateGridSummary != this.props.isUpdateGridSummary){
            this.updateGridSummary();
        }
        if(nextProps.emptyRecoredText != this.props.emptyRecoredText){
            var grid = $(this.state.gridDomSelector()).data("kendoGrid");
            let tmpOption =  grid.options;
            tmpOption.noRecords={ template: nextProps.emptyRecoredText };
            console.log("tmpOption",tmpOption);
            grid.setOptions(tmpOption);
        }
    }
    componentWillUnmount() {
        this._ismounted = false;
     }

    initGrid(layoutData) {

        var tempObject = this.props.columns;
        if(this.props.isAlignIndexesByProperty && layoutData){
            var parsingReturn = JSON.parse(layoutData);
            tempObject =  ObjectWms.alignIndexesByProperty(parsingReturn.columns, this.props.columns, "field");
        }

        const finalObj = this.state.KendoGrid.getGridOptions({ columns: tempObject }, this.assembleGridObjectForKendoConsumption(this.props), layoutData);
        // If we have a window id we need to use this when we initialize the grid
        $(this.state.gridDomSelector()).kendoGrid(finalObj);
        let grid = $(this.state.gridDomSelector()).data('kendoGrid');
        if (typeof grid == "undefined") {
            return true;
        }
        var sortArr = grid.dataSource.sort() ? grid.dataSource.sort() : [];
        if (sortArr.length >= 1) {
            this.setState({ sortArrayGrid: sortArr });
        }
        this.props.onLoadGrid();
    }

    assembleGridObjectForKendoConsumption(obj) {
        let ok = obj;
        var that = this;
        // DEFAULT SETUP FOR THE OBJECT, WE WILL DO CONDITIONALLY SETUP AFTERWORDS
        let returnobj = {
            scrollable: {
                virtual: true
            },
            columnMenu: {
                sortable: false
            },
            height: "99%",
            columnMenuInit: function (e) {
                if (that.props.isBindFilterColumnHoverEvent) {
                    var menu = e.container.find(".k-menu");
                    menu.on('mouseenter', function () {
                        window.setHigherElementsOnGridColumnInit(false)
                    });
                    menu.on('mouseleave', function () {
                        setTimeout(function () {
                            window.setHigherElementsOnGridColumnInit(true)
                        }, 100);
                    });
                }
                if (that.props.isColumnFilterenable) {
                    var filterMenu = e.container.find(".k-filter-menu");
                    // Set class to filter form of grit to reset specific grid column
                    if (!filterMenu.hasClass(that.props.id + 'FilterForm')) {
                        filterMenu.addClass(that.props.id + 'FilterForm');
                    }
                }

                if (!that.props.isColumnShowenable) {
                    var item = e.container.find(".k-columns-item");
                    item.prev(".k-separator").remove();
                    item.remove();
                }
                // let switchedArray = ArrayWms.switchIndexByField(
                //     obj.columns,
                //     e.newIndex,
                //     e.oldIndex
                // );
                // console.log("switchedArray : ",obj.columns);
                // console.log("switchedArray : ",switchedArray);
                // let finalSubmission = that.prepareGridOptionsForStorage(
                //     obj.columns,
                //     that.state.sortArrayGrid
                // );
                // GlobalService.setGridOptions(that.props.gridName, finalSubmission).then(
                //     function successCallBack(response) { },
                //     function errorCallBack(response) {
                //         console.log(response);
                //     }
                // );
            },
            filterable: this.props.showColumnFilter,
            sortable: {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            },
            resizable: true,
            reorderable: true,
            selectable: "multiple, row",
            navigatable: this.props.gridNavigatable,
            editable: this.props.gridEditable,
            remove: function(e) {
                // e.preventDefault();
                that.props.onRemove(e);
            },
            sort: function (e) {
                e.preventDefault();
                GlobalService.sortDataset(that.state.sortArrayGrid, e.sort);
                this.dataSource.sort(that.state.sortArrayGrid);
                var gridColumn = $(that.state.gridDomSelector()).data("kendoGrid").getOptions().columns;
                var finalSubmission = that.state.KendoGrid.prepareGridOptionsForStorage(gridColumn, that.state.sortArrayGrid);
                if (that.props.layoutData === null || typeof (that.props.layoutData) === "undefined"){
                    GlobalService.setGridOptions(that.props.gridName, finalSubmission)
                    .then(function successCallBack(response) {

                    }, function errorCallBack(response) {
                        console.log("Grid w sort ", response);
                    });
                }else{
                    that.props.onSort(finalSubmission);
                }

            },
            save: function (data) {
                that.props.onGridSave(data);
            },
            columnShow: function (e) {
                let gridData = $(that.state.gridDomSelector()).data("kendoGrid");
                let columnIndex = ObjectWms.FindIndexByProperty(
                    gridData.columns,
                    "field",
                    e.column.field
                );

                gridData.columns[columnIndex].hidden = false;

                let finalSubmission = that.prepareGridOptionsForStorage(
                    gridData.columns,
                    that.state.sortArrayGrid
                );
                if (that.props.layoutData === null || typeof (that.props.layoutData) === "undefined"){
                    GlobalService.setGridOptions(that.props.gridName, finalSubmission).then(
                        function successCallBack(response) { },
                        function errorCallBack(response) {
                            console.log(response);
                        }
                    );
                }else{
                    that.props.onColumnShow(finalSubmission);
                }
            },
            columnHide: function (e) {
                let gridData = $(that.state.gridDomSelector()).data("kendoGrid");
                let columnIndex = ObjectWms.FindIndexByProperty(
                    gridData.columns,
                    "field",
                    e.column.field
                );

                gridData.columns[columnIndex].hidden = true;

                let finalSubmission = that.prepareGridOptionsForStorage(
                    gridData.columns,
                    that.state.sortArrayGrid
                );
                if (that.props.layoutData === null || typeof (that.props.layoutData) === "undefined"){
                    GlobalService.setGridOptions(that.props.gridName, finalSubmission).then(
                        function successCallBack(response) { },
                        function errorCallBack(response) {
                            console.log(response);
                        }
                    );
                }else{
                    that.props.onColumnHide(finalSubmission);
                }
            },
            columnReorder: function (e) {
                let gridColumn = $(that.state.gridDomSelector()).data("kendoGrid").getOptions().columns;
                let switchedArray = ArrayWms.switchIndexByField(gridColumn, e.newIndex, e.oldIndex);
                let finalSubmission = that.state.KendoGrid.prepareGridOptionsForStorage(switchedArray, that.state.sortArrayGrid);
                if (that.props.layoutData === null || typeof (that.props.layoutData) === "undefined"){
                    GlobalService.setGridOptions(that.props.gridName, finalSubmission).then(
                        function successCallBack(response) { },
                        function errorCallBack(response) {
                            console.log(response);
                        }
                    );
                }else{
                    that.props.onColumnReorder(finalSubmission);
                }
            },

            columnResize: function (e) {
                let gridData = $(that.state.gridDomSelector()).data("kendoGrid");
                let columnIndex = ObjectWms.FindIndexByProperty(
                    gridData.columns,
                    "field",
                    e.column.field
                );
                gridData.columns[columnIndex].width = e.newWidth;

                let finalSubmission = that.prepareGridOptionsForStorage(
                    gridData.columns,
                    that.state.sortArrayGrid
                );
                if (that.props.layoutData === null || typeof (that.props.layoutData) === "undefined"){
                    GlobalService.setGridOptions(that.props.gridName, finalSubmission).then(
                        function successCallBack(response) { },
                        function errorCallBack(response) {
                            console.log(response);
                        }
                    );
                }else{
                    that.props.onColumnResize(finalSubmission);
                }
            },
            dataBinding: function (e) {
                that.state.KendoGrid.repaintSelections();
                if (that.state.dataBindingInitial == 0) {
                    let grid = $(that.state.gridDomSelector()).data('kendoGrid');
                    // var sortArr = grid.dataSource.sort() ? grid.dataSource.sort() : [];
                    // that.setState({
                    //     sortArrayGrid: sortArr
                    // });
                    if (typeof grid == "undefined") {
                        return true;
                    }
                    grid.table.on('click', function (e) {
                        // Hack: prevent parent grid row selection when click on child grid row
                        if ($(e.target).parents('.k-detail-row').length > 0) {
                            return;
                        }

                        var col = grid.cellIndex($(e.target).closest('td'));
                        // grid selection remain when click on edit cell
                        if (
                            that.props.isRemainSelectionForEditCell &&
                            grid.dataSource.options.schema &&
                            grid.dataSource.options.schema.model &&
                            col != -1 &&
                            grid.dataSource.options.schema.model.fields[grid.columns[col].field].editable
                        ) {
                            that.onReSelectRow();
                            return;
                        }

                        // if it is a regular click or shift click we want to deselect everything if selectall is selection, $scope.EventSelectAll checks if this is
                        // on or not so calling it doesn't guarantee execution it just checks and does it if it needs it
                        // ctrl click we don't want to do it because it can add or deselect

                        if (e.ctrlKey || e.metaKey) {
                            that.eventSelectAll("deselect", "dontclearselection");
                        } else {
                            that.eventSelectAll("deselect");
                        }
                        that.state.KendoGrid.setSelectAllCheckBox(that.state.selectAll);

                        var gridData = grid.dataSource.view();
                        var dataItem = grid.dataItem($(e.target).closest('tr'));
                        var currentPage = grid.dataSource.page();
                        var currentNumberOfItems = grid.dataSource.view().length;
                        var row = $(e.target).closest('tr').index();
                        var col = grid.cellIndex($(e.target).closest('td'));
                        that.state.KendoGrid.clickEventHandleSelection(e, currentPage, currentNumberOfItems, row, col, dataItem, grid.dataSource.data(), that.props.gridKeySelection, function () {
                            that.updateGridSummary();
                            that.props.onSelection(that.state.KendoGrid);
                            // once we removed the synchronous call this gets called before the end of the of the element
                            /*

                            var currentSelections = KendoGridFO.getNumberOfSelections();
                            if (currentSelections == 1) {
                                if (dataItem.IsClosed == true) {
                              //      $scope.IsClosed = true;
                                } else {
                              //      $scope.IsClosed = false;
                                }
                                if (dataItem.CanComplete == true) {
                               //     $scope.ReadyToShip = false;
                                } else {
                               //     $scope.ReadyToShip = true;
                                }
                               // $scope.contextMenuSelection();
                            }
                           // $scope.$apply();
                           */
                        });
                    });
                    grid.table.on("keydown", function (e) {
                        if(e.keyCode == 38 || e.keyCode == 40){
                            var dataItem = grid.dataItem($("#"+that.props.id+"_active_cell").closest("tr"));
                            var currentPage = grid.dataSource.page();
                            var currentNumberOfItems = grid.dataSource.view().length;
                            var row = $("#"+that.props.id+"_active_cell").closest("tr").index();
                            var col = grid.cellIndex($(e.target).closest('td'));
                            that.state.KendoGrid.repaintSelections();
                            that.state.KendoGrid.clickEventHandleSelection(e, currentPage, currentNumberOfItems, row, col, dataItem, grid.dataSource.data(), that.props.gridKeySelection, function () {
                                that.updateGridSummary();
                                that.props.onSelection(that.state.KendoGrid);
                            });
                        }
                    });
                    // set selection in currentSelectionRowsArr and its used to reselect row on click
                    if (that.props.isRemainSelectionForEditCell) {
                        $(that.state.gridDomSelector() + "  tbody").on("click", "tr", (e) => {
                            that.state.currentSelectionRowsArr = Object.assign([], $(that.state.gridDomSelector()).find("tbody tr.k-state-selected"));
                        });
                    }
                    that.state.dataBindingInitial = 1;
                    // $(that.state.gridDomSelector()).data("kendoGrid").dataSource.sort(that.state.sortArrayGrid);
                }
                that.setState({
                    sortColums: GlobalService.getSortedColumnName(that.state.sortArrayGrid, that.props.columns),
                    totalResult: this.dataSource.total()
                });
                that.props.onGridBinding();

                /*
                 KendoGridFO.repaintSelections();
                 if ($scope.InitialFindOrdersGrid == 0) {
                 var grid = $('#findOrdersGrid').data('kendoGrid');
                 grid.table.on('click', function (e) {
                 // if it is a regular click or shift click we want to deselect everything if selectall is selection, $scope.EventSelectAll checks if this is
                 // on or not so calling it doesn't guarantee execution it just checks and does it if it needs it
                 // ctrl click we don't want to do it because it can add or deselect
                 if (e.ctrlKey) {
                 $scope.EventSelectAll("deselect", "dontclearselection");
                 } else {
                 $scope.EventSelectAll("deselect");
                 }
                 KendoGridFO.setSelectAllCheckBox($scope.FindOrders.SelectAll);
                 var gridData = grid.dataSource.view();
                 var dataItem = grid.dataItem($(e.target).closest('tr'));
                 var currentPage = grid.dataSource.page();
                 var currentNumberOfItems = grid.dataSource.view().length;
                 var row = $(e.target).closest('tr').index();
                 var col = grid.cellIndex($(e.target).closest('td'));

                 KendoGridFO.clickEventHandleSelection(e, currentPage, currentNumberOfItems, row, col, dataItem, grid.dataSource.data(), 'OrderId', function () {
                 // once we removed the synchronous call this gets called before the end of the of the element
                 UpdateGridSummary();
                 var currentSelections = KendoGridFO.getNumberOfSelections();
                 if (currentSelections == 1) {
                 if (dataItem.IsClosed == true) {
                 $scope.IsClosed = true;
                 } else {
                 $scope.IsClosed = false;
                 }
                 if (dataItem.CanComplete == true) {
                 $scope.ReadyToShip = false;
                 } else {
                 $scope.ReadyToShip = true;
                 }
                 $scope.contextMenuSelection();
                 }
                 $scope.$apply();
                 });
                 });
                 $scope.InitialFindOrdersGrid = 1;
                 }

                 var TotalOrders = this.dataSource.total();

                 $timeout(function () {
                 $scope.TotalOrders = TotalOrders;
                 $scope.enableExportGrid = false;
                 var grid = $('#findOrdersGrid').data('kendoGrid');
                 $scope.TotalInLineColumnFilters = KendoWms.getLengthOffFilters(grid.dataSource.filter());
                 }, 0);

                 var grid = $('#findOrdersGrid').data('kendoGrid');
                 var gridData = grid.dataSource.view();
                 for (var i = 0; i < gridData.length; i++) {
                 if (gridData[i].FullyAllocated == false) {
                 grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-over-allocated");
                 }
                 }
                 */
                if (that.props.gridName == "GridMassAdd") {
                    var grid = $(that.state.gridDomSelector()).data('kendoGrid');
                    var gridData = grid.dataSource.view();
                    var myCol = that.state.KendoGrid.getQuantityCollection();
                    var myCollection = that.state.KendoGrid.getDictionary().items;

                    for (var i = 0; i < gridData.length; i++) {
                        var key = gridData[i].Key;
                        if (myCollection.hasOwnProperty(key)) {
                            $('.k-grid-content-locked table tr[data-uid="' + gridData[i].uid + '"] td').children("input").val(myCol[key]);
                        }
                    }
                }
            },
            edit: (e) => {
                this.props.onEdit(e);
            },
            // save: (e) => {
            //   this.props.onSave(e);
            // },
            change: (e) => {
              this.props.onChange(e);
            },
            dataBound: function (e) {
                that.state.KendoGrid.repaintSelections();
                that.closeContextMenu();

                if (that.props.gridName == "GridCustomOrderInfo") {
                    var grid = $(that.state.gridDomSelector()).data('kendoGrid');
                    var gridData = grid.dataSource.view();
                    var nameColumnIndex = this.wrapper.find(".k-grid-header [data-field=" + "Name" + "]").index();
                    var valueColumnIndex = this.wrapper.find(".k-grid-header [data-field=" + "Value" + "]").index();

                    for (var i = 0; i < gridData.length; i++) {
                        var row = grid.table.find("[data-uid='" + gridData[i].uid + "']");
                        var nameCell = row.children().eq(nameColumnIndex);
                        var valueCell = row.children().eq(valueColumnIndex);
                        if (gridData[i].Required) {
                            console.log('gridData[' + i + '].Required', gridData[i].Required);
                            row.addClass("wms-required-grid-row");
                            nameCell.addClass("wms-required-grid");
                            valueCell.addClass("wms-required-grid-cell");
                            row.css('font-style', 'italic');
                        }

                        if (gridData[i].Required && !gridData[i].Value) {
                            nameCell.addClass("wms-required-grid-border");
                        }
                    }
                } else if (that.props.gridName == "GridMassAdd") {
                    var grid = $(that.state.gridDomSelector()).data('kendoGrid');
                    grid.table.on('keydown', function (e) {
                        if (e.keyCode === kendo.keys.TAB && $($(e.target).closest('.k-edit-cell'))[0]) {
                            e.preventDefault();
                            var currentNumberOfItems = grid.dataSource.view().length;
                            var row = $(e.target).closest('tr').index();
                            var col = grid.cellIndex($(e.target).closest('td'));
                            var dataItem = grid.dataItem($(e.target).closest('tr'));
                            var field = grid.columns[col].field;
                            var value = $(e.target).val();

                            if (StringWms.exists(value)) {
                                dataItem.set(field, value);
                            }
                            if (row >= 0 && row < currentNumberOfItems && col >= 0 && col < grid.columns.length) {
                                var nextCellRow;
                                var nextCellCol = 0;
                                if (e.shiftKey) {
                                    nextCellRow = row - 1;
                                } else {
                                    nextCellRow = row + 1;
                                }
                                if (nextCellRow >= currentNumberOfItems || nextCellRow < 0) {
                                    return;
                                }
                                // wait for cell to close and Grid to rebind when changes have been made
                                setTimeout(function () {
                                    grid.editCell(grid.tbody.find("tr:eq(" + nextCellRow + ") td:eq(" + nextCellCol + ")"));
                                });
                            }
                        }
                    });
                } else if (that.props.gridName == "OrderItemsGrid") {
                    //order Item grid pass grid index for expand particular row after child element save
                    that.props.onDataBound(e, that.state.gridExpandIndex);
                    that.setState({ gridExpandIndex: null });
                } else if (that.props.gridName == "GridAppliedCharges") {
                    that.props.onDataBound(e, that.state.KendoGrid);
                } else {
                    that.props.onDataBound(e);
                }
            }
        };
        if (obj.gridHeight) {
            returnobj.height = obj.gridHeight;
        }
        if (obj.detailTemplate) {
            returnobj.detailTemplate = "<div class='detailTemplate'>Test</div>";
            returnobj.detailInit = that.detailInit;
            returnobj.detailExpand= function(e) {
                setTimeout(() => {
                    $(that.state.gridDomSelector()).data('kendoGrid').resize(true);
                }, 5);
            };
            returnobj.detailCollapse= function(e) {
                setTimeout(() => {
                    $(that.state.gridDomSelector()).data('kendoGrid').resize(true);
                }, 5);
            }
        }
        if (obj.emptyRecoredText) {
            returnobj.noRecords = {
                template: obj.emptyRecoredText
            }
        }
        // LETS START WITH VIRTUALIZED GRID OR NOT
        if (obj.virtual == true) {
            returnobj.dataSource = {
                pageSize: this.props.virtualGridpageSize,
                serverPaging: true,
                serverSorting: this.props.isServerSorting,
                serverFiltering: this.props.isServerFiltering,
                type: "aspnetmvc-ajax",
                change: (data) => {
                    // console.log("success");
                    this.props.onGridChange(data)
                },
                error: function (xhr, error) { }
            }
            returnobj.dataSource.schema = obj.schema;
            // lets attach the transport layer of the dataSrouce
            returnobj.dataSource.transport = obj.transport;
            return returnobj;
        } else if (obj.staticDataVirtualization == true) {
            returnobj.dataSource = {
                pageSize: 40,
                transport: {
                    read: function (e) {
                        e.success(that.state.staticGridData);
                    }
                },
                schema: obj.schema,
                change: (data) => {
                    // console.log("success");
                    this.props.onGridChange(data)
                },
            }
            return returnobj;
        } else {
            returnobj.dataSource = {
                data: obj.staticGridData,
                schema: obj.schema,
                change: (data) => {
                    this.props.onGridChange(data)
                },
            }
            returnobj.dataSource.transport = obj.transport;
            if(this.props.isAddBatchProperty){
                returnobj.dataSource.batch= this.props.isAddBatchProperty;
            }
            return returnobj;
        }
    }

    onReSelectRow = () => {
        if (this.state.currentSelectionRowsArr.length > 1) {
            var grid = $(this.state.gridDomSelector()).data("kendoGrid");
            grid.select(this.state.currentSelectionRowsArr);
        }
    }

    detailInit = (e) => {
        if (this.props.detailTemplate) {
            var detailTemplate = React.createFactory(this.props.detailTemplate);
            let propsForDetailGrid = {
                dataItem: e.data,
                gridId: e.data.uid,
                windowId: this.props.windowId,
                onSetExpandGridData: this.onSetExpandGridData,
                onSelection: this.props.onSubGridSelection
            };
            ReactDOM.render(detailTemplate(propsForDetailGrid), $(e.detailRow[0]).find('.detailTemplate')[0])
        }
    }
    // order line item grid set expand grid index after line item info saved
    onSetExpandGridData = (param_expandIndex) => {
        this.setState({
            gridExpandIndex: param_expandIndex
        }, () => {
            let orderLineItemsGrid = $(this.state.gridDomSelector()).data('kendoGrid');
            orderLineItemsGrid.dataSource.read();
        })
    }
    prepareGridOptionsForStorage(passedObj, sortArr) {
        var reducedObj;
        reducedObj = {
            columns: [],
            sorts: []
        };
        for (var x = 0; x < passedObj.length; x++) {
            if (passedObj[x].hasOwnProperty("field")) {
                reducedObj.columns[x] = {
                    field: passedObj[x].field
                };
            }
            if (passedObj[x].hasOwnProperty("width")) {
                reducedObj.columns[x].width = passedObj[x].width;
            }
            if (passedObj[x].hasOwnProperty("wmsid")) {
                reducedObj.columns[x].wmsid = passedObj[x].wmsid;
            }
            if (passedObj[x].hasOwnProperty("hidden")) {
                reducedObj.columns[x].hidden = passedObj[x].hidden;
            }
        }

        reducedObj.sorts = sortArr;

        var finalreturn = {
            optionsData: JSON.stringify(reducedObj)
        };
        return finalreturn;
    }

    onChangeFunc(event) {
        var that = this;
        this.setState({
            selectAll: event.target.checked
        });
        setTimeout(function () {
            that.eventSelectAll();
        }, 0);
    }

    eventSelectAll(pass, dontclearselection) {
        var that = this;
        if (pass == "deselect") {
            if (that.state.selectAll == true) {
                that.setState({ selectAll: false }); // clear select all checkbox
                if (dontclearselection == "dontclearselection") {
                    // we just want to clear the select box above here
                } else {
                    that.state.KendoGrid.clearSelections(function () {
                        that.updateGridSummary();
                        that.state.KendoGrid.repaintSelections();
                    });
                }
            }
        } else {
            if (that.state.selectAll == false) {
                that.state.KendoGrid.clearSelections(function () {
                    that.updateGridSummary();
                    that.state.KendoGrid.repaintSelections();
                });
                that.props.onSelection(that.state.KendoGrid);
            } else if (that.state.selectAll == true) {
                if (this.props.inlineSelectAll) {
                    var mygrid = $(this.state.gridDomSelector()).data("kendoGrid");
                    if (typeof mygrid != "undefined") {
                        mygrid.select("tr");
                    }
                    this.setState({ selectedRows: mygrid.select().length }); // For Inline select all set selection row state
                    that.props.onSelection(that.state.KendoGrid);
                } else {
                    that.state.KendoGrid.getAllRecordsFromApi(function () {
                        that.updateGridSummary();
                        that.props.onSelection(that.state.KendoGrid);
                    });
                }
            }

        }
        if (this.props.inlineSelectAll) {
            this.props.onSelectAllChange();
        }

    }

    updateGridSummary() {
        this.setState({ selectedRows: this.state.KendoGrid.getNumberOfSelections() });
        this.props.onUpdateGridSummary();
    }

    EventClickResetGrid() {
        var that = this;
        kendo.ui.progress($(this.state.gridDomSelector()), true);
        that.state.dataBindingInitial = 0;
        if (that.props.layoutData === null || typeof (that.props.layoutData) === "undefined"){
            GlobalService.resetGrid(that.props.gridName).then(function successCallBack(response) {
                that.clearGridConfig();
            }).finally(() => {
                kendo.ui.progress($(this.state.gridDomSelector()), false);
            });
        }else{
            that.clearGridConfig();
            that.props.onResetGrid();
        }

    }
    clearGridConfig = () =>{
        var column = {
            columns: this.props.columns
        };
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        grid.setOptions(column);
        grid.dataSource.sort({});
        this.state.sortColums = '';
        this.state.sortArrayGrid = [];
        this.state.KendoGrid.clearSelections( () => {
            this.state.KendoGrid.repaintSelections();
        });
        kendo.ui.progress($(this.state.gridDomSelector()), false);
        this.updateGridSummary();
    }
    EventClickClearFilters() {
        var grid = $(this.state.gridDomSelector()).data("kendoGrid");
        grid.dataSource.filter({});
        this.eventSelectAll("deselect");
    }

    EventClickClearSorts() {
        var gridData = $(this.state.gridDomSelector()).data('kendoGrid');
        gridData.dataSource.sort({});
        this.setState({
            sortColums: '',
            sortArrayGrid: []
        });
        var finalSubmission = this.prepareGridOptionsForStorage(gridData.columns, []);
        GlobalService.setGridOptions(this.props.gridName, finalSubmission).then(function successCallBack(response) {
        }, function errorCallBack(response) {
            console.log(response);
        });
    }

    eventClickContextMenu(obj) {
        this.props.clickEventOfContextMenu(obj);
    }

    eventMouseEnter(text) {
        this.setState({
            disabledText: text
        })
    }

    eventMouseLeave() {
        this.setState({
            disabledText: ""
        })
    }
    // method for context menu right click
    beforeContextMenuOpen(e) {
        //Hack: prevent context menu when click on row detail section
        if ($(e.target).parents('.k-detail-row').length > 0 || $(e.target).hasClass('k-detail-row')) {
            e.preventDefault();
            return;
        }

        var grid = $(this.state.gridDomSelector()).data('kendoGrid');
        var dataItem = grid.dataItem($(e.target).closest('tr'));

        var page = grid.dataSource.page();
        var length = grid.dataSource.view().length;
        var col = grid.cellIndex($(e.target).closest('td'));

        var row2 = $(e.target).closest('tr').index();
        var that = this;
        this.state.KendoGrid.onRightClick(e, page, length, row2, col, dataItem, grid.dataSource.data(), this.props.gridKeySelection, function () {
            that.updateGridSummary();
            that.props.onSelection(that.state.KendoGrid);
        });

    }
    handleMouseHover() {
        this.setState({ isHovering: true });
    }

    handleMouseLeave() {
        this.setState({ isHovering: false });
    }

    closeContextMenu = () => {
        if (this.refs.GridWmsContextMenu && this.refs.GridWmsContextMenu.state.isOpen) {
            this.refs.GridWmsContextMenu.closeWmsContextMenu();
        }
    }


    render() {
        return (
            <div className='wms-grid-wrapper'>
                <div className='table-background-image'/>
                <WmsContextMenu
                    ref="GridWmsContextMenu"
                    eventClickContextMenu={this.eventClickContextMenu}
                    attachTo={this.state.gridDomSelector()}
                    menu={this.props.menu}
                    eventMouseEnter={this.eventMouseEnter}
                    eventMouseLeave={this.eventMouseLeave}
                    onBeforeOpen={(e) => this.beforeContextMenuOpen(e)}
                    contextMenuFilter={this.props.contextMenuFilter} />
                {this.props.showErrorMessageSection && (
                    <div className="wms-grid-message">
                        {this.props.gifLoaderText != '' &&
                            <div className={"wms-grid-alert-msg note loading-text"}>
                                <img src="assets/images/Spinner-1s-35px.gif" />
                                <span>{this.props.gifLoaderText} in progress</span>
                            </div>
                        }
                        <label className="wms-grid-alert-msg">{this.state.disabledText}</label>
                    </div>
                )}
                <div className="wms-grid-toolbar">
                    {this.props.shawCheckbox && (
                        <div className="wms-grid-checkbox-content">
                            <WmsCheckBox
                                id={"SelectAll" + this.props.id}
                                name={"SelectAll" + this.props.id}
                                onChangeFunc={this.onChangeFunc.bind(this)}
                                label={this.translate('Label_Select_All')}
                                checked={this.state.selectAll}
                                value={this.state.selectAll}
                                disabled={this.state.totalResult >= GlobalService.constants.virtualizedGridLimit}
                                parentClassName={
                                    this.state.totalResult >= GlobalService.constants.virtualizedGridLimit
                                        ? "k-state-disabled wms-grid-checkbox-input "
                                        : "wms-grid-checkbox-input "
                                }
                                wmsSelector={this.props.wmsSelector + '_SelectAll'}
                                isRounded={true} />
                            {this.state.totalResult >= GlobalService.constants.virtualizedGridLimit && (<div className="wms-toolbar-btn-block">
                                <div className="wms-grid-div-disable" onMouseEnter={this.handleMouseHover}
                                    onMouseLeave={this.handleMouseLeave}>&nbsp;</div>
                                {this.state.isHovering == true && (<div onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseLeave} className="wms-grid-div-tooltip">To enable, click less than {GlobalService.constants.virtualizedGridLimit}</div>)}
                            </div>)}
                        </div>
                    )}
                        <div className="wms-toolbar-button ">
                            {this.props.menu.map((obj, index) =>
                                (typeof obj.isButtonMenu == "undefined" || obj.isButtonMenu == true) ?
                                    obj.value.length != 0 ?
                                        <WmsButtonDropdown
                                            windowId={this.props.windowId}
                                            key={index}
                                            wmsSelector={obj.wmsSelector}
                                            id={obj.name.split(' ').join('')}
                                            disabled={obj.disabled}
                                            label={obj.name}
                                            menuIcon={obj.icon}
                                            isHidden={obj.isHidden ? obj.isHidden : false}
                                            buttonType={obj.color}>
                                            <WmsMenuItem
                                                eventMouseEnter={this.eventMouseEnter}
                                                eventMouseLeave={this.eventMouseLeave}
                                                items={obj.value} />
                                        </WmsButtonDropdown> :
                                        (obj.isButtonShaw != false &&
                                            <WmsButton
                                                key={index}
                                                wmsSelector={obj.wmsSelector}
                                                disabled={obj.disabled}
                                                icon={obj.icon}
                                                label={obj.name}
                                                buttonType={obj.color}
                                                onClickFunc={obj.clickFunc}
                                                loading={obj.loading}
                                                showToolTip={obj.showToolTip}
                                                tooltipText={obj.tooltipText}
                                                parentClassName={obj.parentClassName ? obj.parentClassName : ''} />
                                        )
                                    : ""
                            )}
                        {this.props.showResetButton == true &&
                        (<div className="wms-grid-reset-btn">
                                <ul
                                    kendo-menu={this.props.id + "kendoMenu"}
                                    id={this.props.id + "menu"}
                                    k-orientation="'horizontal'"
                                    className="GridMenu"
                                    data-wms-selector={this.props.wmsSelector + '_resetMenu'}
                                >
                                    <li className="GridMenuTopLink">
                                        <span className="options-title"> <i className={"fa " + this.props.resetButtonIcon}aria-hidden="true"></i> <span className="wms-grid-options">{this.props.resetButtonLabel}</span></span>
                                        <ul>
                                            <li onClick={this.EventClickResetGrid} data-wms-selector={this.props.wmsSelector + '_reset'}>
                                                <span>{this.translate("Label_Reset_Grid")}</span>
                                            </li>
                                            {this.props.showColumnFilter && (
                                                <li onClick={this.EventClickClearFilters} data-wms-selector={this.props.wmsSelector + '_clearFilter'}>
                                                    <span>{this.translate("Label_Clear_Column_Filters")}</span>
                                                </li>)
                                            }
                                            {this.props.showSortColumns && (
                                                <li onClick={this.EventClickClearSorts} data-wms-selector={this.props.wmsSelector + '_clearSort'}>
                                                    <span>{this.translate("Label_Clear_Sort")}</span>
                                                </li>)
                                            }
                                            {this.props.exportable ? (
                                                <li
                                                    onMouseLeave={
                                                        this.props.exportDisable == true ?
                                                            this.eventMouseLeave :
                                                            () => function () { }
                                                    }
                                                    onMouseEnter={
                                                        this.props.exportDisable == true ?
                                                            () => this.eventMouseEnter(this.props.exportGridDisabledText) :
                                                            () => function () { }
                                                    }
                                                    test="Helloooo"
                                                    onClick={this.props.exportDisable ? () => function () { } : this.props.eventClickExportGrid}
                                                    data-wms-selector={this.props.wmsSelector + '_exportGrid'}
                                                    className={(this.props.exportDisable ? 'wms-disabled-look' : 'k-state-default') + ' k-item ExportGrid'}
                                                >
                                                    <span>{this.translate("Export Grid")}</span>
                                                </li>
                                            ) : null}
                                        </ul>
                                    </li>
                                </ul>
                            </div>)
                        }
                    </div>

                </div>
                {this.props.showGridSummary &&
                    <div className="wms-grid-top">
                        {this.props.showGridResult == true &&
                            <label className="wms-grid-title result-info-wrapper"><span>{this.state.totalResult} </span>Results</label>
                        }
                        <label className="wms-grid-title select-info-wrapper"><span>{this.state.selectedRows} </span>selected</label>
                        {this.state.sortColums != "" && this.props.showSortColumns && (
                            <label className="wms-grid-title "> Sort: <span className="no-space">{this.state.sortColums}</span> </label>
                        )}
                    </div>
                }
                <div className={"wms-grid-holder " + (this.state.totalResult == 0 ? 'no-recored-text-enable' : '')}>
                   <div
                        className="no-josh"
                        id={this.props.id}
                        data-wms-selector={this.props.wmsSelector}
                    />
                </div>
            </div>
        );
    }
};
WmsGrid.propTypes = {
    columns: PropTypes.array.isRequired,
    config: PropTypes.object,
    staticGridData: PropTypes.array,
    exportable: PropTypes.bool,
    exportDisable: PropTypes.bool,
    exportGridDisabledText: PropTypes.string,
    eventClickExportGrid: PropTypes.func, // this should control export of grid
    gridName: PropTypes.string.isRequired,
    virtual: PropTypes.bool.isRequired,
    staticDataVirtualization: PropTypes.bool,
    id: PropTypes.string.isRequired, // this should write the id to the input
    wmsSelector: PropTypes.string.isRequired, // this should set up a selector on the input
    languageHeaders: PropTypes.array,
    windowId: PropTypes.string,
    shawCheckbox: PropTypes.bool,
    onSelection: PropTypes.func.isRequired, // this should control the selection of row event
    onSubGridSelection: PropTypes.func,
    clickEventOfContextMenu: PropTypes.func, // this should control the selection of row event
    filterObject: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object
    ]), // Object for filter grid
    getGridSelectionUrl: PropTypes.string, // url of get selection list of records
    inlineFilter: PropTypes.bool, // this should control inline filter of grid or call api,
    inlineSelectAll: PropTypes.bool, // this should control inline all record selection of grid or call api,
    onSelectAllChange: PropTypes.func, // this function call when selectall chane for inline selection,
    searchUrl: PropTypes.string,
    gridEditable: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.object
    ]), // set true or multiple edit column object to make grid columns editable
    showSortColumns: PropTypes.bool, // set true to shaw sotable columns in grid
    showColumnFilter: PropTypes.bool, // set true to shaw clear column filter in grid
    gridHeight: PropTypes.number, // number for set grid height in px
    onLoadGrid: PropTypes.func,
    onDataBound: PropTypes.func,
    onEdit: PropTypes.func,// this function call when grid column's edit
    onRemove: PropTypes.func,// this function call when grid column's remove
    onChange: PropTypes.func,// this function call when grid column's changed
    virtualGridpageSize: PropTypes.number,// set page size in virtual grid read api call
    isColumnFilterenable: PropTypes.bool, // set false to hide column filter option from in grid menu
    isColumnShowenable: PropTypes.bool, // set false to hide column display option from in grid menu
    isUpdateGridSummary: PropTypes.bool, // to reset selection counter
    onUpdateGridSummary:PropTypes.func, // call props method on selection counter update for maintain isUpdateGridSummary flag
    isAddBatchProperty:PropTypes.bool, // set true to add Batch property in grid data source obj
    isAlignIndexesByProperty:PropTypes.bool, // set true to  execute AlignIndexesByProperty() on default columns layout set
    selectRowsSummaryPlaceHolder: PropTypes.string, // Place holder for Input in bottom of grid
    isBindFilterColumnHoverEvent:PropTypes.bool, // Set true to bind mouse events on filter columns list to manage z-index of header when columns list is large
    isServerSorting:PropTypes.bool, // set true for sorting from server when grid is virtual
    isServerFiltering:PropTypes.bool, // set true for filter from server when grid is virtual
    onSort:PropTypes.func, // this function call on grid sort
    onColumnShow:PropTypes.func, // this function call on grid column show
    onColumnHide:PropTypes.func, // this function call on grid column hide
    onColumnReorder:PropTypes.func, // this function call on grid column order change
    onColumnResize:PropTypes.func, // this function call on grid column resize
    onResetGrid:PropTypes.func, // this function call on grid reset
    isRemainSelectionForEditCell:PropTypes.bool, // set true for grid not deselect row when click on edit cell
    showErrorMessageSection:PropTypes.bool, // set true for grid not deselect row when click on edit cell
    showGridSummary: PropTypes.bool, // set true to saw grid total result and selected recored at grid top
    emptyRecoredText: PropTypes.string, // Display empty message when grid data is empty
    gridNavigatable:PropTypes.bool, //set true to apply focus on grid default is false
    resetButtonLabel:PropTypes.string, // set label for filter option default is blank
    resetButtonIcon:PropTypes.string // set icon for filter option default is fa-filter
}

WmsGrid.defaultProps = {
    exportable: false,
    exportDisable: false,
    exportGridDisabledText: '',
    shawCheckbox: true,
    inlineFilter: false,
    inlineSelectAll: false,
    gridEditable: false,
    showResetButton: true,
    showGridResult: true,
    gifLoaderText: '',
    showSortColumns: true,
    showColumnFilter: true,
    onLoadGrid: (e) => function () { },
    onGridBinding: (e) => function () { },
    onGridChange: (e) => function () { },
    onGridSave: (e) => function () { },
    onDataBound: (e) => function () { },
    onEdit: (e) => function () { },
    onRemove: (e) => function () { },
    onChange: (e) => function () { },
    onSelectAllChange: (e) => function () { },
    onSubGridSelection: (e) => function () { },
    onSort: (e) => function () { },
    onColumnShow: (e) => function () { },
    onColumnHide: (e) => function () { },
    onColumnReorder: (e) => function () { },
    onColumnResize: (e) => function () { },
    onResetGrid: (e) => function () { },
    virtualGridpageSize: 50,
    isColumnFilterenable: true,
    isColumnShowenable: true,
    isUpdateGridSummary:false,
    isAlignIndexesByProperty:false,
    onUpdateGridSummary: (e) => function () { },
    isAddBatchProperty:false,
    selectRowsSummaryPlaceHolder: "Selected Orders",
    isBindFilterColumnHoverEvent:false,
    isServerSorting:true,
    isServerFiltering:true,
    isRemainSelectionForEditCell:false,
    showErrorMessageSection: false,
    showGridSummary: false,
    emptyRecoredText: '',
    gridNavigatable:false,
    resetButtonLabel:'',
    resetButtonIcon: 'fa-sliders',
    staticDataVirtualization: false
}

export default WmsGrid;
