import React from "react";
import WmsModel from "../../components/WmsModel/WmsModel";
import WmsButton from "../../components/WmsButton/WmsButton";
import PropTypes from "prop-types";

interface Props {
    isOpen: boolean;
    selector: string;
    onReviewAuditJobAddLocationModalClose: Function;
    selectedRowData: any;
    facilityUri: string;
}

declare var window: any;

const AddLocationModal: React.FC<Props> = (props: Props) => {
    return (
        <div className="review-audit-job-add-location-modal">
            <WmsModel
                isOpen={props.isOpen}
                id={props.selector + "ReviewAuditJobModel"}
                title={"Add Location"}
                width={800}
                height={706}
                onCloseFunc={props.onReviewAuditJobAddLocationModalClose}
                customClass={"wms-model-new-ui-wrapper"}
            >
                <div className={"model-content-wrapper"}>Something</div>
                <div className="footer-btn-holder">
                    <WmsButton
                        label={"Add Location(s) to Audit Job"}
                        wmsSelector={"shipAndcloseCancel"}
                        onClickFunc={() => {}}
                        disabled={false}
                        loading={false}
                        buttonType="green"
                    />
                    <WmsButton
                        label={window.translate("Label_Close")}
                        wmsSelector={"shipAndcloseCancel"}
                        onClickFunc={props.onReviewAuditJobAddLocationModalClose}
                        disabled={false}
                        loading={false}
                        buttonType="orange"
                        icon={"fa-times-circle-o"}
                    />
                </div>
            </WmsModel>
        </div>
    );
};

AddLocationModal.propTypes = {
    isOpen: PropTypes.bool,
    selector: PropTypes.string.isRequired,
    onReviewAuditJobAddLocationModalClose: PropTypes.func,
    selectedRowData: PropTypes.any,
    facilityUri: PropTypes.string.isRequired,
};

AddLocationModal.defaultProps = {
    isOpen: false,
    onReviewAuditJobAddLocationModalClose: (): void => {},
};

export default AddLocationModal;
