import React, { Component } from 'react';
import WmsModel from '../../../components/WmsModel/WmsModel.jsx';
import WmsButton from '../../../components/WmsButton/WmsButton.jsx';
import WmsContainer from '../../../components/WmsContainer/WmsContainer.jsx';
import WmsDropdownList from '../../../components/WmsDropdownList/WmsDropdownList.jsx';
import WmsComboBox from '../../../components/WmsComboBox/WmsComboBox.jsx';
import WmsDropdown from '../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from '../../../components/WmsInput/WmsInput.jsx';
import WmsDateTimePicker from '../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import { ObjectWms } from '../../../../../assets/ts/ObjectWms.ts';
import { StringWms } from '../../../../../assets/ts/StringWms.ts';
import { DictionaryWms } from '../../../../../assets/ts/DictionaryWms.ts';

import './manage-line-items-modal.scss';
//services
import * as manageReceiptService from './../manageReceipt.services.jsx';
import * as GlobalService from './../../../global_services';

import WmsRadio from '../../../components/WmsRadio/WmsRadio.tsx';
import WmsCheckBox from '../../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsGrid from '../../../components/WmsGrid/WmsGrid.jsx';
import { ItemsHolder } from '../../../items/items-holder.component.jsx';
import { locationsHolder } from '../../../locations/locations-holder.component.jsx';
import { kendo, Big } from "./../../../global_variable_service.jsx";

export class ManageLineItemsModal extends Component {

    constructor(props) {
        super(props);


        this.$rootScope = window;
        this.translate = window.translate;

        this.defaultItemsummary = {
            Id: 0,
            Sku: "",
            Location: { Name: "", Description: "", Id: 0 },
            PutawayLocation: { Id: 0, LocationName: "" },
            Supplier: { Id: 0, },
        };
        this.state = {
            savingGif: false,
            openCreateItemGif: false,
            openCreateLocationGif: false,
            isItemReadyForSave: false,
            Rights: GlobalService.GetRights(),
            selector: 'ReceiptLineItem',
            itemSummary: Object.assign({}, this.defaultItemsummary),
            displayWeightUnit: '',
            displayHeightLengthWidthUnit: '',
            selectedMovableUnitLabel: { Name: "" },
            optionsMovableUnitLabels: [],
            holdReasonOptions: [],
            labelPrimaryRec: 0,
            labelSecondaryRec: 0,
            isCustomerUser: GlobalService.GetCurrentUser().CustomerIds.length > 0 ? true : false,
            customLabels: GlobalService.getCustomLabels(),
            showTrackBys: false,
            showQualifier: false,
            isRequiredQualifier: false,
            showLocationDropdown: true,
            requiredElementsComplete: false,
            showRecommendedLocations: false,
            disableSplitMU: true,
            showMovableUnitFields: false,
            showSuggestedPutawayLocations: false,
            disableMovableUnits: true,
            showCustomFieldInfo: false,
            lineItemCustomFieldsGridData: [],
            selectedMovableUnitType: { Name: "" },
            measurementStandard: null,
            suggestedPutawayLocationsOptions: [],
            customField: {},
            skuOptionsLoaded: false,
            enableSplitMU: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.totalLineItems >= 1 && nextProps.totalLineItems != this.props.totalLineItems) {
            this.getMovableUnitLabelOptions(nextProps.lineItemsGridData);
        }
    }
    // use RECEIPT object from  --- this.props.sharedReceiptData.Receipt
    // use main receipt object from  --- this.props.sharedReceiptData

    componentDidMount() {
        let showMovableUnit = false;
        let showSuggestedPutawayLocations = false;
        let showRecommendedLocations = false;
        let requiredElementsComplete = false;

        if (this.props.sharedLineItemFlow == 'create') {
            requiredElementsComplete = true;
        } else if (this.props.sharedLineItemFlow == 'edit') {
            // get the dom element of the receipt line item grid, making sure to only grab the window of the current one, we need this to get the sku for our service

            // display ajax while we get data.
            kendo.ui.progress($("#" + this.props.windowId + 'ManageLineItemsModal'), true);

            manageReceiptService.lineItemEditOptions({
                "receiverId": this.props.sharedReceiptData.Receipt.ReceiverId,
                "customerId": this.props.selectedCustomer.id,
                "sku": this.props.sharedSelectedLineItem.SKU
            }).then((response) => {
                this.initializeElements(response);
            }).finally(() => {
                kendo.ui.progress($("#" + this.props.windowId + 'ManageLineItemsModal'), false);
            });
        }


        let suggestedPutawayEnabled = this.props.selectedCustomer.options.suggestedPutAwayEnabled;

        if (!StringWms.isEmpty(this.props.selectedCustomer.options.trackPallets)) {
            showMovableUnit = this.props.selectedCustomer.options.trackPallets;
        }
        if (!StringWms.isEmpty(suggestedPutawayEnabled)) {
            showSuggestedPutawayLocations = suggestedPutawayEnabled;
            // showRecommendedLocations = true;
            showRecommendedLocations = false;
        }

        this.setState({
            requiredElementsComplete: requiredElementsComplete,
            showSuggestedPutawayLocations: showSuggestedPutawayLocations,
            showRecommendedLocations: showRecommendedLocations,
            showMovableUnitFields: showMovableUnit,
            optionsMovableUnitTypes: this.props.sharedReceiptData.PalletTypes,
            measurementStandard: this.props.selectedFacility.measurementStandard,
            showCustomFieldInfo: this.props.sharedReceiptData.threePlReceiptSettings.OtherUserFeatures.SavedElementsEnabled,
            enableSplitMU: this.props.selectedCustomer.options.createMultipleMus,
            displayWeightUnit:
                (this.props.selectedFacility.measurementStandard == 0) ?
                    this.translate('Label_Imperial_Weight') :
                    this.translate('Label_Metric_Weight')
            ,
            displayHeightLengthWidthUnit:
                (this.props.selectedFacility.measurementStandard == 0) ?
                    this.translate('Label_Inch_Abbrev') :
                    this.translate('Label_Centimeter_Abbrev'),

        });

        this.getMovableUnitLabelOptions(this.props.lineItemsGridData);
        this.getHoldReasonOptions();

    }
    async initializeElements(param_sku_obj) {

        var selectedSkuObj = param_sku_obj;
        var selectedMovableUnitLabel = "";
        let showTrackBys = true;
        let showQualifier = false;
        var isRequiredQualifier = false;

        // $scope.page.LineItemData = this.props.sharedSelectedLineItem;
        // $scope.page.SkuSettings = param_sku_obj;
        // $scope.page.FacilitySettings = this.props.selectedFacility;



        await this.setSuggestedPutawayLocationsOptions(selectedSkuObj.Id, this.props.sharedSelectedLineItem.Qualifier);
        if (param_sku_obj.QualifierSummaries && param_sku_obj.QualifierSummaries.length >= 2 || param_sku_obj.QualifierSummaries[0].Qualifier != '') {
            showQualifier = true;
            isRequiredQualifier = true;
        }

        // selectedSkuObj.Qualifier = typeof param_sku_obj.QualifierSummaries != 'undefined' ? param_sku_obj.QualifierSummaries[0] : null;
        if (this.props.sharedSelectedLineItem.Qualifier != '') {
            param_sku_obj.QualifierSummaries.forEach((element) => {
                if (element.Qualifier.toLowerCase() == this.props.sharedSelectedLineItem.Qualifier.toLowerCase()) {
                    selectedSkuObj.Qualifier = element;
                }
            });
        } else {
            selectedSkuObj.Qualifier = typeof param_sku_obj.QualifierSummaries != 'undefined' ? param_sku_obj.QualifierSummaries[0] : selectedSkuObj.Qualifier;
        }

        selectedSkuObj.PrimaryInvQty = this.props.sharedSelectedLineItem.Qty;
        selectedSkuObj.SecondaryInvQty = this.props.sharedSelectedLineItem.SecondaryQty;
        selectedSkuObj.Weight = this.props.sharedSelectedLineItem.Weight;
        selectedSkuObj.LotNumber = this.props.sharedSelectedLineItem.LotNumber;
        selectedSkuObj.SerialNumber = this.props.sharedSelectedLineItem.SerialNumber;
        selectedSkuObj.LandedCost = this.props.sharedSelectedLineItem.LandedCost;
        selectedSkuObj.ExpDate = this.props.sharedSelectedLineItem.ExpirationDate;
        selectedSkuObj.MovUnitType = this.props.sharedSelectedLineItem.MovableUnitType;
        selectedSkuObj.MovUnitLength = this.props.sharedSelectedLineItem.MovableUnitLength;
        selectedSkuObj.MovUnitWidth = this.props.sharedSelectedLineItem.MovableUnitWidth;
        selectedSkuObj.MovUnitHeight = this.props.sharedSelectedLineItem.MovableUnitHeight;
        selectedSkuObj.MovUnitWeight = this.props.sharedSelectedLineItem.MovableUnitWeight;
        selectedSkuObj.MovUnitNumber = null;
        selectedSkuObj.AutoHoldOnReceive = param_sku_obj.AutoHoldOnReceive;
        selectedSkuObj.HoldStatus = this.props.sharedSelectedLineItem.HoldStatus;
        selectedSkuObj.HoldReason = this.props.sharedSelectedLineItem.HoldReason;
        selectedSkuObj.CreateMultipleMovUnits = this.props.sharedSelectedLineItem.SplitLineByMovableUnit;

        if (this.props.sharedSelectedLineItem.MovableUnitLabel != '') {
            selectedMovableUnitLabel = this.props.sharedSelectedLineItem.MovableUnitLabel;
        }

        if (param_sku_obj.TrackBys) {
            showTrackBys = (selectedSkuObj.TrackBys.TrackLotNumber != 0 || selectedSkuObj.TrackBys.TrackSerialNumber != 0 || selectedSkuObj.TrackBys.TrackExpirationDate != 0 || selectedSkuObj.TrackBys.TrackCost != 0);
        }
        if (this.props.sharedSelectedLineItem.Location) {
            this.state.suggestedPutawayLocationsOptions.forEach(element => {
                if (this.props.sharedSelectedLineItem.Location == element.LocationName) {
                    selectedSkuObj.PutawayLocation = element;
                }
            });
            selectedSkuObj.Location = {
                Name: this.props.sharedSelectedLineItem.Location,
                Id: this.props.sharedSelectedLineItem.LocationId
            };
        }

        this.setState({
            showQualifier: showQualifier,
            isRequiredQualifier: isRequiredQualifier,
            showTrackBys: showTrackBys,
            selectedMovableUnitLabel: {
                Name: selectedMovableUnitLabel
            },
            selectedMovableUnitType: {
                Name: selectedSkuObj.MovUnitType
            },
            // showLocationDropdown : true,
            itemSummary: Object.assign(
                {},
                this.state.itemSummary,
                selectedSkuObj
            )
        }, () => {
            this.initialiseCustomFieldGridData();
            this.checkItemReadyForSave();
            kendo.ui.progress($("#" + this.props.windowId + 'ManageLineItemsModal'), false);
        });

    }
    setSuggestedPutawayLocationsOptions = (param_sku_id, param_qualifier) => {
        if (this.props.selectedCustomer.options.suggestedPutAwayEnabled) {
            if (param_qualifier == "") {
                param_qualifier = "''";
            }

            return manageReceiptService.getSuggestedPutawayLocations(this.props.selectedCustomer.defaultFacility, param_sku_id, param_qualifier).then((response) => {
                if (response) {
                    this.setState({
                        suggestedPutawayLocationsOptions: response,
                        // showLocationDropdown: (response.length == 0),
                        showLocationDropdown: true,
                        showSuggestedPutawayLocations: (response.length != 0),
                        // showRecommendedLocations: (response.length != 0),
                        showRecommendedLocations: false,
                    });
                }
            });
        }
    }

    handleChangeEvent = (e, objKey) => {
        let itemSummary = Object.assign({}, this.state.itemSummary);
        var value = e.target.value;
        if (typeof value == 'object' && objKey != 'ExpDate') {
            value = Object.assign({}, value);
        }

        if (e.target.type == 'checkbox') {
            itemSummary[objKey] = !this.state.itemSummary[objKey];
        } else {
            itemSummary[objKey] = value;
        }

        this.setState({
            itemSummary: itemSummary
        }, () => {
            this.checkItemReadyForSave();
        });
    }

    handleSkuChange = (e) => {
        var selectedSkuDropDown = Object.assign({}, e.target.value);

        // QualifierSummaries options assign with array because its have object type
        selectedSkuDropDown.QualifierSummaries = Object.assign([], selectedSkuDropDown.QualifierSummaries);

        var showQualifier = false;
        var showTrackBys = false;
        var isRequiredQualifier = false;
        if (selectedSkuDropDown && selectedSkuDropDown.Id != 0) {
            var qualifier = null;
            selectedSkuDropDown.Weight = null;
            selectedSkuDropDown.HoldStatus = false;
            selectedSkuDropDown.PrimaryInvQty = null;

            if (selectedSkuDropDown.TrackBys) {
                showTrackBys = (selectedSkuDropDown.TrackBys.TrackLotNumber != 0 || selectedSkuDropDown.TrackBys.TrackSerialNumber != 0 || selectedSkuDropDown.TrackBys.TrackExpirationDate != 0 || selectedSkuDropDown.TrackBys.TrackCost != 0);
                if (selectedSkuDropDown.TrackBys.TrackSerialNumber == 3 || selectedSkuDropDown.TrackBys.TrackSerialNumber == 4) {
                    selectedSkuDropDown.PrimaryInvQty = 1;
                }
            }

            if (this.refs.refExpirationDate) {
                this.refs.refExpirationDate.clearDateTimePicker();
            }
            if (selectedSkuDropDown.QualifierSummaries) {
                if (selectedSkuDropDown.QualifierSummaries.length > 1) {
                    isRequiredQualifier = true;
                } else if (selectedSkuDropDown.QualifierSummaries.length == 1 && selectedSkuDropDown.QualifierSummaries[0].Qualifier != "") {
                    isRequiredQualifier = true;
                }
                showQualifier = ObjectWms.doesKeyContainValidValue(selectedSkuDropDown.QualifierSummaries, "Qualifier");
                selectedSkuDropDown.Qualifier = selectedSkuDropDown.QualifierSummaries[0];
                qualifier = selectedSkuDropDown.QualifierSummaries[0].Qualifier;
            }
            if (this.props.selectedCustomer.options.suggestedPutAwayEnabled) {

                if (qualifier == "") {
                    qualifier = "''";
                }

                manageReceiptService.getSuggestedPutawayLocations(this.props.selectedCustomer.defaultFacility, selectedSkuDropDown.Id, qualifier).then((response) => {
                    this.setState({
                        suggestedPutawayLocationsOptions: response,
                        showLocationDropdown: (response.length == 0),
                        showSuggestedPutawayLocations: (response.length != 0),
                        showRecommendedLocations: (response.length != 0),
                    });
                });
            }
            this.setState({
                selectedSku: e.target.value,
                itemSummary: Object.assign(
                    this.state.itemSummary,
                    selectedSkuDropDown
                ),
                showTrackBys: showTrackBys,
                showQualifier: showQualifier,
                isRequiredQualifier: isRequiredQualifier,
                disableMovableUnits: true
            });

            this.resetLineItemsSection('skuChange');
            this.initialiseCustomFieldGridData();
        } else {
            this.resetLineItemsSection();
        }
    }

    /* istanbul ignore next */
    qualifierOptionsChange = (e) => {
        var qualifier = Object.assign({}, e.target.value);
        this.handleChangeEvent({ target: { value: qualifier } }, "Qualifier");

        if (this.props.selectedCustomer.options.suggestedPutAwayEnabled) {
            if (qualifier.Qualifier == "") {
                qualifier.Qualifier = "''";
            }
            manageReceiptService.getSuggestedPutawayLocations(this.props.selectedCustomer.defaultFacility, this.state.itemSummary.Id, qualifier.Qualifier).then((response) => {
                this.setState({
                    suggestedPutawayLocationsOptions: response,
                    showLocationDropdown: true,
                    showSuggestedPutawayLocations: (response.length != 0),
                });
            });
        }
    };

    resetLineItemsSection = (type) => {
        var PrimaryInvQty = null;
        if (this.state.itemSummary.TrackBys) {
            if (this.state.itemSummary.TrackBys.TrackSerialNumber == 3 || this.state.itemSummary.TrackBys.TrackSerialNumber == 4) {
                PrimaryInvQty = 1;
            }
        }

        var showQualifier = this.state.showQualifier;
        var showTrackBys = this.state.showTrackBys;
        var itemSummary = Object.assign(
            this.state.itemSummary,
            {
                PrimaryInvQty: PrimaryInvQty,
                SecondaryInvQty: null,
                LotNumber: null,
                SerialNumber: null,
                LandedCost: null,
                Cost: null,
                ExpDate: null,
                Weight: null,
                Location: { Name: "", Description: "", Id: 0 },
                Supplier: { Id: 0, },
            }
        );

        if (type != "skuChange") {
            showQualifier = false;
            showTrackBys = false;
            itemSummary = Object.assign({}, this.defaultItemsummary);
        }

        this.setState({
            showQualifier: showQualifier,
            showTrackBys: showTrackBys,
            itemSummary: itemSummary,
            lineItemCustomFieldsGridData: [],
            labelPrimaryRec: 0,
            labelSecondaryRec: 0,
            selectedMovableUnitLabel: {
                Name: ""
            },
            disableMovableUnits: true
        }, this.checkItemReadyForSave());
    }


    eventChangeLocationList = (e) => {
        this.setState({
            showRecommendedLocations: !this.state.showRecommendedLocations,
            showLocationDropdown: this.state.showRecommendedLocations,
        }, () => {
            this.checkItemReadyForSave();
        });
    }

    getMovableUnitLabelOptions = (lineItemsGridData) => {
        var movableUnitLabels = [];
        var receiptLineItemGridData = lineItemsGridData;
        // this.props.sharedReceiptLineItemGridData.forEach(lineItem => {
        receiptLineItemGridData.forEach(lineItem => {
            if (lineItem.MovableUnitLabel != null && lineItem.MovableUnitLabel != "") {
                if (ObjectWms.FindIndexByProperty(movableUnitLabels, "Name", lineItem.MovableUnitLabel) == -1) {
                    movableUnitLabels.push({
                        "Name": lineItem.MovableUnitLabel
                    });
                }
            }
        });

        // add in System-Assigned which option which should always be there
        movableUnitLabels.unshift({
            "Name": this.translate('Label_System_Assigned'),
        });
        // add in None option that should always be there, we need to make sure this is selected by default in all cases too
        if (this.props.selectedCustomer.options.trackPallets != 2) {
            movableUnitLabels.unshift({
                "Name": this.translate('Label_None'),
            });
        }

        this.setState({
            optionsMovableUnitLabels: movableUnitLabels,
            //selectedMovableUnitLabel: movableUnitLabels[0].Name
        });
    }

    getHoldReasonOptions = () => {
        var HoldReasonOptions = [];
        var holdReasonsArray = this.translate('Inventory_HoldReason').split(',');
        holdReasonsArray.forEach(reason => {
            if (reason) {
                HoldReasonOptions.push({
                    "Name": reason,
                    "Value": reason
                });
            }
        });

        this.setState({
            holdReasonOptions: HoldReasonOptions
        });
    }

    eventMovableUnitLabelsChange = (e) => {
        if (e != "" && e.target.value != null && e.target.value != "") {
            var selectedIndex = e.target.selectedIndex;
            var selectedMovableUnit = e.target.value;

            this.setState({
                selectedMovableUnitLabel: {
                    Name: selectedMovableUnit
                }
            });

            if (selectedIndex == 0 && selectedMovableUnit == "None") {
                this.setState({
                    selectedMovableUnitLabel: {
                        Name: this.state.optionsMovableUnitLabels[0].Name
                    },
                    disableSplitMU: true,
                    disableMovableUnits: true,
                    selectedMovableUnitType: {
                        Name: ""
                    },
                    itemSummary: Object.assign(
                        this.state.itemSummary,
                        {
                            MovUnitHeight: null,
                            MovUnitLength: null,
                            MovUnitWeight: null,
                            MovUnitWidth: null,
                            MovUnitType: null,
                            MovUnitNumber: null,
                            CreateMultipleMovUnits: false
                        }
                    ),

                });
            } else if (selectedIndex == -1) {
                this.setState({
                    disableSplitMU: !(this.state.itemSummary.Pallets != null && this.state.itemSummary.Pallets.Qty),
                    disableMovableUnits: true,
                });

                manageReceiptService.getPalletInfoByLabel(selectedMovableUnit).then((response) => {
                    if (response != null && response != "") {

                        this.setState({
                            itemSummary: Object.assign(
                                this.state.itemSummary,
                                {
                                    MovUnitType: response.Name,
                                    MovUnitLength: (this.state.measurementStandard == 0) ? response.ImperialLength : response.MetricLength,
                                    MovUnitWidth: (this.state.measurementStandard == 0) ? response.ImperialWidth : response.MetricWidth,
                                    MovUnitHeight: (this.state.measurementStandard == 0) ? response.ImperialHeight : response.MetricHeight,
                                    MovUnitWeight: (this.state.measurementStandard == 0) ? response.ImperialWeight : response.MetricWeight,
                                    MovUnitNumber: this.state.itemSummary.Pallets.Qty,
                                    CreateMultipleMovUnits: this.state.enableSplitMU
                                }
                            )
                        });
                    } else {
                        var selectedMovableUnitTypeName = this.state.optionsMovableUnitTypes[0].Name;
                        var MovUnitLength = null;
                        var MovUnitWidth = null;
                        var MovUnitHeight = null;
                        var MovUnitWeight = null;
                        var MovUnitNum = null;

                        if (this.state.itemSummary.Pallets != null) {
                            if (this.state.itemSummary.Pallets.TypeIdentifier != null) {
                                selectedMovableUnitTypeName = this.state.itemSummary.Pallets.TypeIdentifier.Name;
                            }
                            MovUnitNum = this.state.itemSummary.Pallets.Qty;

                            if (this.state.measurementStandard == 0) {
                                MovUnitLength = (this.state.itemSummary.Pallets.Imperial != null) ? this.state.itemSummary.Pallets.Imperial.Length : null;
                                MovUnitWidth = (this.state.itemSummary.Pallets.Imperial != null) ? this.state.itemSummary.Pallets.Imperial.Width : null;
                                MovUnitHeight = (this.state.itemSummary.Pallets.Imperial != null) ? this.state.itemSummary.Pallets.Imperial.Height : null;
                                MovUnitWeight = (this.state.itemSummary.Pallets.Imperial != null) ? this.state.itemSummary.Pallets.Imperial.Weight : null;
                            } else {
                                MovUnitLength = (this.state.itemSummary.Pallets.Metric != null) ? this.state.itemSummary.Pallets.Metric.Length : null;
                                MovUnitWidth = (this.state.itemSummary.Pallets.Metric != null) ? this.state.itemSummary.Pallets.Metric.Width : null;
                                MovUnitHeight = (this.state.itemSummary.Pallets.Metric != null) ? this.state.itemSummary.Pallets.Metric.Height : null;
                                MovUnitWeight = (this.state.itemSummary.Pallets.Metric != null) ? this.state.itemSummary.Pallets.Metric.Weight : null;
                            }

                        }
                        this.setState({
                            disableMovableUnits: false,
                            selectedMovableUnitType: {
                                Name: selectedMovableUnitTypeName
                            },
                            itemSummary: Object.assign(
                                this.state.itemSummary,
                                {
                                    MovUnitLength: MovUnitLength,
                                    MovUnitWidth: MovUnitWidth,
                                    MovUnitHeight: MovUnitHeight,
                                    MovUnitWeight: MovUnitWeight,
                                    MovUnitNumber: MovUnitNum,
                                    CreateMultipleMovUnits: this.state.enableSplitMU
                                }
                            )
                        });
                    }
                });
            } else if (selectedIndex != 0 || selectedIndex != -1) {
                if (selectedMovableUnit !== this.translate('Label_System_Assigned')) {
                    var isUpdateSummary = false;
                    this.setState({
                        disableSplitMU: true,
                        disableMovableUnits: true,
                    });
                    var receiptLineItemGridData = this.props.lineItemsGridData;
                    // this.props.sharedReceiptLineItemGridData.forEach(lineItem => {
                    receiptLineItemGridData.forEach(lineItem => {
                        if (lineItem.MovableUnitLabel === selectedMovableUnit && !isUpdateSummary) {
                            this.setState({
                                itemSummary: Object.assign(
                                    this.state.itemSummary,
                                    {
                                        MovUnitType: lineItem.MovableUnitType,
                                        MovUnitLength: lineItem.MovableUnitLength,
                                        MovUnitWidth: lineItem.MovableUnitWidth,
                                        MovUnitHeight: lineItem.MovableUnitHeight,
                                        MovUnitWeight: lineItem.MovableUnitWeight,
                                        MovUnitNumber: this.state.itemSummary.Pallets.Qty,
                                        CreateMultipleMovUnits: this.state.enableSplitMU
                                    }
                                ),
                            });
                            isUpdateSummary = true;
                        }
                    });
                } else {
                    var selectedMovableUnitTypeName = this.state.optionsMovableUnitTypes[0].Name;
                    var MovUnitLength = null;
                    var MovUnitWidth = null;
                    var MovUnitHeight = null;
                    var MovUnitWeight = null;
                    var MovUnitNum = null;

                    if (this.state.itemSummary.Pallets != null) {
                        if (this.state.itemSummary.Pallets.TypeIdentifier != null) {
                            selectedMovableUnitTypeName = this.state.itemSummary.Pallets.TypeIdentifier.Name;
                            MovUnitNum = this.state.itemSummary.Pallets.Qty;
                        }

                        if (this.state.measurementStandard == 0) {
                            MovUnitLength = (this.state.itemSummary.Pallets.Imperial != null) ? this.state.itemSummary.Pallets.Imperial.Length : null;
                            MovUnitWidth = (this.state.itemSummary.Pallets.Imperial != null) ? this.state.itemSummary.Pallets.Imperial.Width : null;
                            MovUnitHeight = (this.state.itemSummary.Pallets.Imperial != null) ? this.state.itemSummary.Pallets.Imperial.Height : null;
                            MovUnitWeight = (this.state.itemSummary.Pallets.Imperial != null) ? this.state.itemSummary.Pallets.Imperial.Weight : null;
                        } else {
                            MovUnitLength = (this.state.itemSummary.Pallets.Metric != null) ? this.state.itemSummary.Pallets.Metric.Length : null;
                            MovUnitWidth = (this.state.itemSummary.Pallets.Metric != null) ? this.state.itemSummary.Pallets.Metric.Width : null;
                            MovUnitHeight = (this.state.itemSummary.Pallets.Metric != null) ? this.state.itemSummary.Pallets.Metric.Height : null;
                            MovUnitWeight = (this.state.itemSummary.Pallets.Metric != null) ? this.state.itemSummary.Pallets.Metric.Weight : null;
                        }
                    }

                    this.setState({
                        disableSplitMU: !(this.state.itemSummary.Pallets != null && this.state.itemSummary.Pallets.Qty),
                        disableMovableUnits: false,
                        selectedMovableUnitType: {
                            Name: selectedMovableUnitTypeName
                        },
                        itemSummary: Object.assign(
                            this.state.itemSummary,
                            {
                                MovUnitLength: MovUnitLength,
                                MovUnitWidth: MovUnitWidth,
                                MovUnitHeight: MovUnitHeight,
                                MovUnitWeight: MovUnitWeight,
                                MovUnitNumber: MovUnitNum,
                                CreateMultipleMovUnits: this.state.enableSplitMU
                            }
                        )
                    });
                }
            }
            this.checkItemReadyForSave();
        }
        else {
            this.setState({
                selectedMovableUnitLabel: {
                    Name: ""
                },
                disableSplitMU: true,
                disableMovableUnits: true,
                selectedMovableUnitType: {
                    Name: ""
                },
                itemSummary: Object.assign(
                    this.state.itemSummary,
                    {
                        MovUnitHeight: null,
                        MovUnitLength: null,
                        MovUnitWeight: null,
                        MovUnitWidth: null,
                        MovUnitType: null,
                        MovUnitNumber: null,
                        CreateMultipleMovUnits: false
                    }
                ),

            });
        }
    }

    eventMovableUnitTypeChange = (e) => {
        var selectedMovableUnitType = Object.assign({}, e.target.value);
        if (e.target.selectedIndex != -1) {
            this.setState({
                itemSummary: Object.assign(
                    this.state.itemSummary,
                    {
                        MovUnitType: selectedMovableUnitType.Name,
                        MovUnitLength: (this.state.measurementStandard == 0) ? selectedMovableUnitType.ImperialLength : selectedMovableUnitType.MetricLength,
                        MovUnitWidth: (this.state.measurementStandard == 0) ? selectedMovableUnitType.ImperialWidth : selectedMovableUnitType.MetricWidth,
                        MovUnitHeight: (this.state.measurementStandard == 0) ? selectedMovableUnitType.ImperialHeight : selectedMovableUnitType.MetricHeight,
                        MovUnitWeight: (this.state.measurementStandard == 0) ? selectedMovableUnitType.ImperialWeight : selectedMovableUnitType.MetricWeight,
                    }
                ),
            });
        }
        this.setState({
            selectedMovableUnitType: selectedMovableUnitType
        }, this.checkItemReadyForSave());
    }

    showRecommendation = (e) => {
        let qntyValue = e.target.value;
        let qntyName = e.target.name;
        let labelPrimaryRec = 0;
        let labelSecondaryRec = 0;

        if (qntyName == 'PrimaryInvQty') {
            labelSecondaryRec = qntyValue / this.state.itemSummary.PrimaryUnitsPerSecondaryUnit;
            if ((labelSecondaryRec % 1 != 0) && this.state.itemSummary.SecondaryIsIntegral) {
                labelSecondaryRec = Math.ceil(labelSecondaryRec);
            }
            this.setState({ labelSecondaryRec: labelSecondaryRec });
        }
        if (qntyName == 'SecondaryInvQty') {
            labelPrimaryRec = qntyValue * this.state.itemSummary.PrimaryUnitsPerSecondaryUnit;
            if ((labelPrimaryRec % 1 != 0) && this.state.itemSummary.PrimaryIsIntegral) {
                labelPrimaryRec = Math.ceil(labelPrimaryRec);
            }
            this.setState({ labelPrimaryRec: labelPrimaryRec });
        }

        this.handleChangeEvent({ target: { value: qntyValue } }, qntyName);
    }

    eventCalculateWeight = () => {
        if (this.state.itemSummary.PackageUnit != null) {
            // We dont know if they have filled out primary quantity or secondary quantity so the first thing we need to do is convert to primary and use this
            var _adjustedQty = "";
            //var unitWeightForPackage;
            var invQty;
            var Weight = null;

            if (this.state.itemSummary.PrimaryInvQty) {
                _adjustedQty = this.state.itemSummary.PrimaryInvQty;
            } else if (this.state.itemSummary.SecondaryInvQty) {
                _adjustedQty = this.state.itemSummary.SecondaryInvQty * this.state.itemSummary.PrimaryUnitsPerSecondaryUnit;
            }

            if (this.props.selectedFacility.measurementStandard != 0) {
                var metricData = this.state.itemSummary.PackageUnit.Metric;
                if (metricData != null && metricData.Weight != null) {
                    //unitWeightForPackage = parseFloat(new Big(metricData.Weight || 0).div(new Big(this.state.itemSummary.PackageUnit.InventoryUnitsPerUnit || 0)).round(4));
                    //Weight = parseFloat(new Big(_adjustedQty || 0).times(new Big(unitWeightForPackage || 0)).round(4));
                    invQty = parseFloat(new Big(_adjustedQty || 0).div(new Big(this.state.itemSummary.PackageUnit.InventoryUnitsPerUnit || 0)));
                    Weight = parseFloat(new Big(metricData.Weight || 0).times(new Big(invQty || 0)).round(4));
                }
            } else {
                var imperialData = this.state.itemSummary.PackageUnit.Imperial;
                if (imperialData != null && imperialData.Weight != null) {
                    //unitWeightForPackage = parseFloat((new Big(imperialData.Weight || 0).div(new Big(this.state.itemSummary.PackageUnit.InventoryUnitsPerUnit || 0))).round(4));
                    //Weight = parseFloat((new Big(_adjustedQty || 0).times(new Big(unitWeightForPackage || 0))).round(4));
                    invQty = parseFloat(new Big(_adjustedQty || 0).div(new Big(this.state.itemSummary.PackageUnit.InventoryUnitsPerUnit || 0)));
                    Weight = parseFloat(new Big(imperialData.Weight || 0).times(new Big(invQty || 0)).round(4));
                }
            }

            this.setState({
                itemSummary: Object.assign(
                    this.state.itemSummary,
                    {
                        Weight: Weight
                    }
                ),
            });
        }
    }

    suplierDropdownDataBound = (e) => {

        let data = Object.assign([], e.sender.dataSource._data);

        if (data.length > 0) {
            if (this.props.sharedLineItemFlow == "edit" && this.props.sharedSelectedLineItem.Supplier) {
                data.forEach(element => {
                    if (this.props.sharedSelectedLineItem.Supplier == element.CompanyName) {
                        this.handleChangeEvent({ target: { value: element } }, "Supplier");
                    }
                });
            }
        }
    }


    locationDropdownDataBound = (e) => {
        // let data = Object.assign([],e.sender.dataSource._data);
        // let showLocationDropdown = false;

        // if (data.length > 0) {
        //     showLocationDropdown = true;

        //     if (this.props.sharedLineItemFlow == "edit" && this.props.sharedSelectedLineItem.Location) {
        //         // data.forEach(element => {
        //             console.log("this.props.sharedSelectedLineItem.LocationId",this.props.sharedSelectedLineItem.LocationId)
        //             if (this.props.sharedSelectedLineItem.LocationId) {
        //                 this.handleChangeEvent({ target: { value: this.props.sharedSelectedLineItem.LocationId } }, "Location");
        //             }
        //         // });
        //     }
        // }

        // this.setState({
        //     showLocationDropdown: showLocationDropdown,
        // });
    }

    setCustomFieldData = (e, key) => {
        this.setState({
            customField: Object.assign(
                this.state.customField,
                {
                    [key]: e.target.value
                }
            )
        });
    }

    /* istanbul ignore next */
    addCustomeElement = (e) => {
        var lineItemCustomFieldsGridData = Object.assign([], this.state.lineItemCustomFieldsGridData);
        let isFieldAvailable = lineItemCustomFieldsGridData.some((el) => {
            return el['Name'].toLowerCase() == this.state.customField.Name.toLowerCase();
        });

        if (isFieldAvailable) {
            GlobalService.notification("error", this.translate('Label_Unique_Value_Required') + ':', this.translate('Label_Name'));
            return;
        }

        var customFieldDataObj = Object.assign(
            this.state.customField,
            {
                Required: false
            }
        );
        lineItemCustomFieldsGridData.push(customFieldDataObj);

        this.setState({
            lineItemCustomFieldsGridData: lineItemCustomFieldsGridData,
            customField: {},
        });
    }

    checkItemReadyForSave = () => {
        var ItemSummary = Object.assign({}, this.state.itemSummary);

        var hasNoSuggestedPutawaylocation = false;
        if (this.state.itemSummary.PutawayLocation) {
            hasNoSuggestedPutawaylocation = StringWms.isEmpty(this.state.itemSummary.PutawayLocation.LocationName);
        }

        var notready = (this.state.itemSummary.Id == 0) || (!ItemSummary.PrimaryInvQty && !ItemSummary.SecondaryInvQty) || (ItemSummary.PrimaryInvQty <= 0 && !ItemSummary.SecondaryInvQty < 0) || (ItemSummary.TrackBys.TrackLotNumber == 2 && !ItemSummary.LotNumber) || ((ItemSummary.TrackBys.TrackSerialNumber == 3 || ItemSummary.TrackBys.TrackSerialNumber == 4) && !ItemSummary.SerialNumber) || (ItemSummary.TrackBys.TrackCost == 2 && !ItemSummary.LandedCost) || (ItemSummary.TrackBys.TrackExpirationDate == 2 && !ItemSummary.ExpDate) || (ItemSummary.HoldStatus && !ItemSummary.HoldReason) || (this.state.showMovableUnitFields === 2 && this.state.selectedMovableUnitLabel.Name === '') || (
            this.props.selectedCustomer.options.trackLocation === 2 &&
            (
                (this.state.showRecommendedLocations == true && hasNoSuggestedPutawaylocation) ||
                (this.state.showRecommendedLocations == false && this.state.itemSummary.Location.Id <= 0)
            )
        ) ||
            (this.props.selectedCustomer.options.trackSupplier === 2 && this.state.itemSummary.Supplier.Id <= 0);
        if (this.props.sharedLineItemFlow == 'create') {
            notready = (notready || this.state.requiredElementsComplete == false);
        }
        if (this.state.isRequiredQualifier && this.state.itemSummary.Qualifier && !this.state.itemSummary.Qualifier.Qualifier) {
            notready = true;
        }
        this.setState({
            isItemReadyForSave: !notready,
        });
    }

    /* istanbul ignore next */
    saveLineItemAndExit = () => {

        if (this.state.showMovableUnitFields == 2 && this.state.selectedMovableUnitLabel.Name == "None") {
            GlobalService.notification('error', this.translate('Label_Receipt_MU_Required'));
        } else {
            this.setState({ savingGif: true });

            var volume = 0;

            if (this.state.itemSummary.MovUnitLength && this.state.itemSummary.MovUnitWidth && this.state.itemSummary.MovUnitHeight) {
                volume = parseFloat(new Big(this.state.itemSummary.MovUnitLength || 0).times(new Big(this.state.itemSummary.MovUnitWidth || 0)).times(new Big(this.state.itemSummary.MovUnitHeight || 0)).round(4));
            }

            var _SecondaryQty = null;
            if (this.state.itemSummary.SecondaryInvQty) {
                _SecondaryQty = this.state.itemSummary.SecondaryInvQty;
            } else if (!this.state.itemSummary.SecondaryInvQty && this.state.itemSummary.SecondaryUom) {
                _SecondaryQty = this.state.labelSecondaryRec;
            }


            // code to determine supplier, if supplier is set in UI, use its value to send over to api, else send null
            var _supplierValue = (this.state.itemSummary.Supplier.CompanyName) ? this.state.itemSummary.Supplier.CompanyName : null;
            // if supplierValue is equal to the injected option we did, then we want that to be null
            if (_supplierValue == this.translate('Label_Select_Supplier')) {
                _supplierValue = null;
            }
            var isMuLabelNull = false;
            if (this.state.selectedMovableUnitLabel.Name) {
                if (
                    this.state.selectedMovableUnitLabel.Name == "None" ||
                    this.state.selectedMovableUnitLabel.Name.toLowerCase() == this.translate('Label_System_Assigned').toLowerCase() ||
                    this.state.selectedMovableUnitLabel.Name.toLowerCase() == 'systemset'
                ) {
                    isMuLabelNull = true;
                }
            }
            let locationName = '';
            if (this.state.itemSummary.Location && this.state.itemSummary.Location.Name != "Select a location") {
                locationName = this.state.itemSummary.Location.Name;
            }
            var receiptLineItemDataToSave = {
                "Description": this.state.itemSummary.Description,
                "HoldStatus": (this.state.itemSummary.AutoHoldOnReceive) ? this.state.itemSummary.AutoHoldOnReceive : this.state.itemSummary.HoldStatus,
                "HoldReason": (this.state.itemSummary.AutoHoldOnReceive) ? this.translate('Label_AutoHoldOnReceive_Reason') : this.state.itemSummary.HoldReason,
                "SavedElements": this.state.lineItemCustomFieldsGridData,
                "SKU": this.state.itemSummary.Sku,
                "Qualifier": (this.state.itemSummary.Qualifier) ? this.state.itemSummary.Qualifier.Qualifier : "",
                "Qty": (this.state.itemSummary.PrimaryInvQty) ? this.state.itemSummary.PrimaryInvQty : this.state.labelPrimaryRec,
                "PrimaryUnits": this.state.itemSummary.PrimaryUom,
                "SecondaryQty": _SecondaryQty,
                "SecondaryUnits": this.state.itemSummary.SecondaryUom,
                "Weight": this.state.itemSummary.Weight,
                "Volume": volume,
                "LotNumber": this.state.itemSummary.LotNumber,
                "SerialNumber": this.state.itemSummary.SerialNumber,
                "ExpirationDate": kendo.toString(kendo.parseDate(this.state.itemSummary.ExpDate), 'yyyy-MM-dd'),
                "LandedCost": this.state.itemSummary.LandedCost,
                "Location": (this.state.showSuggestedPutawayLocations && this.state.showRecommendedLocations) ? this.state.itemSummary.PutawayLocation.LocationName : locationName,
                "Supplier": _supplierValue,
                "MovableUnitLabel": (isMuLabelNull) ? null : this.state.selectedMovableUnitLabel.Name,
                "MovableUnitType": (this.state.disableMovableUnits) ? this.state.itemSummary.MovUnitType : this.state.selectedMovableUnitType.Name,

                "MovableUnitLength": this.state.itemSummary.MovUnitLength,
                "MovableUnitWidth": this.state.itemSummary.MovUnitWidth,
                "MovableUnitHeight": this.state.itemSummary.MovUnitHeight,
                "MovableUnitWeight": this.state.itemSummary.MovUnitWeight,
                "SplitLineByMovableUnit": this.state.itemSummary.CreateMultipleMovUnits,
                "MovableUnitNumber": this.state.itemSummary.MovUnitNumber
            };

            if (this.props.sharedLineItemFlow == 'edit') {
                receiptLineItemDataToSave.LineItemEditUri = this.props.sharedSelectedLineItem.LineItemEditUri;
                receiptLineItemDataToSave.ReceiveItemId = this.props.sharedSelectedLineItem.ReceiveItemId;
                receiptLineItemDataToSave.SplitLineByMovableUnit = this.state.itemSummary.CreateMultipleMovUnits;
            }

            var method = (this.props.sharedLineItemFlow == "create") ? "post" : "put";
            var data = {
                editUri: this.props.sharedReceiptData.Receipt.EditUri,
            };
            if (this.props.sharedLineItemFlow == "create") {
                data.addedItem = receiptLineItemDataToSave;
            } else {
                data.updatedItem = receiptLineItemDataToSave;
            }


            if (this.state.selectedMovableUnitLabel.Name) {
                let loc = (this.state.showSuggestedPutawayLocations && this.state.showRecommendedLocations) ? this.state.itemSummary.PutawayLocation.LocationName : this.state.itemSummary.Location.Name;
                if (!loc || loc == 'Select a location') {
                    loc = null; // passing it as null if its blank also
                }
                let receiveItemId = null;
                if (receiptLineItemDataToSave.ReceiveItemId) {
                    receiveItemId = receiptLineItemDataToSave.ReceiveItemId;
                }

                manageReceiptService.checkifMUExistsMultipleLocs(this.state.selectedMovableUnitLabel.Name, this.props.selectedFacility.id, loc, receiveItemId).then((response) => {
                    if (response != 'undefined') {
                        this.createOrUpdateReceiptLineItem(method, data);
                    }
                }).finally(() => {
                    this.setState({ savingGif: false });
                });
            } else {
                this.createOrUpdateReceiptLineItem(method, data);
            }

        }
    }
    createOrUpdateReceiptLineItem = (method, data) => {
        manageReceiptService.saveReceiptLineItem(method, data).then((response) => {
            this.setState({ savingGif: false });
            if (response && response.status == 204 || response.status == 200) {
                var successMsg = this.translate('Label_ReceiptLineItems_Create');
                this.props.onResetLineItemGrid();
                if (this.props.sharedLineItemFlow == 'edit') {
                    successMsg = this.translate('Label_ReceiptLineItems_Update');
                    this.props.onCloseModal();
                }
                GlobalService.notification('success', successMsg);
            }
        }).finally(() => {
            this.setState({ savingGif: false });
        });
    }

    openCreateItem = () => {
        this.props.onSaveReceipt();
        this.props.onCloseModal();
        this.$rootScope.OpenWindow({
            url: '<react-items-holder></react-items-holder>',
            reactComponent: ItemsHolder,
            singleton: true,
            title: 'Manage ' + this.state.customLabels.Label_Item + 's',
            active: true,
            useLineItem: true,
            lineItem: [{
                name: '',
                value: 'Manage ' + this.state.customLabels.Label_Item + 's'
            }],
            icon: 'submenu-icon fa fa-puzzle-piece',
            close: {
                'Title': 'Label_AreYouSure_Title',
                'Button1Title': 'Label_Save_And_Exit',
                'Button1Icon': 'save',
                'Button1Show': true,
                'Button2Title': 'Label_DiscardAndExit',
                'Button2Icon': 'newCancel',
                'Button2Show': true,
                'Button3Title': 'Label_Return',
                'Button3Icon': 'return',
                'Button3Show': true,
                'Paragraph': 'Label_CreateOrder_AreYouSure_Paragraph'
            },
            callerID: 'manage-receipt',
            receiptObj: this.props.sharedReceiptData.Receipt,
            sendToTab: "Item"
        });
    }

    openCreateLocation = () => {
        this.props.onSaveReceipt();
        this.props.onCloseModal();
        this.$rootScope.OpenWindow({
            url: '<react-locations-holder></react-locations-holder>',
            reactComponent: locationsHolder,
            singleton: true,
            title: 'Window_Title_Label_ManageLocations',
            active: true,
            useLineItem: false,
            lineItem: [{
                name: '',
                value: 'Window_Title_Label_ManageLocations'
            }],
            icon: 'submenu-icon fa fa-map-marker',
            callerID: 'manage-receipt',
            receiptObj: this.props.sharedReceiptData.Receipt,
            sendToTab: "Location"
        });
    }

    /* istanbul ignore next */
    saveGridLineItemSavedElementsReceipts = (e) => {
        var requiredElementsComplete = true;
        let gridData = e.sender.dataSource.data();
        var lineItemCustomFieldsGridData = Object.assign([], gridData);

        if (e.model.Required == true && !StringWms.isEmpty(e.values.Value)) {
            var numberRequiredEmptyFields = 0;
            requiredElementsComplete = false;

            this.state.lineItemCustomFieldsGridData.forEach(custFild => {
                if (custFild.Required == true && StringWms.isEmpty(custFild.Value)) {
                    numberRequiredEmptyFields++;
                }
            });

            if (numberRequiredEmptyFields <= 1) {
                requiredElementsComplete = true;
            }
        } else if (e.model.Required == true && StringWms.isEmpty(e.values.Value)) {
            requiredElementsComplete = false;
        } else {

            requiredElementsComplete = this.state.lineItemCustomFieldsGridData.every(custFild => {
                return !(custFild.Required == true && StringWms.isEmpty(custFild.Value));
            });
        }

        this.setState({
            requiredElementsComplete: requiredElementsComplete,
            lineItemCustomFieldsGridData: lineItemCustomFieldsGridData
        }, () => { this.checkItemReadyForSave(); });
    }

    initialiseCustomFieldGridData = () => {

        var lineItemCustomFieldsGridData = this.props.sharedReceiptData.ReceiptLineItemPredefinedOnlySE;
        var requiredElementsComplete = true;

        if (this.props.sharedLineItemFlow == 'edit') {
            if (this.props.sharedSelectedLineItem.SavedElements.length != 0) {
                lineItemCustomFieldsGridData = Object.assign([], this.props.sharedSelectedLineItem.SavedElements);
            }
        } else {
            if (this.state.showCustomFieldInfo) {
                for (var i = 0; i < lineItemCustomFieldsGridData.length; i++) {
                    if (lineItemCustomFieldsGridData[i].Required) {
                        requiredElementsComplete = false;
                    }
                }
            }

        }
        this.setState({
            lineItemCustomFieldsGridData: lineItemCustomFieldsGridData,
            requiredElementsComplete: requiredElementsComplete
        });
    }
    // Focus next element after sku select
    nextFocusAfterSkuSelect = () => {
        setTimeout(() => {
            if (this.state.showQualifier) {
                $("#QualifierComboBoxReceiptAddLineItem").data("kendoDropDownList").focus();
            } else if (this.state.itemSummary.PrimaryUom != null) {
                $("#" + this.props.windowId + "PrimaryInvQty").focus();
            } else if (this.state.itemSummary.SecondaryUom != null && this.state.itemSummary.TrackBys.TrackSerialNumber != 4) {
                $("#" + this.props.windowId + "SecondaryInvQty").focus();
            }
        });
    }

    nextFocusAfterQualifierSelect = () => {
        setTimeout(() => {
            if (this.state.itemSummary.PrimaryUom != null) {
                $("#" + this.props.windowId + "PrimaryInvQty").focus();
            } else if (this.state.itemSummary.SecondaryUom != null && this.state.itemSummary.TrackBys.TrackSerialNumber != 4) {
                $("#" + this.props.windowId + "SecondaryInvQty").focus();
            }
        });
    }

    nextFocusAfterMovableUnitTypeList = () => {
        setTimeout(() => {
            $("#MovUnitLength").focus();
        });
    }

    render() {
        return (
            <div>
                <WmsModel
                    id={this.props.windowId + 'ManageLineItemsModal'}
                    title={
                        (this.props.sharedLineItemFlow == 'edit') ?
                            this.translate('Label_Edit_Receipt_Line_Items') :
                            this.translate('Label_Add_Receipt_Line_Items')
                    }
                    width={1000}
                    height={560}
                    onCloseFunc={this.props.onCloseModal}
                    isOpen={this.props.sharedIsOpenManageLineItemsModal}
                    windowId={this.props.windowId}
                    class={'ManageLineItemsModal'}
                    customClass='wms-model-new-ui-wrapper' >
                    {(this.props.sharedIsOpenManageLineItemsModal) &&

                        <div className='model-content-wrapper form'>
                            <WmsContainer>
                                {this.props.sharedLineItemFlow == 'create' &&
                                    <WmsDropdownList
                                        key={"SkuDropDownListReceiptAddLineItem"+this.state.itemSummary.Id}
                                        id={this.props.windowId + "SkuDropDownListReceiptAddLineItem"}
                                        name={this.props.windowId + "SkuDropDownListReceiptAddLineItem"}
                                        label={this.state.customLabels.Label_Sku}
                                        showDetailsTooltip={true}
                                        optionsLabel={{
                                            Sku: this.translate('Label_Select_Sku'),
                                            Description: this.translate('Label_Select_Sku'),
                                            Id: 0
                                        }}
                                        wmsSelector={this.state.selector + "SelectSku"}
                                        extraWrapperClass="receit-addlineitem-sku"
                                        value={this.state.itemSummary.Id}
                                        required={this.state.itemSummary.Id == 0}
                                        onChangeFunc={(e) => this.handleSkuChange(e)}
                                        textField='Sku'
                                        valueField='Id'
                                        template={"<span style='white-space: nowrap;text-overflow: ellipsis;width: 210px;display: block;overflow: hidden'>${ Sku } - ${Description}</span>"}
                                        disabled={(this.props.sharedLineItemFlow == "edit")}
                                        nextFocus={this.nextFocusAfterSkuSelect}
                                        virtualObj={{
                                            itemHeight: 45,
                                            valueMapper: (options) => {
                                                var that = options;
                                                this.setState({ skuOptionsLoaded: false });
                                                $.ajax({
                                                    url: "/WebUI/Receiving/ManageReceivings/SkuDropdownIndexRead",
                                                    type: "POST",
                                                    dataType: "json",
                                                    data: {
                                                        itemId: that.value || 0,
                                                        customerId: this.props.selectedCustomer.id
                                                    },
                                                    success: (data) => {
                                                        options.success(data);
                                                        this.setState({ skuOptionsLoaded: true });
                                                    }
                                                });
                                            }
                                        }}
                                        dataSourceObj={{
                                            type: "aspnetmvc-ajax",
                                            pageSize: 30,
                                            serverPaging: true,
                                            serverFiltering: true,
                                            transport: {
                                                read: {
                                                    url: "/WebUI/Receiving/ManageReceivings/SkuDropDownRead",
                                                    type: "POST",
                                                    data: {
                                                        customerId: this.props.selectedCustomer.id
                                                    }
                                                }
                                            },
                                            schema: {
                                                data: "Data",
                                                total: "Total"
                                            }
                                        }}
                                    />
                                }
                                {this.props.sharedLineItemFlow == 'edit' &&

                                    <WmsInput
                                        id='SKU_Label'
                                        inputName='SKU_Label'
                                        wmsSelector={this.state.selector + 'Skulabel'}
                                        onChangeFunc={() => { }}
                                        label={this.state.customLabels.Label_Sku}
                                        value={this.props.sharedSelectedLineItem.SKU}
                                        disabled={true}
                                    />
                                }
                                {this.state.showQualifier &&
                                    <WmsDropdownList
                                        id="QualifierComboBoxReceiptAddLineItem"
                                        name="QualifierComboBoxReceiptAddLineItem"
                                        label={this.translate('Label_Qualifier')}
                                        wmsSelector={this.state.selector + "Qualifier"}
                                        value={this.state.itemSummary.Qualifier?.SummaryLink}
                                        onChangeFunc={this.qualifierOptionsChange}
                                        textField="Qualifier"
                                        valueField="SummaryLink"
                                        nextFocus={this.nextFocusAfterQualifierSelect}
                                        isVirtualOptions={false}
                                        required={true}
                                        staticOptions={this.state.itemSummary.QualifierSummaries}
                                    />
                                }

                                {this.state.itemSummary.PrimaryUom != null &&
                                    <WmsInput
                                        id={this.props.windowId + 'PrimaryInvQty'}
                                        inputName='PrimaryInvQty'
                                        wmsSelector={this.state.selector + 'PrimaryInvQty'}
                                        onChangeFunc={this.showRecommendation}
                                        label={
                                            this.translate('Label_Primary_Quantity') +
                                            ((this.state.itemSummary.PrimaryUom) ? ' (' + this.state.itemSummary.PrimaryUom + ') ' : ' ') +
                                            (this.state.labelPrimaryRec ? this.translate('Label_Recommend') + ': ' + this.state.labelPrimaryRec : '')
                                        }
                                        value={this.state.itemSummary.PrimaryInvQty}
                                        required={(this.state.itemSummary.PrimaryInvQty == null || this.state.itemSummary.PrimaryInvQty == "") && (this.state.itemSummary.SecondaryInvQty == null || this.state.itemSummary.SecondaryInvQty == "")}
                                        regularExp={this.state.itemSummary.PrimaryIsIntegral ? /(^[0-9]+$|^$)/ : /^[0-9]*(?:\.[0-9]{0,4})?$/}
                                        disabled={this.state.itemSummary.TrackBys.TrackSerialNumber == 4}
                                        onBlurFunc={this.eventCalculateWeight}
                                    />
                                }

                                {(this.state.itemSummary.SecondaryUom != null && this.state.itemSummary.TrackBys.TrackSerialNumber != 4) &&
                                    <WmsInput
                                        id={this.props.windowId + 'SecondaryInvQty'}
                                        inputName='SecondaryInvQty'
                                        wmsSelector={this.state.selector + 'SecondaryInvQty'}
                                        regularExp={this.state.itemSummary.SecondaryIsIntegral ? /(^[0-9]+$|^$)/ : /^[0-9]*(?:\.[0-9]{0,4})?$/}
                                        onChangeFunc={this.showRecommendation}
                                        label={
                                            this.translate('Label_Primary_Quantity') +
                                            ((this.state.itemSummary.SecondaryUom) ? ' (' + this.state.itemSummary.SecondaryUom + ') ' : ' ') +
                                            (this.state.labelSecondaryRec ? this.translate('Label_Recommend') + ': ' + this.state.labelSecondaryRec : '')
                                        }
                                        value={this.state.itemSummary.SecondaryInvQty}
                                        required={(this.state.itemSummary.PrimaryInvQty == null || this.state.itemSummary.PrimaryInvQty == "") && (this.state.itemSummary.SecondaryInvQty == null || this.state.itemSummary.SecondaryInvQty == "")}
                                        onBlurFunc={this.eventCalculateWeight}
                                    />
                                }
                                {this.state.itemSummary.Id != 0 &&
                                    <WmsInput
                                        id='weightKg'
                                        inputName='weightKg'
                                        wmsSelector={this.state.selector + 'WeightKg'}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                        onChangeFunc={(e) => { this.handleChangeEvent(e, 'Weight'); }}
                                        label={this.translate('Label_Weight') + ' (' + this.state.displayWeightUnit + ')'}
                                        value={this.state.itemSummary.Weight}
                                    />
                                }

                            </WmsContainer>

                            {this.state.itemSummary.Id != 0 &&
                                <div>
                                    {this.state.showTrackBys &&
                                        <WmsContainer>
                                            {this.state.itemSummary.TrackBys.TrackLotNumber != 0 &&
                                                <WmsInput
                                                    id='LotNumber'
                                                    inputName='LotNumber'
                                                    wmsSelector={this.state.selector + 'LotNumber'}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'LotNumber'); }}
                                                    required={
                                                        this.state.itemSummary.TrackBys.TrackLotNumber == 2 && !this.state.itemSummary.LotNumber
                                                    }
                                                    label={this.state.customLabels.Label_Lot_Number}
                                                    value={this.state.itemSummary.LotNumber}
                                                />
                                            }

                                            {this.state.itemSummary.TrackBys.TrackSerialNumber != 0 &&
                                                <WmsInput
                                                    id='SerialNumber'
                                                    inputName='SerialNumber'
                                                    wmsSelector={this.state.selector + 'SerialNumber'}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'SerialNumber'); }}
                                                    required={
                                                        (this.state.itemSummary.TrackBys.TrackSerialNumber == 3 || this.state.itemSummary.TrackBys.TrackSerialNumber == 4) && !this.state.itemSummary.SerialNumber
                                                    }
                                                    label={this.state.customLabels.Label_Serial_Number}
                                                    value={this.state.itemSummary.SerialNumber}
                                                />
                                            }

                                            {this.state.itemSummary.TrackBys.TrackCost != 0 &&
                                                <WmsInput
                                                    id='LandedCost'
                                                    inputName='LandedCost'
                                                    wmsSelector={this.state.selector + 'LandedCost'}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'LandedCost'); }}
                                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                                    required={this.state.itemSummary.TrackBys.TrackCost == 2 && !this.state.itemSummary.LandedCost}
                                                    label={this.translate('Label_Landed_Cost')}
                                                    value={this.state.itemSummary.LandedCost}
                                                />
                                            }

                                            {this.state.itemSummary.TrackBys.TrackExpirationDate != 0 &&
                                                <WmsDateTimePicker
                                                    ref="refExpirationDate"
                                                    id={'ExpDate'}
                                                    name='ExpDate'
                                                    label={this.translate('Label_Expiration_Date')}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'ExpDate'); }}
                                                    required={
                                                        this.state.itemSummary.TrackBys.TrackExpirationDate == 2 &&
                                                        (
                                                            this.state.itemSummary.ExpDate == null ||
                                                            this.state.itemSummary.ExpDate == ""
                                                        )
                                                    }
                                                    value={this.state.itemSummary.ExpDate}
                                                    wmsSelector={this.state.selector + "EarliestShipDate"}
                                                    defaultTime={"00:00:00"}
                                                    format={'M/d/yyyy'}
                                                    onlyDate={true}
                                                />
                                            }
                                        </WmsContainer>
                                    }

                                    <WmsContainer>
                                        {this.state.showMovableUnitFields &&
                                            <div>
                                                <WmsComboBox
                                                    id="MovableUnitLabelList"
                                                    name="MovableUnitLabelList"
                                                    label={this.state.customLabels.Label_Movable_Unit}
                                                    wmsSelector={this.state.selector + "MovableUnitList"}
                                                    value={this.state.selectedMovableUnitLabel.Name}
                                                    onChangeFunc={(e) => this.eventMovableUnitLabelsChange(e)}
                                                    textField="Name"
                                                    valueField="Name"
                                                    placeholder={'Select MU Label'}
                                                    options={this.state.optionsMovableUnitLabels}
                                                    /*parentClassName={''}*/
                                                    required={this.props.selectedCustomer.options.trackPallets == 2 && this.state.selectedMovableUnitLabel.Name == ''}
                                                />

                                                <WmsCheckBox
                                                    id={'CreateMultipleMovUnits' + this.props.windowId}
                                                    name={'CreateMultipleMovUnits' + this.props.windowId}
                                                    wmsSelector={this.state.selector + 'CreateMultipleMovUnits'}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'CreateMultipleMovUnits'); }}
                                                    label={"Split " + this.state.customLabels.Label_Movable_Unit}
                                                    checked={this.state.itemSummary.CreateMultipleMovUnits}
                                                    disabled={this.state.disableSplitMU}
                                                />

                                                <WmsInput
                                                    id='MovUnitNumber'
                                                    inputName='MovUnitNumber'
                                                    wmsSelector={this.state.selector + 'MovUnitNumber'}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'MovUnitNumber'); }}
                                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                                    label={this.translate('Label_Movable_Unit_Number')}
                                                    value={this.state.itemSummary.MovUnitNumber}
                                                    disabled={this.state.selectedMovableUnitLabel.Name == 'None' || !this.state.itemSummary.CreateMultipleMovUnits}
                                                />

                                                {!this.state.disableMovableUnits &&
                                                    <WmsDropdownList
                                                        id="MovableUnitTypeList"
                                                        name="MovableUnitTypeList"
                                                        optionsLabel='Select an element'
                                                        label={this.state.customLabels.Label_Movable_Unit + ' Type'}
                                                        wmsSelector={this.state.selector + "MovableUnitTypeList"}
                                                        value={this.state.selectedMovableUnitType.Name}
                                                        onChangeFunc={this.eventMovableUnitTypeChange}
                                                        textField="Name"
                                                        valueField="Name"
                                                        isVirtualOptions={false}
                                                        staticOptions={this.state.optionsMovableUnitTypes}
                                                        disabled={this.state.disableMovableUnits}
                                                        nextFocus={this.nextFocusAfterMovableUnitTypeList}
                                                    />
                                                }
                                                {this.state.disableMovableUnits &&
                                                    <WmsInput
                                                        id='MovUnitType'
                                                        inputName='MovUnitType'
                                                        wmsSelector={this.state.selector + 'MovUnitType'}
                                                        onChangeFunc={(e) => { }}
                                                        label={this.state.customLabels.Label_Movable_Unit + ' Type'}
                                                        value={this.state.itemSummary.MovUnitType}
                                                        disabled={this.state.disableMovableUnits}
                                                    />
                                                }

                                                <WmsInput
                                                    id='MovUnitLength'
                                                    inputName='MovUnitLength'
                                                    wmsSelector={this.state.selector + 'MovUnitLength'}
                                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'MovUnitLength'); }}
                                                    label={this.translate('Label_Length') + ' (' + this.state.displayHeightLengthWidthUnit + ')'}
                                                    value={this.state.itemSummary.MovUnitLength}
                                                    disabled={this.state.disableMovableUnits}
                                                />
                                                <WmsInput
                                                    id='MovUnitWidth'
                                                    inputName='MovUnitWidth'
                                                    wmsSelector={this.state.selector + 'MovUnitWidth'}
                                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'MovUnitWidth'); }}
                                                    label={this.translate('Label_Width') + ' (' + this.state.displayHeightLengthWidthUnit + ')'}
                                                    value={this.state.itemSummary.MovUnitWidth}
                                                    disabled={this.state.disableMovableUnits}
                                                />
                                                <WmsInput
                                                    id='MovUnitHeight'
                                                    inputName='MovUnitHeight'
                                                    wmsSelector={this.state.selector + 'MovUnitHeight'}
                                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'MovUnitHeight'); }}
                                                    label={this.translate('Label_Height') + ' (' + this.state.displayHeightLengthWidthUnit + ')'}
                                                    value={this.state.itemSummary.MovUnitHeight}
                                                    disabled={this.state.disableMovableUnits}
                                                />
                                                <WmsInput
                                                    id='MovUnitWeight'
                                                    inputName='MovUnitWeight'
                                                    wmsSelector={this.state.selector + 'MovUnitWeight'}
                                                    regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'MovUnitWeight'); }}
                                                    label={this.translate('Label_Weight') + ' (' + this.state.displayWeightUnit + ')'}
                                                    value={this.state.itemSummary.MovUnitWeight}
                                                    disabled={this.state.disableMovableUnits}
                                                />
                                            </div>
                                        }
                                        <div>
                                            {
                                                (this.props.selectedCustomer.options.trackSupplier != undefined && this.props.selectedCustomer.options.trackSupplier != 0) &&
                                                <WmsDropdownList
                                                    id="SupplierDropDownList"
                                                    name="SupplierDropDownList"
                                                    optionsLabel={{
                                                        CompanyName: this.translate('Label_Select_Supplier'),
                                                        Name: this.translate('Label_Select_Supplier'),
                                                        Id: 0
                                                    }}
                                                    label={this.translate('Label_Supplier')}
                                                    wmsSelector={this.state.selector + "SupplierDropDownList"}
                                                    value={this.state.itemSummary.Supplier.Id}
                                                    onChangeFunc={(e) => { this.handleChangeEvent(e, 'Supplier'); }}
                                                    textField="CompanyName"
                                                    valueField="Id"
                                                    required={this.props.selectedCustomer.options.trackSupplier === 2 && this.state.itemSummary.Supplier.Id === 0}
                                                    onDataBound={this.suplierDropdownDataBound}
                                                    virtualObj={{
                                                        itemHeight: 45,
                                                        valueMapper: (options) => {
                                                            var that = options;
                                                            $.ajax({
                                                                url: "/WebUI/Receiving/ManageReceivings/SupplierDropdownIndexRead",
                                                                type: "POST",
                                                                dataType: "json",
                                                                data: {
                                                                    supplierId: that.value.Id || 0,
                                                                    supplierIndexUri: this.props.sharedReceiptData.SupplierIndexUri
                                                                },
                                                                success: (data) => {
                                                                    options.success(data);
                                                                }
                                                            });
                                                        }
                                                    }}
                                                    dataSourceObj={{
                                                        type: "aspnetmvc-ajax",
                                                        pageSize: 30,
                                                        serverPaging: true,
                                                        serverFiltering: true,
                                                        transport: {
                                                            read: {
                                                                url: "/WebUI/Receiving/ManageReceivings/SupplierDropDownRead",
                                                                type: "POST",
                                                                data: {
                                                                    suppliersUri: this.props.sharedReceiptData.SuppliersUri
                                                                }
                                                            }
                                                        },
                                                        schema: {
                                                            data: "Data",
                                                            total: "Total"
                                                        }
                                                    }}
                                                />
                                            }
                                        </div>
                                    </WmsContainer>


                                    <WmsContainer>

                                        {this.state.showSuggestedPutawayLocations &&
                                            <div>
                                                <WmsRadio
                                                    label={this.translate("Label_View_Recommended_Locations")}
                                                    name={"Locations" + this.props.windowId}
                                                    value={this.state.showRecommendedLocations == true}
                                                    wmsSelector={this.state.selector + "RecommendedLocations"}
                                                    onChangeFunc={this.eventChangeLocationList}
                                                    checked={this.state.showRecommendedLocations == true}
                                                />

                                                <WmsRadio
                                                    label={this.translate("Label_View_All_Locations")}
                                                    name={"Locations" + this.props.windowId}
                                                    value={this.state.showRecommendedLocations == false}
                                                    wmsSelector={this.state.selector + "AllLocations"}
                                                    onChangeFunc={this.eventChangeLocationList}
                                                    checked={this.state.showRecommendedLocations == false}
                                                />
                                                {this.state.showRecommendedLocations &&
                                                    <WmsDropdownList
                                                        id="SuggestedPutawayLocationDropDownList"
                                                        name="SuggestedPutawayLocationDropDownList"
                                                        optionsLabel={this.translate('Label_Select_Location')}
                                                        label={this.translate('Label_Put_Away_Location')}
                                                        wmsSelector={this.state.selector + "PutAwayLocationDropDownList"}
                                                        required={this.props.selectedCustomer.options.trackLocation === 2 && !this.state.itemSummary.PutawayLocation.LocationName}
                                                        value={this.state.itemSummary.PutawayLocation.LocationName}
                                                        onChangeFunc={(e) => { this.handleChangeEvent(e, 'PutawayLocation'); }}
                                                        textField="LocationName"
                                                        valueField="LocationName"
                                                        isVirtualOptions={false}
                                                        staticOptions={this.state.suggestedPutawayLocationsOptions}
                                                        template={'#: LocationName #    (#: Quantity #)'}
                                                    />
                                                }
                                            </div>
                                        }
                                        {(this.state.showLocationDropdown &&
                                            (this.state.showSuggestedPutawayLocations ? !this.state.showRecommendedLocations : true) &&
                                            this.props.selectedCustomer.options.trackLocation != undefined &&
                                            this.props.selectedCustomer.options.trackLocation != 0) &&
                                            <WmsDropdownList
                                                id="LocationDropDownList"
                                                name="LocationDropDownList"
                                                label={this.translate('Label_Put_Away_Location')}
                                                wmsSelector={this.state.selector + "LocationDropDownList"}
                                                value={this.state.itemSummary.Location.Id}
                                                onChangeFunc={(e) => { this.handleChangeEvent(e, 'Location'); }}
                                                filterType={"startswith"}
                                                textField="Name"
                                                valueField="Id"
                                                optionsLabel={{
                                                    Name: this.translate('Label_Select_Location'),
                                                    Description: this.translate('Label_Select_Location'),
                                                    Id: 0
                                                }}
                                                // onDataBound={this.locationDropdownDataBound}
                                                required={this.props.selectedCustomer.options.trackLocation === 2 && this.state.itemSummary.Location.Id === 0}
                                                virtualObj={{
                                                    itemHeight: 45,
                                                    delay: 1000,
                                                    valueMapper: (options) => {
                                                        var that = options;
                                                        $.ajax({
                                                            url: "/WebUI/Receiving/ManageReceivings/LocationDropdownIndexRead",
                                                            type: "POST",
                                                            dataType: "json",
                                                            data: {
                                                                locationId: that.value || 0,
                                                                facilityId: this.props.selectedFacility.id
                                                            },
                                                            success: (data) => {
                                                                options.success(data);
                                                            }
                                                        });
                                                    }
                                                }}
                                                dataSourceObj={{
                                                    type: "aspnetmvc-ajax",
                                                    pageSize: 30,
                                                    serverPaging: true,
                                                    serverFiltering: true,
                                                    transport: {
                                                        read: {
                                                            url: "/WebUI/Receiving/ManageReceivings/LocationDropDownRead",
                                                            type: "POST",
                                                            data: {
                                                                facilityId: this.props.selectedFacility.id
                                                            }
                                                        }
                                                    },
                                                    serverSorting: true,
                                                    sort: { field: "Name", dir: "asc" },
                                                    schema: {
                                                        data: "Data",
                                                        total: "Total"
                                                    }
                                                }}
                                            />
                                        }
                                    </WmsContainer>


                                    <WmsContainer>
                                        {(this.state.itemSummary.AutoHoldOnReceive) &&
                                            <WmsCheckBox
                                                id={'itemHoldStatus' + this.props.windowId}
                                                name={'itemHoldStatus' + this.props.windowId}
                                                wmsSelector={this.state.selector + 'ItemHoldStatus'}
                                                onChangeFunc={(e) => this.handleChangeEvent(e, 'AutoHoldOnReceive')}
                                                label={this.translate('Label_PutItem_OnHold')}
                                                checked={this.state.itemSummary.AutoHoldOnReceive == true}
                                                disabled={this.state.itemSummary.AutoHoldOnReceive}
                                            />
                                        }

                                        {(!this.state.itemSummary.AutoHoldOnReceive) &&
                                            <WmsCheckBox
                                                id={'ItemAutoHoldOnReceive' + this.props.windowId}
                                                name={'ItemAutoHoldOnReceive' + this.props.windowId}
                                                wmsSelector={this.state.selector + 'ItemAutoHoldOnReceive'}
                                                onChangeFunc={(e) => this.handleChangeEvent(e, 'HoldStatus')}
                                                label={this.translate('Label_PutItem_OnHold')}
                                                checked={this.state.itemSummary.HoldStatus == true}
                                            />
                                        }

                                        {(this.state.itemSummary.HoldStatus) &&
                                            <WmsDropdown
                                                id="ReasonForHold"
                                                name="ReasonForHold"
                                                label={this.translate('Label_Reason_for_Hold')}
                                                wmsSelector={this.state.selector + "ReasonForHold"}
                                                value={this.state.itemSummary.HoldReason}
                                                onChangeFunc={(e) => this.handleChangeEvent(e, 'HoldReason')}
                                                options={this.state.holdReasonOptions}
                                                textField="Name"
                                                valueField="Value"
                                                required={this.state.itemSummary.HoldStatus && !this.state.itemSummary.HoldReason}
                                                blankFirstOption={true}
                                            />
                                        }
                                    </WmsContainer>


                                    {this.state.showCustomFieldInfo &&
                                        <WmsContainer>
                                            <div className="wms-element-wrapper">
                                                <WmsInput
                                                    id='customFieldName'
                                                    inputName='customFieldName'
                                                    wmsSelector={this.state.selector + 'customFieldName'}
                                                    onChangeFunc={(e) => this.setCustomFieldData(e, 'Name')}
                                                    label={this.translate('Label_Name')}
                                                    value={this.state.customField.Name}
                                                />
                                                <WmsInput
                                                    id='customFieldValue'
                                                    inputName='customFieldValue'
                                                    wmsSelector={this.state.selector + 'customFieldValue'}
                                                    onChangeFunc={(e) => this.setCustomFieldData(e, 'Value')}
                                                    label={this.translate('Label_Value')}
                                                    value={this.state.customField.Value}
                                                />
                                                <WmsButton
                                                    label={this.translate('Label_New_Custom_Field')}
                                                    wmsSelector={this.state.selector + 'AddNewButton'}
                                                    onClickFunc={this.addCustomeElement}
                                                    disabled={(this.state.customField.Name == null || $.trim(this.state.customField.Name) == "") || (this.state.customField.Value == null || $.trim(this.state.customField.Value) == "")}
                                                    icon={'fa-plus'}
                                                />
                                            </div>

                                            <WmsGrid
                                                columns={[
                                                    {
                                                        field: 'Name',
                                                        title: "Name",
                                                        width: 180,
                                                        lockable: false,
                                                        template: "#= Required === true ? Name + '*' : Name #"
                                                    }, {
                                                        field: 'Value',
                                                        title: "Value",
                                                        width: 180,
                                                        lockable: false,
                                                        template: "#= Value === null && Required === true  ? '<i class=\"wms-required-grid\">"
                                                            + this.translate('Label_Enter_Value')
                                                            + "*</i>' : Value === null && Required === false ? '<i>"
                                                            + this.translate('Label_Enter_Value')
                                                            + "</i>' : Value === '' && Required === true ? '<i class=\"wms-required-grid\">"
                                                            + this.translate('Label_Enter_Value')
                                                            + "*</i>' : Value === '' && Required === false ? '<i>"
                                                            + this.translate('Label_Enter_Value')
                                                            + "</i>' : Value #"
                                                    }
                                                ]}
                                                schema={{
                                                    model: {
                                                        fields: {
                                                            Name: {
                                                                type: 'string',
                                                                editable: false
                                                            },
                                                            Value: {
                                                                type: 'string',
                                                                editable: true
                                                            },
                                                            Required: {
                                                                type: 'boolean'
                                                            }
                                                        }
                                                    }
                                                }}
                                                gridKeySelection={'Name'}
                                                showGridResult={false}
                                                virtual={false}
                                                gridEditable={true}
                                                shawCheckbox={false}
                                                showResetButton={false}
                                                staticGridData={this.state.lineItemCustomFieldsGridData}
                                                gridName={"GridReceiptLineItemCustomFields"}
                                                id={"GridReceiptLineItemCustomFields" + this.props.windowId}
                                                wmsSelector={this.state.selector + 'CustomFieldsGrid'}
                                                onSelection={() => function () { }}
                                                onEdit={(e) => function (e) {
                                                    e.container.find("input[name=Value]").attr("maxlength", 255);
                                                }}
                                                onGridSave={this.saveGridLineItemSavedElementsReceipts}
                                                gridHeight={150}
                                                menu={[]}
                                            />
                                        </WmsContainer>
                                    }

                                </div>
                            }
                        </div>
                    }
                    {(this.props.sharedIsOpenManageLineItemsModal) &&
                        <div className="footer-btn-holder">
                            <WmsButton
                                label={this.translate("Label_Close")}
                                wmsSelector={this.state.selector + 'ExitButton'}
                                onClickFunc={this.props.onCloseModal}
                                icon="fa-times-circle-o"
                                buttonType={'orange'}
                            />
                            {this.props.sharedLineItemFlow == 'create' &&
                                <WmsButton
                                    label={this.translate('Label_Clear')}
                                    wmsSelector={this.state.selector + 'ClearButton'}
                                    onClickFunc={this.resetLineItemsSection}
                                    icon={'fa-minus'}
                                    buttonType={'orange'}
                                />
                            }
                            <WmsButton
                                label={(this.props.sharedLineItemFlow == 'create') ? this.translate('Label_Add') : this.translate('Label_Save_and_Exit')}
                                wmsSelector={this.state.selector + 'AddExitButton'}
                                onClickFunc={this.saveLineItemAndExit}
                                icon={(this.props.sharedLineItemFlow == 'create') ? 'fa-plus' : 'fa-solid fa-floppy-disk'}
                                showToolTip={!this.state.isItemReadyForSave}
                                tooltipText={this.translate("Label_Fill_Out_Required")}
                                disabled={(this.state.savingGif || !this.state.isItemReadyForSave)}
                                loading={this.state.savingGif}
                            />



                            {this.state.Rights.hasOwnProperty('customer.items.manage') &&
                                <WmsButton
                                    label={this.translate('Label_Create_Item')}
                                    wmsSelector={this.state.selector + 'CreateItem'}
                                    onClickFunc={this.openCreateItem}
                                    icon={'fa-plus'}
                                    disabled={this.state.openCreateItemGif}
                                    loading={this.state.openCreateItemGif}
                                />
                            }


                            {(this.state.Rights.hasOwnProperty('warehouse.locations') && this.state.isCustomerUser == false) &&
                                <WmsButton
                                    label={this.translate('Window_Title_Label_CreateLocation')}
                                    wmsSelector={this.state.selector + 'CreateLocation'}
                                    onClickFunc={this.openCreateLocation}
                                    icon={'fa-plus'}
                                    disabled={this.state.openCreateLocationGif}
                                    loading={this.state.openCreateLocationGif}
                                />
                            }

                        </div>
                    }
                </WmsModel>
            </div>
        );
    }
}
