import React, { Component } from 'react';


import './available-rates.scss';

import { StringWms } from '../../../../assets/ts/StringWms.ts';
// Component
import WmsModel from '../../components/WmsModel/WmsModel.jsx';
import WmsButton from '../../components/WmsButton/WmsButton.jsx';
import WmsFormGrid from '../../components/WmsFormGrid/WmsFormGrid.jsx';
import * as GlobalService from '../../global_services';
import * as smallParcelService from '../smallParcel.service.jsx';
import ViewRateShoppingWarnings from "./view-rate-shopping-warnings.component";
import ViewRateShoppingDetails from "./view-rate-shopping-details.component";

export class AvailableRates extends Component {

    constructor(props) {
        super(props);


        this.$rootScope = window;
        this.translate = window.translate;
        let gridHeaders = StringWms.convertSringToOption(this.translate('AvailableRates_GridHeaders'));
        this._isMounted = false;
        this.state = {
            selector: "availableRates",
            availableRatesGridData: null,
            rateShopWarningsData: [],
            isOpenViewRateShoppingWarningsModal: false,
            rateShopDetailsData: [],
            isOpenViewRateShoppingDetailsModal: false,
            carrierRoutingData: null,
            selectedRateItems: null,
            saveRateLoading: false,
            selectedRate: 0,
            gridColumns: [
                {
                    field: "Carrier",
                    title: gridHeaders[0].description,
                    width: 120
                }, {
                    field: "Service",
                    title: gridHeaders[1].description,
                    width: 240
                }, {
                    field: "DeliveryDate",
                    title: gridHeaders[2].description,
                    width: 180,
                    template: "#= DeliveryDate != null && DeliveryDate != '0001-01-01T00:00:00' ? kendo.toString(kendo.parseDate(DeliveryDate), 'MM/dd/yyyy by hh:mm tt') : '-' #"
                }, {
                    field: "Rate",
                    title: gridHeaders[3].description,
                    width: 120,
                    template: '#if(RateDetails) {# <div class="rate-details">#=parseFloat(Rate.total).toFixed(2)#</div> #} else{# #=parseFloat(Rate.total).toFixed(2)# #}#',
                }, {
                    field: "CarrierAccount",
                    title: gridHeaders[4].description,
                    width: 120
                }, {
                    field: "Nickname",
                    title: gridHeaders[5].description,
                    width: 120
                }
            ],
            gridSchema: {
                data: [],
                schema: {
                    model: {
                        fields: {
                            Carrier: {
                                type: "string",
                                editable: false
                            },
                            Service: {
                                type: "string",
                                editable: false
                            },
                            Eta: {
                                type: "string",
                                editable: false
                            },
                            Rate: {
                                type: "string",
                                editable: false
                            },
                            CarrierAccount: {
                                type: "string",
                                editable: false
                            },
                            Nickname: {
                                type: "string",
                                editable: false
                            }
                        }
                    }
                }
            }
        }
    }

    componentDidMount() {
        this._isMounted = true;
        kendo.ui.progress($('#AvailableRates'), true);
        smallParcelService.initialAvailableRates(this.props.customerId, this.props.sharedSelectedOrderIds).then((response) => {
            this.setState({
                carrierRoutingData: response.data
            });
        });
        let dataObj = {
            "OrderIds": this.props.sharedSelectedOrderIds,
            "ReturnAllRates": true,
            "MultiOrderRateShopOnShip": false
        }
        smallParcelService.parcelAutoRateShopperStarter(dataObj).then((response) => {
            if(response && response.data) {
                this.getParcelAutoRateShopperStatus(response.data);
            }
        });

        $(document).off("click", ".rate-details");
        $(document).on("click", ".rate-details", showRateDetails);
        let self = this;
        function showRateDetails() {
            let grid = $("#" + self.props.windowId + 'gridAvailableRates').data("kendoGrid");
            let selectedRate = grid.select();

            if (selectedRate.length == 1) {
                self.setState({
                    isOpenViewRateShoppingDetailsModal: true,
                    rateShopDetailsData: grid.dataItem(selectedRate[0])
                });
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getParcelAutoRateShopperStatus = (data) => {
        smallParcelService.parcelAutoRateShopperStatus(data.StatusRelativeUri).then((response) => {
            if(response.data.Done) {
                let tmpAvailableRatesGridData = [];
                if (response.data.Rates && response.data.Rates.ResourceList) {
                    tmpAvailableRatesGridData = [...response.data.Rates.ResourceList].sort((a, b) => a.Rate.total - b.Rate.total);
                }
                this.setState({
                    availableRatesGridData: tmpAvailableRatesGridData,
                    rateShopWarningsData: response.data.Rates.FaultedOrders ? response.data.Rates.FaultedOrders.Faults : []
                });
                kendo.ui.progress($('#AvailableRates'), false);
            } else {
                setTimeout(() => {
                    this.getParcelAutoRateShopperStatus(response.data);
                }, 3000);
            }
        }, (err) => {
            kendo.ui.progress($('#AvailableRates'), false);
            this.props.onCloseAvailableRatesModel();
        });
    };

    selectAvailableRates = () => {
        this.setState({ saveRateLoading: true });

        let routingFormObj = this.state.carrierRoutingData;
        routingFormObj.Carrier.Value = this.state.selectedRateItems.Carrier;
        routingFormObj.Carrier.HasChanged = true;
        routingFormObj.AccountNumber.Value = this.state.selectedRateItems.CarrierAccount;
        routingFormObj.AccountNumber.HasChanged = true;
        routingFormObj.Service.Value = this.props.onGetServiceCode(this.state.selectedRateItems);
        routingFormObj.Service.HasChanged = true;
        smallParcelService.updateRateCarrierAndServices({
            'massRoutingData': routingFormObj,
            'orderIds': this.props.sharedSelectedOrderIds
        }).then((response) => {
            GlobalService.notification('success', this.translate('Rate_Save_Success'));
            this.props.updateCarrierAndServiceInfo()
            this.props.onCloseAvailableRatesModel();
        }).finally(() => {
            if (this._isMounted) {
                this.setState({ saveRateLoading: false });
            }
        });
    }

    onRowSelected = () => {
        let grid = $("#" + this.props.windowId + 'gridAvailableRates').data("kendoGrid");
        let selectedRate = grid.select();
        let selectedRateItems = null;

        if (selectedRate.length == 1) {
            selectedRateItems = grid.dataItem(selectedRate[0]);
        }

        this.setState({
            selectedRateItems: selectedRateItems,
            selectedRate: selectedRate.length
        });
    }

    viewRateShoppingWarnings = () => {
        this.setState({ isOpenViewRateShoppingWarningsModal: true });
    }

    onCloseViewRateShoppingModal = () => {
        this.setState({ isOpenViewRateShoppingWarningsModal: false });
    }

    onCloseViewRateShoppingDetailModal = () => {
        this.setState({ isOpenViewRateShoppingDetailsModal: false });
    }

    render() {
        return (
            <>
                <WmsModel
                    id='AvailableRates'
                    class="available-rates-model-wrapper"
                    title={this.translate('Lable_Available_Rates')}
                    width={1000}
                    height={800}
                    onCloseFunc={this.props.onCloseAvailableRatesModel}
                    isOpen={this.props.isAvailableRatesModelOpen}
                    customClass='wms-model-new-ui-wrapper'
                >

                    <div className="wms-area-content-darker model-content-wrapper wms-area-partial-border">
                        <div className="flex-container">
                            <div className="flex-item-left">{this.translate('Lable_Available_Rates_Description')}</div>
                            {this.state.rateShopWarningsData.length > 0 && (<div className="flex-item-right"><a onClick={this.viewRateShoppingWarnings}>{this.translate('Lable_View_Rates_Shopping_Warnings')}</a></div>)}
                        </div>
                        <WmsFormGrid
                            id={this.props.windowId + 'gridAvailableRates'}
                            wmsSelector={'gridAvailableRates'}
                            gridKeySelection={'DocName'}
                            columns={this.state.gridColumns}
                            staticGridData={this.state.availableRatesGridData}
                            schema={this.state.gridSchema}
                            isColumnsResizable={true}
                            isColumnsReorderable={true}
                            isColumnMenuenable={false}
                            isColumnsSortable={true}
                            parentClassName={'grid-section'}
                            showResetMenu={false}
                            onSelection={this.onRowSelected}
                            IsMultipleSelectable={true}
                        />
                    </div>

                    <div className="footer-btn-holder">
                        <WmsButton
                            label={this.translate('Label_Cancel')}
                            wmsSelector={this.state.selector + "Cancel"}
                            onClickFunc={this.props.onCloseAvailableRatesModel}
                            disabled={false}
                            loading={false}
                            buttonType='orange'
                            icon={'fa-ban'}
                        />
                        <WmsButton
                            id={'AvailableRates' + this.props.windowId}
                            label={this.translate('Label_Save')}
                            wmsSelector={this.state.selector + "Save"}
                            onClickFunc={this.selectAvailableRates}
                            loading={this.state.saveRateLoading}
                            disabled={this.state.selectedRate != 1}
                            tooltipText={
                                (this.state.selectedRate == 0) ?
                                    this.translate("Select_Rate_Tooltip")
                                    : this.translate("Single_Rate_Save_Tooltip")

                            }
                            showToolTip={this.state.selectedRate != 1}
                            icon="fa-floppy-o"
                        />
                    </div>
                </WmsModel>
                {this.state.isOpenViewRateShoppingWarningsModal &&
                    <ViewRateShoppingWarnings
                        open={this.state.isOpenViewRateShoppingWarningsModal}
                        rateShoppingWarningData={this.state.rateShopWarningsData}
                        onClose={this.onCloseViewRateShoppingModal}
                    />
                }
                {this.state.isOpenViewRateShoppingDetailsModal &&
                    <ViewRateShoppingDetails
                        open={this.state.isOpenViewRateShoppingDetailsModal}
                        rateShopDetailsData={this.state.rateShopDetailsData}
                        onClose={this.onCloseViewRateShoppingDetailModal}
                    />
                }
            </>
        );
    }
}