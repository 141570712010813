import React, { useEffect, useState } from "react";
import WmsCheckBox from "../../components/WmsCheckBox/WmsCheckBox";
import "./audit-inventory-review-audit-job-grid.scss";
import WmsButton from "../../components/WmsButton/WmsButton";
import WmsGrid from "../../components/WmsGrid/WmsGrid";
import {
    reviewAuditJobGridColumns,
    reviewAuditJobGridSchema,
} from "./audit-inventory-review-audit-job-grid-configuration";
import { StringWms } from "../../../../assets/ts/StringWms";
import { GetAuditReviewList, GetAuditReviewCSV } from "./review-audit-job.service";
import { DetailSubGrid } from "./detail-sub-grid.component";
import * as GlobalService from "../../global_services";
import _ from "lodash";
import AddLocationModal from "./review-audit-job-add-location.modal.component";
import PropTypes from "prop-types";
import WmsToolTip from "../../components/WmsToolTip/WmsToolTip";

interface Props {
    selector: string;
    auditCountsUris: Array<string>;
    reviewAuditJobTitle: string;
    facilityUri: string;
    mainGridSelectedRowData: any;
    sendSubGridSelectedDataToModalComponent: any;
}

declare var window: any;

const AuditInventoryReviewAuditJobGrid: React.FC<Props> = (props: Props) => {
    const [showVariancesOnly, setShowVariancesOnly] = useState(false);
    const [expandedView, setExpandedView] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedRow, setSelectedRow] = useState(null);
    const [gridData, setGridData] = useState([]);
    const [originalResponseData, setOriginalResponseData] = useState([]);
    const [isAddLocationModalOpen, setIsAddLocationModalOpen] = useState(false);
    const [subGridSelectedRowValue, setSubGridSelectedRowValue] = useState(null);
    const [itemName, setItemName] = useState("");
    const [exportLoading, setExportLoading] = useState(false);

    useEffect(() => {
        (async () => {
            kendo.ui.progress($(`#${selector}`), true);
            const reviewAuditJobResponse = await GetAuditReviewList(props.auditCountsUris);
            if (reviewAuditJobResponse.status) {
                setOriginalResponseData(reviewAuditJobResponse.data.AuditReviews);
            }
            kendo.ui.progress($(`#${selector}`), false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        arrangeAndSetGridData(originalResponseData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [originalResponseData]);

    useEffect(() => {
        let tempGridData: Array<any> = originalResponseData;
        if (showVariancesOnly === true) {
            tempGridData = Object.assign(
                [],
                originalResponseData.filter((grid: any) => {
                    return grid.variance !== 0 || grid.parentId === null;
                })
            );
        }
        arrangeAndSetGridData(tempGridData);
        setTimeout(() => {
            expandOrCollapseGrid(expandedView);
            setSubGridSelectedRowValue(null);
        }, 50);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showVariancesOnly]);

    const selector = props.selector + "ReviewAuditJobGrid";

    const defaultColumns = reviewAuditJobGridColumns(
        StringWms.convertSringToOption(window.translate("Audit_Inventory_Review_Audit_Job_GrideHeaders"))
    );

    const arrangeAndSetGridData = (auditReview: any) => {
        let auditReviews: Array<any> = _.cloneDeep(auditReview);
        let removeAuditReviewIds: Array<number> = [];
        auditReviews.forEach((mainReview) => {
            auditReviews.forEach((childReview) => {
                if (mainReview.id === childReview.parentId) {
                    removeAuditReviewIds.push(childReview.id);
                    if (mainReview?.childReview === undefined) {
                        mainReview.childReview = [];
                    }
                    mainReview.childReview.push(childReview);
                }
            });
        });
        let filteredAuditReviews = auditReviews.filter((review) => !removeAuditReviewIds.includes(review.id));
        let tempItemName: string = "";
        filteredAuditReviews.forEach((mainAudit: any) => {
            if (undefined !== mainAudit.childReview) {
                mainAudit.childReview.forEach((childAudit: any) => {
                    tempItemName = childAudit.title;
                    childAudit.AuditType = props.mainGridSelectedRowData.AuditType;
                    childAudit.isSelectable = true;
                    mainAudit.systemCount = mainAudit.systemCount
                        ? mainAudit.systemCount + childAudit.systemCount
                        : childAudit.systemCount + 0;
                    mainAudit.allocatedCount = mainAudit.allocatedCount
                        ? mainAudit.allocatedCount + childAudit.allocatedCount
                        : childAudit.allocatedCount + 0;
                    mainAudit.physicalCount = mainAudit.physicalCount
                        ? mainAudit.physicalCount + childAudit.physicalCount
                        : childAudit.physicalCount + 0;
                    mainAudit.variance = mainAudit.variance
                        ? mainAudit.variance + childAudit.variance
                        : childAudit.variance + 0;
                });
            }
        });
        setItemName(tempItemName);
        setGridData(filteredAuditReviews);
    };

    const handleShowVariancesOnly = (e: any) => {
        setShowVariancesOnly(e.target.checked);
        setSelectedRow(null);
    };

    const handleExpandedView = () => {
        const tempExpandedView = !expandedView;
        setExpandedView(tempExpandedView);
        expandOrCollapseGrid(tempExpandedView);
    };

    const expandOrCollapseGrid = (expandCollapse: boolean) => {
        var grid = $(`#${selector}`).data("kendoGrid");
        if (undefined !== grid && null !== grid) {
            grid.collapseRow(grid.tbody.find("tr.k-master-row"));
            if (expandCollapse) {
                grid.expandRow(grid.tbody.find("tr.k-master-row"));
            }
            if (undefined !== grid.resize) {
                grid.resize(true);
            }
        }
    };

    const exportGridToCsv = () => {
        setExportLoading(true);
        GetAuditReviewCSV(props.auditCountsUris)
            .then(function (response) {
                var now = new Date();
                var headers = response.headers;
                GlobalService.downloadFileBaseOnContentType(
                    response.data,
                    headers["content-type"],
                    itemName +
                        "_" +
                        +(now.getMonth() + 1) +
                        "-" +
                        +now.getDate() +
                        "-" +
                        +now.getFullYear() +
                        "_" +
                        +now.getHours() +
                        "_" +
                        now.getMinutes() +
                        ".csv",
                    true,
                    "download"
                );
                setExportLoading(false);
            })
            .finally(function () {
                setExportLoading(false);
            });
    };

    const selectRow = (kendoGrid: any) => {
        let selectedRowsValue = kendoGrid.getValues();
        if (selectedRowsValue.length === 0) {
            selectedRowsValue = null;
        }
        setSelectedRow(selectedRowsValue);
    };

    const onSubGridSelection = (objectOfIds: any) => {
        let selectedRowsId = Object.keys(objectOfIds);
        if (selectedRowsId.length === 0) {
            selectedRowsId = null;
        }
        setSubGridSelectedRowValue(selectedRowsId);
        let selectedSubGridData = null;
        if (null !== selectedRowsId && selectedRowsId.length !== 0) {
            selectedSubGridData = _.cloneDeep(
                originalResponseData.filter((ord) => selectedRowsId.includes(ord.id.toString()))
            );
        }
        props.sendSubGridSelectedDataToModalComponent(selectedSubGridData);
    };

    const addLocation = () => {
        setIsAddLocationModalOpen(true);
    };

    const closeReviewAuditJobAddLocationModal = () => {
        setIsAddLocationModalOpen(false);
    };

    return (
        <div className="audit-inventory-review-audit-job-grid">
            <div className="grid-options-wrapper">
                <div className="grid-options-left-part">
                    {props.mainGridSelectedRowData?.AuditType === "Item" && (
                        <div className="item-text">
                            <b>Item: </b>
                            {itemName.toLocaleUpperCase()}
                        </div>
                    )}
                    <div>
                        <WmsCheckBox
                            id={"ShowVariancesOnly"}
                            name={"ShowVariancesOnly"}
                            label={window.translate("Label_Show_Variances_only")}
                            wmsSelector={selector + "ShowVariancesOnlyCheckBox"}
                            checked={showVariancesOnly}
                            onChangeFunc={handleShowVariancesOnly}
                            parentClassName={"ShowVariancesOnlyCheckBox"}
                        />
                    </div>
                </div>
                <div>
                    <WmsToolTip
                        showToolTip={true}
                        tooltipText={
                            expandedView ? "Collapse views of the audit data" : "Expanded views of the audit data"
                        }
                    >
                        <WmsButton
                            id={selector + "ExpandedViewButton"}
                            buttonType={"green"}
                            wmsSelector={selector + "ExpandedViewbutton"}
                            label={""}
                            icon={expandedView ? "fas fa-caret-square-up" : "fas fa-caret-square-down"}
                            onClickFunc={handleExpandedView}
                            parentClassName={"expanded-view-button"}
                        />
                    </WmsToolTip>
                    <WmsButton
                        id={selector + "AddLocationButton"}
                        buttonType={"green"}
                        wmsSelector={selector + "AddLocationButton"}
                        label={""}
                        disabled={subGridSelectedRowValue == null}
                        icon={"fa-solid fa-plus"}
                        onClickFunc={addLocation}
                        parentClassName={"add-location-button"}
                    />
                    <WmsToolTip
                        showToolTip={true}
                        tooltipText={window.translate("Tooltip_Text_Export_Adudit_Results_In_Csv")}
                    >
                        <WmsButton
                            id={selector + "ExportReviewAuditJob"}
                            buttonType={"green"}
                            wmsSelector={selector + "ExportReviewAuditJob"}
                            label={""}
                            icon={"fa-solid fa-file-export"}
                            onClickFunc={exportGridToCsv}
                            loading={exportLoading}
                            parentClassName={"export-review-audit-job"}
                            tooltipText={window.translate("Tooltip_Text_Export_Adudit_Results_In_Csv")}
                        />
                    </WmsToolTip>
                </div>
            </div>
            <hr />
            <div className="audit-inventory-review-audit-job-grid-holder">
                <WmsGrid
                    id={selector}
                    wmsSelector={selector}
                    gridName={selector}
                    gridKeySelection={"id"}
                    inlineSelectAll={true}
                    gridHeight={500}
                    defaultColumns={defaultColumns}
                    columns={defaultColumns}
                    schema={reviewAuditJobGridSchema}
                    staticGridData={gridData}
                    detailTemplate={DetailSubGrid}
                    virtual={false}
                    shawCheckbox={false}
                    isAlignIndexesByProperty={true}
                    onSelection={selectRow}
                    onSubGridSelection={onSubGridSelection}
                    resetButtonLabel={"Options"}
                    showGridSummary={false}
                    showResetButton={false}
                    onDataBound={() => {}}
                    menu={[]}
                />
            </div>
            {isAddLocationModalOpen && (
                <AddLocationModal
                    isOpen={isAddLocationModalOpen}
                    onReviewAuditJobAddLocationModalClose={closeReviewAuditJobAddLocationModal}
                    selector={selector}
                    facilityUri={props.facilityUri}
                    selectedRowData={subGridSelectedRowValue}
                />
            )}
        </div>
    );
};

AuditInventoryReviewAuditJobGrid.propTypes = {
    selector: PropTypes.string.isRequired,
    auditCountsUris: PropTypes.arrayOf(PropTypes.string),
    reviewAuditJobTitle: PropTypes.string.isRequired,
    facilityUri: PropTypes.string.isRequired,
    mainGridSelectedRowData: PropTypes.object,
    sendSubGridSelectedDataToModalComponent: PropTypes.func,
};

AuditInventoryReviewAuditJobGrid.defaultProps = {
    auditCountsUris: [],
    mainGridSelectedRowData: {},
    sendSubGridSelectedDataToModalComponent: () => {},
};

export default AuditInventoryReviewAuditJobGrid;
