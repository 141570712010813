export function cycleCountJobsGridColumns(titleArray) {
    return [
        {
            field: "Name",
            title: titleArray[0].description,
            width: 120,
            wmsid: 1,
            filterable: true,
        },
        {
            field: "CreationDate",
            title: titleArray[1].description,
            template: "#= CreationDate != null ? kendo.toString(kendo.parseDate(CreationDate), 'MMM dd,yyyy') : '' #",
            width: 120,
            wmsid: 2,
            filterable: true,
        },
        {
            field: "AuditType",
            title: titleArray[2].description,
            width: 120,
            wmsid: 3,
            filterable: true,
        },
        {
            field: "Status",
            title: titleArray[3].description,
            template:
                "# if(Status == 0){# Open #}else if(Status == 1){# Counting #}else if(Status == 2){# Counted #}else if(Status == 3){# Closed #} # ",
            width: 120,
            wmsid: 4,
            filterable: true,
        },
        {
            field: "NumberOfLocations",
            title: titleArray[4].description,
            width: 120,
            wmsid: 5,
            filterable: true,
        },
        {
            field: "AssignedTo",
            title: titleArray[5].description,
            template: "#= AssignedTo != null ? AssignedTo.Name : '' #",
            width: 120,
            wmsid: 6,
            filterable: true,
        },
        {
            field: "UseTrackBys",
            title: titleArray[6].description,
            template: "#= UseTrackBys == true ? 'Yes' : 'No' #",
            width: 120,
            wmsid: 7,
            filterable: true,
        },
    ];
}
export function cycleCountJobsGridSchema() {
    return {
        model: {
            id: "EditUri",
            fields: {
                Name: {
                    type: "string",
                },
                CreationDate: {
                    type: "string",
                },
                AuditType: {
                    type: "string",
                },
                NumberOfLocations: {
                    type: "number",
                },
                UseTrackBys: {
                    type: "boolean",
                },
                Status: {
                    type: "string",
                },
                AssignedTo: {
                    type: "any",
                },
            },
        },
    };
}

export function itemsGridColumns() {
    return [
        {
            selectable: true,
            width: 40,
            // eslint-disable-next-line sonarjs/no-duplicate-string
            attributes: { class: "k-text-center" },
            headerAttributes: { class: "k-text-center" },
        },
        {
            field: "Name",
            title: "Locations",
            width: 120,
            wmsid: 1,
            filterable: true,
        },
        {
            field: "Type",
            title: "Location Type",
            width: 120,
            wmsid: 4,
            filterable: true,
        },
        {
            field: "Deactivated",
            title: "Status",
            width: 120,
            wmsid: 6,
            template: "#= Deactivated ? 'Inactive' : 'Active' #",
            filterable: true,
        },
    ];
}
export function locationsGridColumns() {
    return [
        {
            selectable: true,
            width: 40,
            // eslint-disable-next-line sonarjs/no-duplicate-string
            attributes: { class: "k-text-center" },
            headerAttributes: { class: "k-text-center" },
        },
        {
            field: "Name",
            title: "Locations",
            width: 120,
            wmsid: 1,
            filterable: true,
        },
        {
            field: "LastCounted",
            title: "LastCounted",
            width: 120,
            wmsid: 3,
            filterable: true,
        },
        {
            field: "Type",
            title: "Location Type",
            width: 120,
            wmsid: 4,
            filterable: true,
        },
        {
            field: "Deactivated",
            title: "Status",
            width: 120,
            wmsid: 6,
            template: "#= Deactivated ? 'Inactive' : 'Active' #",
            filterable: true,
        },
    ];
}
export function locationsGridSchema() {
    return {
        schema: {
            data: "Data",
            total: "Total",
            model: {
                id: "Id",
                fields: {
                    Name: {
                        type: "string",
                    },
                    LastCounted: {
                        type: "string",
                    },
                    Type: {
                        type: "string",
                    },
                    Deactivated: {
                        type: "boolean",
                    },
                },
            },
        },
    };
}
export const getGridDemoAuditLocationsGridRead = (facilityId, auditType, pastndays, ItemId, isSuggestedLocation) => {
    let dataPayload = null;
    dataPayload = {
        facilityId: facilityId,
        auditType: auditType,
        filter: "Deactivated~eq~false",
        itemid: ItemId,
    };
    if (isSuggestedLocation) {
        dataPayload.pastndays = pastndays;
    }
    return {
        transport: {
            read: {
                url:
                    auditType === "Location"
                        ? "/WebUI/Inventory/EditAudits/AuditLocationsGridRead"
                        : "/WebUI/Inventory/EditAudits/ItemAuditLocationsGridRead",
                type: "POST",
                data: dataPayload,
                dataType: "json",
            },
            destroy: {
                url:
                    auditType === "Location"
                        ? "/WebUI/Inventory/EditAudits/AuditLocationsGridRead"
                        : "/WebUI/Inventory/EditAudits/ItemAuditLocationsGridRead",
                type: "GET",
                dataType: "JSON",
            },
            parameterMap: function (options, operation) {
                if (operation !== "read" && options.models) {
                    return {
                        models: JSON.stringify(options.models),
                    };
                }
            },
        },
    };
};

export function jobLocationsGridSchema() {
    return {
        schema: {
            model: {
                id: "Id",
                fields: {
                    Name: {
                        type: "string",
                    },
                },
            },
        },
    };
}
export function jobLocationsGridColumns(auditType, isSuggestedLocation) {
    return [
        {
            selectable: true,
            width: 20,
            attributes: { class: "k-text-center" },
            headerAttributes: { class: "k-text-center" },
        },
        {
            field: "Name",
            title: "Locations",
            width: 120,
            attributes: { class: auditType === "Item" ? "audit-type-item" : "audit-type-location" },
            headerTemplate: "Locations ",
            template: function (dataItem) {
                return (
                    ((dataItem.IsRequired || isSuggestedLocation) && auditType === "Item"
                        ? isSuggestedLocation
                            ? "<span class='suggested-label-wrapper'><label>S</label></span>"
                            : "<span class='required-label-wrapper'><label>R</label></span>"
                        : "") + dataItem.Name
                );
            },
            wmsid: 1,
            filterable: true,
        },
    ];
}
