import React, { useState, useEffect } from "react";

//component
import WmsButton from "../components/WmsButton/WmsButton.jsx";
import { StringWms } from "../../../assets/ts/StringWms";
import WmsInput from "../components/WmsInput/WmsInput.jsx";
import WmsDropdown from "../components/WmsDropdown/WmsDropdown.jsx";
import WmsCheckBox from "../components/WmsCheckBox/WmsCheckBox.jsx";
import WmsCollapse from "../components/WmsCollapse/WmsCollapse.jsx";
import "./audit-inventory.scss";
import * as GlobalService from "../global_services.js";

import * as auditInventoryServices from "./audit-inventory.services";

interface Props {
    selector: string;
    windowId: string;
    userOptions: any;
    onClickForFilterToggle: () => void;
    onApplyFilter: () => void;
    OnFacilitySelect: (facUri: any) => void;
}
const defaultFilterValue = {
    IndexOptionsJobName: 0,
    IndexOptionsStatus: -1,
    IndexOptionsUserName: 0,
    JobName: null,
    Status: "All",
    UserName: null,
    TrackBys: false,
};

declare var window: any;

const AuditInventorySearch: React.FC<Props> = (props: Props): any => {
    const CustomLabels = GlobalService.getCustomLabels();
    const optionContains = StringWms.convertSringToOption(window.translate("Options_SearchBy_StringOptions"));
    const [booleanOption, setBooleanOption] = useState([]);
    const [filter, setFilter] = useState(defaultFilterValue);
    const [userOption, setUserOption] = useState([]);
    const facilityOption = GlobalService.getFacilityList(false);
    const [selectedFacility, setSelectedFacilty] = useState(0);

    useEffect(() => {
        initialsOptions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        setUserOption(props.userOptions);
    }, [props.userOptions]);
    const initialsOptions = () => {
        let tmpBooleanOptions = StringWms.convertSringToOption(window.translate("Options_CycleCount_StatusOption"));
        tmpBooleanOptions.unshift({
            "value": -1,
            "description": "All",
        });
        setBooleanOption(tmpBooleanOptions);
        auditInventoryServices.auditSearchInfo(filter).then((response) => {});
    };
    const clearFilters = () => {
        let resetFilterObj = defaultFilterValue;
        kendo.ui.progress($("#" + props.selector + "Grid"), true);
        auditInventoryServices
            .auditSearchInfo(resetFilterObj)
            .then((response) => {
                props.onApplyFilter();
                setFilter(resetFilterObj);
            })
            .finally(() => {
                // kendo.ui.progress($("#" + props.selector + "Grid"), false);
            });
    };
    const onFilterApply = () => {
        if (filter.IndexOptionsUserName > 0) {
            // eslint-disable-next-line array-callback-return
            userOption.filter((item) => {
                if (item.Id === filter.IndexOptionsUserName) {
                    filter.UserName = item["Name"];
                }
            });
        } else {
            filter.IndexOptionsUserName = -1;
            filter.UserName = null;
        }
        kendo.ui.progress($("#" + props.selector + "Grid"), true);
        auditInventoryServices
            .auditSearchInfo(filter)
            .then((response) => {
                props.onApplyFilter();
            })
            .finally(() => {
                // kendo.ui.progress($("#" + props.selector + "Grid"), false);
            });
    };

    const OnFacilityChange = (e) => {
        let facilityId = e.target.value;
        console.log("OnFacilityChange facilityId", facilityId);
        console.log("OnFacilityChange facilityOption", facilityOption);
        // eslint-disable-next-line eqeqeq
        var facObj = facilityOption.find((x) => x.id == facilityId);
        setSelectedFacilty(facilityId);
        props.OnFacilitySelect(facObj);
    };
    const onSearchFilterChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === "IndexOptionsUserName") {
            value = parseInt(value);
        }
        if (name === "TrackBys") {
            value = e.target.checked;
        }
        setFilter((previousState) => ({
            ...previousState,
            [name]: value,
        }));
    };

    return (
        <div className={`audit-inventory-search-holder`}>
            <div className="inventory-filter">
                <div className="filter-wrapper">
                    <div className="btn-section">
                        <WmsButton
                            label={window.translate("Label_Clear_Filters")}
                            buttonType="yellow"
                            wmsSelector={props.selector + "ClearSearchButton"}
                            onClickFunc={clearFilters}
                            icon="fa-minus"
                        />
                        <WmsButton
                            label={window.translate("Label_Apply")}
                            wmsSelector={props.selector + "GridRefreshButton"}
                            onClickFunc={onFilterApply}
                            disabled={!selectedFacility}
                            icon="fa fa-search"
                        />
                    </div>

                    <div className={"warehouse-form-boxes"}>
                        <div className="warehouse-are-content" id="inventory-filter-section-sortable">
                            <WmsCollapse
                                id={"inventory_search_collapse1"}
                                showDragIcon={true}
                                headerLabel={window.translate("Label_Filter_Options")}
                                showCollapseText={false}
                            >
                                <WmsDropdown
                                    id="indexOptionsWarehouse"
                                    name="indexOptionsWarehouse"
                                    label={CustomLabels.Label_Warehouse}
                                    wmsSelector={props.selector + "warehouseDropdown"}
                                    value={selectedFacility}
                                    onChangeFunc={OnFacilityChange}
                                    options={facilityOption}
                                    valueField="id"
                                    textField="name"
                                    parentClassName="form_group"
                                    required={true}
                                    blankFirstOption={true}
                                    blankFirstOptionText={""}
                                />
                                <div className="wms-input-group-wrapper">
                                    <WmsDropdown
                                        id="IndexOptionsJobName"
                                        name="IndexOptionsJobName"
                                        windowId={props.windowId}
                                        label={window.translate("Label_Cycle_count_Job_Name")}
                                        wmsSelector={props.selector + "IndexOptionsJobName"}
                                        value={filter.IndexOptionsJobName}
                                        onChangeFunc={onSearchFilterChange}
                                        options={optionContains}
                                        valueField="value"
                                        textField="description"
                                        parentClassName="select_box"
                                    />
                                    <WmsInput
                                        inputName="JobName"
                                        wmsSelector={props.selector + "SearchJobName"}
                                        value={filter.JobName}
                                        onChangeFunc={onSearchFilterChange}
                                        label={window.translate("Label_Cycle_count_Job_Name")}
                                        parentClassName="input_box"
                                    />
                                </div>
                                <WmsDropdown
                                    id="IndexOptionsStatus"
                                    name="IndexOptionsStatus"
                                    label={"status"}
                                    wmsSelector={props.selector + "IndexOptionsStatus"}
                                    value={filter.IndexOptionsStatus}
                                    onChangeFunc={onSearchFilterChange}
                                    options={booleanOption}
                                    valueField="value"
                                    textField="description"
                                />
                                <WmsDropdown
                                    id="IndexOptionsUserName"
                                    name="IndexOptionsUserName"
                                    label={"Assigned To"}
                                    wmsSelector={props.selector + "SearchUserName"}
                                    value={filter.IndexOptionsUserName}
                                    onChangeFunc={onSearchFilterChange}
                                    options={userOption}
                                    valueField="Id"
                                    textField="Name"
                                    blankFirstOption={true}
                                    blankFirstOptionText={window.translate("Label_All")}
                                />
                                <WmsCheckBox
                                    id={"TrackBys"}
                                    name={"TrackBys"}
                                    wmsSelector={props.selector + "SearchTrackBys"}
                                    onChangeFunc={onSearchFilterChange}
                                    label={window.translate("Label_Cycle_count_Job_includes")}
                                    checked={filter.TrackBys}
                                />
                            </WmsCollapse>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
AuditInventorySearch.propTypes = {};

AuditInventorySearch.defaultProps = {};

export default React.memo(AuditInventorySearch);
