import React from 'react';

//component
import WmsModel from '../../../../components/WmsModel/WmsModel.jsx';
import WmsContainer from '../../../../components/WmsContainer/WmsContainer.jsx';
import WmsButton from '../../../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from '../../../../components/WmsDropdown/WmsDropdown.jsx';
import * as findOrdersService from '../../findOrders.services.jsx';
import * as GlobalService from '../../../../global_services';
import style from './consolidate-packages.scss';
import WmsCollapse from '../../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsInput from '../../../../components/WmsInput/WmsInput.jsx';
import WmsComboBox from '../../../../components/WmsComboBox/WmsComboBox.jsx';

export class ConsolidatePackages extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;

        this.pkgCustomOption = {
            "PackageDefId": 0,
            "PackageId": 0,
            "Name": this.translate('Label_Custom_Dimensions'),
            "Description": this.translate('Label_Custom_Dimensions'),
            "Barcode": "",
            "IsDefault": false,
            "LengthImperial": 0,
            "WidthImperial": 0,
            "HeightImperial": 0,
            "WeightImperial": 0,
            "LengthMetric": 0,
            "WidthMetric": 0,
            "HeightMetric": 0,
            "WeightMetric": 0,
            "AssignedCustomers": []
        };

        this.state = {
            selector: "FindOrderConsolidatePackage",
            packageDefsOptions: [],
            packageDefsSaveGif: false,
            selectedPackeId: '',
            currentMeasurementStandardIsImperial: GlobalService.getMeasurementStandardFlag(this.props.facilityId),
            customPackageObj: {},
            isCustomDimension: false,
        }
    }

    componentDidMount() {
        kendo.ui.progress($('#ConsolidatePackages'), true);
        findOrdersService.getPackageDefs().then((response) => {
            if (response && response.PackageDefs.length) {
                response.PackageDefs.push(this.pkgCustomOption);
                let packageOptions = response.PackageDefs;
                packageOptions = packageOptions.slice().sort((a,b) => a.Name.localeCompare(b.Name, undefined, {numeric: true}));
                this.setState({
                    packageDefsOptions: packageOptions,
                    // selectedPackeId: response.PackageDefs[0].PackageDefId
                });
            }
        }).finally(() => {
            kendo.ui.progress($('#ConsolidatePackages'), false);
            setTimeout(() => {
                $("#packageOption").data("kendoComboBox").focus();
            }, 500);
        });
    }

    handleChangePackages = (e) => {
        let selectedPackedId = e.target.value;
        let packageDefId = "";
        var packAndShipCombobox = $("#packageOption").data("kendoComboBox");
        let tmpIsCustomDimension = false;
        if (selectedPackedId != '' && (selectedPackedId == 0 || selectedPackedId == 'Custom Dimensions')) {
            tmpIsCustomDimension = true
        }

        if (selectedPackedId && e.target.selectedIndex == -1) {
            packAndShipCombobox.select(function (dataItem) {
                return (dataItem.Name == selectedPackedId || dataItem.Barcode == selectedPackedId);
            });
            packageDefId = packAndShipCombobox.value();
        } else {
            packageDefId = selectedPackedId;
        }

        this.setState({
            selectedPackeId: packageDefId,
            isCustomDimension: tmpIsCustomDimension,
            customPackageObj: {}
        });

        if (packageDefId) {
            if (e.target.selectedIndex == -1 && selectedPackedId != 'Custom Dimensions') {
                this.packageDefsSave();
            }
        } else if (e.target.value) {
            if (packAndShipCombobox) {
                packAndShipCombobox.value("");
            }
            GlobalService.notification("error", "Scanned package not found");
        }
    }

    packageDefsSave = () => {
        this.setState({ packageDefsSaveGif: true });
        if (this.state.isCustomDimension) {
            let customDimensionObj = {};
            customDimensionObj.OrderIdentifiers = this.props.SharedSelectedOrderIds.map((element, index) => {
                return { Id: element };
            });
            customDimensionObj.PackageByCustomDims = this.state.customPackageObj;
            findOrdersService.customDimensionConsolidatePackages(customDimensionObj).then((response) => {
                this.setState({ packageDefsSaveGif: false });
                GlobalService.notification('success', this.translate('Label_Consolidate_Packages_Success'));
                this.props.onResetGridSelection();
                this.props.onCloseConsolidatePackages();
            }).finally(() => {
                this.setState({ packageDefsSaveGif: false });
            });

        } else {
            findOrdersService.consolidatePackages(this.props.SharedSelectedOrderIds, this.state.selectedPackeId).then((response) => {
                this.setState({ packageDefsSaveGif: false });
                GlobalService.notification('success', this.translate('Label_Consolidate_Packages_Success'));
                this.props.onResetGridSelection();
                this.props.onCloseConsolidatePackages();
            }).finally(() => {
                this.setState({ packageDefsSaveGif: false });
            });
        }
    }

    handleCustomPackageChange = (e) => {
        let tmpCustomPkgObj = Object.assign({}, this.state.customPackageObj, { [e.target.name]: e.target.value });
        this.setState({
            customPackageObj: tmpCustomPkgObj
        });
    }

    render() {
        return (
            <WmsModel
                id={"ConsolidatePackages"}
                class="consolidate-packages-wrapper"
                title={this.translate('Label_Packages')}
                width={500}
                onCloseFunc={this.props.onCloseConsolidatePackages}
                height={230}
                isOpen={this.props.sharedIsOpenConsolidatePackages}
                customClass='wms-model-new-ui-wrapper' >
                <div className="model-content-wrapper">
                    <WmsCollapse
                        id={'dimensionAndWeight'}
                        headerLabel={this.translate('Label_Service_Charge_Trigger')}
                        showCollapseText={false}
                        showCollapseIcon={false} >
                        <WmsComboBox
                            id="packageOption"
                            name="selectedPackeId"
                            label={this.translate('Label_Package_Type')}
                            wmsSelector={this.state.selector + "packageOption"}
                            value={this.state.selectedPackeId}
                            onChangeFunc={this.handleChangePackages}
                            options={this.state.packageDefsOptions}
                            placeholder={this.translate('Label_Select_Package_Option')}
                            valueField="PackageDefId"
                            textField="Name"
                            parentClassName="pack-and-ship-add-package"
                        />

                        {this.state.isCustomDimension && (
                            <div className="dimension-wrapper">
                                <div className="dimension-title ">
                                    <label>{this.translate('Label_Package_Dimensions_And_Weight')}</label>
                                </div>
                                <div className="dimension-inputs">
                                    <WmsInput
                                        id="Length"
                                        inputName={'Length'}
                                        label={this.translate('Label_Length') + ' (' + (this.state.currentMeasurementStandardIsImperial ? this.translate('Label_Inch_Abbrev_Wihtout_dot') : this.translate('Label_Centimeter_Abbrev_Wihtout_dot')) + ')'}
                                        wmsSelector={this.state.selector + "Length"}
                                        onChangeFunc={this.handleCustomPackageChange}
                                        value={this.state.customPackageObj.Length}
                                        extraWrapperClass="length-wrapper"
                                        required={true}
                                        isAutoFocus={true}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/} />

                                    <WmsInput
                                        id="Width"
                                        inputName={'Width'}
                                        label={this.translate('Label_Width') + ' (' + (this.state.currentMeasurementStandardIsImperial ? this.translate('Label_Inch_Abbrev_Wihtout_dot') : this.translate('Label_Centimeter_Abbrev_Wihtout_dot')) + ')'}
                                        wmsSelector={this.state.selector + "Width"}
                                        onChangeFunc={this.handleCustomPackageChange}
                                        value={this.state.customPackageObj.Width}
                                        extraWrapperClass="width-wrapper"
                                        required={true}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    />

                                    <WmsInput
                                        id="Height"
                                        inputName={'Height'}
                                        label={this.translate('Label_Height') + ' (' + (this.state.currentMeasurementStandardIsImperial ? this.translate('Label_Inch_Abbrev_Wihtout_dot') : this.translate('Label_Centimeter_Abbrev_Wihtout_dot')) + ')'}
                                        wmsSelector={this.state.Selector + "Height"}
                                        onChangeFunc={this.handleCustomPackageChange}
                                        value={this.state.customPackageObj.Height}
                                        extraWrapperClass="height-wrapper"
                                        required={true}
                                        regularExp={/^[0-9]*(?:\.[0-9]{0,4})?$/}
                                    />
                                </div>
                            </div>
                        )}
                    </WmsCollapse>
                </div>
                <div className="footer-btn-holder">
                    <WmsButton
                        id={this.state.selector + "cancel"}
                        name={this.state.selector + "cancel"}
                        label={this.translate("Label_Cancel")}
                        wmsSelector={this.state.selector + "cancel"}
                        onClickFunc={this.props.onCloseConsolidatePackages}
                        icon="fa-ban"
                        buttonType="yellow"
                    />
                    <WmsButton
                        label={this.translate("Label_Save")}
                        wmsSelector={this.state.selector + "save"}
                        onClickFunc={this.packageDefsSave}
                        icon="fa-floppy-o"
                        loading={this.state.packageDefsSaveGif}
                        disabled={
                            !this.state.selectedPackeId ||
                            this.state.isCustomDimension &&
                            (
                                !this.state.customPackageObj.Length ||
                                !this.state.customPackageObj.Width ||
                                !this.state.customPackageObj.Height
                            )
                        }
                    />
                </div>
            </WmsModel>
        )
    }
}