
import React from 'react';
import { StringWms } from '../../../assets/ts/StringWms.ts';
import { KendoWms } from '../../../assets/ts/KendoWms.ts';
import { ObjectWms } from '../../../assets/ts/ObjectWms.ts';
import { KendoGridWms } from '../../../assets/ts/KendoGridWms.ts';

import style from './cyclecount.scss';
import WmsButton from './../components/WmsButton/WmsButton.jsx';
import WmsContainer from './../components/WmsContainer/WmsContainer.jsx';
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import WmsInput from './../components/WmsInput/WmsInput.jsx';
import WmsDateTimePicker from './../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import WmsCheckBox from './../components/WmsCheckBox/WmsCheckBox.jsx';

import * as cyclecountService from './cyclecount.services.jsx';
import * as GlobalService from './../global_services';
import * as commanFunction from './cyclecountgrid-columns.jsx';
import * as emitter from '../emitter';
import { _ } from "./../global_variable_service.jsx";
var $rootScope, kendoGridJobLocation, kendoGridLocation, backup, that, _ismounted;

export class CyclecountForm extends React.Component {

    constructor(props) {
        super(props);

        $rootScope = window;

        this.state = {
            createJobGif: false,
            Rights: GlobalService.GetRights(),
            optionConatins: [],
            optionLocationType: [],
            excludeLocations: false,
            jobLocationGridData: '',
            tempOptionsLocationJobGridColumns: '',
            sharedCycleCount: this.props.sharedCycleCount,
            sortArrayGrid: [],
            initialjobLocationGrid: 0,
            addLocationDisabled: true,
            removeLocationDisabled: true,
            warehouseSelection: this.props.sharedCycleCount.warehouseSelection,
            locationList: [],
            jobName: '',
            jobPriotity: 8,
            jobPriorityOption: [
                { value: 1, text: 1 },
                { value: 2, text: 2 },
                { value: 3, text: 3 },
                { value: 4, text: 4 },
                { value: 5, text: 5 },
                { value: 6, text: 6 },
                { value: 7, text: 7 },
                { value: 8, text: 8 },
                { value: 9, text: 9 },
                { value: 10, text: 10 },
            ],
            filter: {
                IndexOptionsLocation: 0,
                LocationType: 0,
                Location: null,
                LastCountedDate: null,
                ShowLastCountedDate: true,
                ShowLocationType: true,
                ShowLocation: true,
            },
            inActiveLocation: false,
            createEditFormShow: false,
            assignUser: '',
            collectTrackBy: false,
            selector: 'AuditInventory'
        };

        this.translate = window.translate;
        this.handleChange = this.handleChange.bind(this);
        this.filterHandleChange = this.filterHandleChange.bind(this);
        this.searchJobLocation = this.searchJobLocation.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.createJob = this.createJob.bind(this);
        kendoGridJobLocation = new KendoGridWms("cycleCountLocationGrid", $rootScope.ChildBulletin.Data.Window, "Id", '/WebUI/Inventory/EditAudits/AuditLocationsGridSelections');
        kendoGridLocation = new KendoGridWms("jobLocationGrid", $rootScope.ChildBulletin.Data.Window, "Id", '');
        this.LocationJob_GridHeaders = StringWms.convertSringToOption(this.translate('Cyclecount_Job_Location_Grid_Header'));
        that = this;
    }

    componentDidMount() {
        _ismounted = true;
        var that = this;
        var containsOption = StringWms.convertSringToOption(this.translate('Orders_FindOrders_SearchFilters'));
        let mytemp = [];
        if (typeof this.props.sharedCycleCount.locationTypes != 'undefined') {
            mytemp = this.props.sharedCycleCount.locationTypes;
        }

        this.setState({
            optionConatins: containsOption,
            optionLocationType: mytemp,
        });
        // restring multiple sorting direct click on header
        $("#cycleCountLocationGrid").on("mouseup", "th>a.k-link", function (e) {
            if (!(e.ctrlKey || e.metaKey)) {
                that.state.sortArrayGrid = [];
            }
        });
        that.setJobLocationInitial(this.props.sharedCycleCount);
        that.initLocationGrid(this.props.sharedCycleCount.warehouseSelection.id);
        that.initJobLocationGrid(this.props.sharedCycleCount);
        this.contextMenuSet();
        this.groupContextMenuSet();

        var grid = $('#jobLocationGrid').data('kendoGrid');
        grid.table.kendoSortable({
            filter: ">tbody >tr",
            hint: function (element) { //customize the hint
                $('#jobLocationGrid tbody tr').removeClass("k-state-selected");
                that.setState({
                    removeLocationDisabled: true
                });
                return element.clone().addClass("hint");
            },
            cursor: "move",
            placeholder: function (element) {
                return element.clone().addClass("placeholder").text("drop here");
            },
            cursorOffset: {
                top: 0,
                left: -100
            },
            change: function (e) {
                var newIndex = e.newIndex;
                var dataItem = grid.dataSource.getByUid(e.item.data("uid"));
                if (typeof dataItem != 'undefined') {
                    grid.dataSource.remove(dataItem);
                    grid.dataSource.insert(newIndex, dataItem);
                }


                that.setState({
                    removeLocationDisabled: true
                });
                that.setState({
                    locationList: grid.dataSource._data
                });
            }
        });

        cyclecountService.locationGridSearchInfoGet().then(function (response) {
            if (typeof response != "undefined" && response.status == 200) {
                if (response.data.HasData == true) {
                    that.setState({
                        filter: Object.assign(
                            {},
                            that.state.filter,
                            response.data.Data
                        ),
                    });
                }
            }
        }, function (response) {
            console.log("Grid w sort ", response);
        });

    }
    componentWillUnmount() {
        _ismounted = false;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {

        if (
            typeof that.state.sharedCycleCount.warehouseSelection != 'undefined' &&
            typeof nextProps.sharedCycleCount.warehouseSelection != 'undefined' && nextProps.sharedCycleCount.warehouseSelection.id != that.state.sharedCycleCount.warehouseSelection.id
        ) {
            that.setState({
                sharedCycleCount: Object.assign(
                    {},
                    that.state.sharedCycleCount,
                    { warehouseSelection: nextProps.warehouseSelection }
                ),
            });
            $('#cycleCountLocationGrid').data('kendoGrid').dataSource.transport.options.read.data.facilityId = nextProps.sharedCycleCount.warehouseSelection.id;
            $('#cycleCountLocationGrid').data('kendoGrid').refresh();
            $('#cycleCountLocationGrid').data('kendoGrid').dataSource.read();
            kendoGridJobLocation.clearSelections(function () {
                kendoGridJobLocation.repaintSelections();
            });
        }
        if (nextProps.sharedCycleCount) {
            this.setState({
                sharedCycleCount: Object.assign(
                    {},
                    that.state.sharedCycleCount,
                    nextProps.sharedCycleCount
                ),
            });


            let temp = nextProps.sharedCycleCount.locationTypes;

            this.setState({
                optionLocationType: temp,
            });
            that.setJobLocationInitial(nextProps.sharedCycleCount);
            $('#jobLocationGrid').data('kendoGrid').dataSource.data(nextProps.sharedCycleCount.flowType == 'edit' ? nextProps.sharedCycleCount.EditData['Locations'] : []);
        }
    }
    addLocation() {
        var tempkeys = kendoGridJobLocation.getKeys();
        var locationList = that.state.locationList;
        for (var i = 0; i < tempkeys.length; i++) {
            var itemExist = false;
            locationList.filter(location => {
                if (tempkeys[i] == location['Id']) { itemExist = true; }
            });
            if (itemExist == false || locationList.length == 0) {
                var dataItem = kendoGridJobLocation.getItem(tempkeys[i]);
                var totalLocations = that.state.locationList.length;
                locationList.push({
                    Id: dataItem['Id'],
                    Name: dataItem['Name'],
                });
            }
        }
        setTimeout(function () {
            that.setState({
                locationList: locationList,
                addLocationDisabled: true,
                removeLocationDisabled: true
            });
            $('#jobLocationGrid').data('kendoGrid').dataSource.data(that.state.locationList);
            that.addLocationAllocatedClass();
            kendoGridJobLocation.clearSelections(function () {
                kendoGridJobLocation.repaintSelections();
            });
        }, 0);

    }

    removeElement(Id) {
        var locationList = that.state.locationList;
        var selectedrow = $("#jobLocationGrid").find("tbody tr.k-state-selected");
        for (var i = 0; i < selectedrow.length; i++) {
            var goods = $('#jobLocationGrid').data("kendoGrid").dataItem(selectedrow[i]);
            var exists = ObjectWms.FindIndexByProperty(locationList, "Id", goods.Id);
            if (exists != -1) {
                var removedLocation = locationList[exists];
                locationList.splice(exists, 1);
            }
        }
        setTimeout(function () {
            that.setState({
                locationList: locationList
            });
            that.addLocationAllocatedClass();
            kendoGridJobLocation.clearSelections(function () {
                kendoGridJobLocation.repaintSelections();
            });
            that.setState({
                addLocationDisabled: true,
                removeLocationDisabled: true
            });
        }, 10);

        $('#jobLocationGrid').data('kendoGrid').dataSource.data(that.state.locationList);

    }

    addLocationAllocatedClass() {
        var grid = $('#cycleCountLocationGrid').data('kendoGrid');
        if (typeof grid != 'undefined') {
            var gridData = grid.dataSource.view();
            for (var i = 0; i < gridData.length; i++) {
                var locationExist = that.state.locationList.filter((location) => {
                    return gridData[i].Id == location.Id;
                });
                if (locationExist.length != 0) {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").addClass("wms-location-allocated");
                } else {
                    grid.table.find("tr[data-uid='" + gridData[i].uid + "']").removeClass("wms-location-allocated");
                }
            }
        }
    }

    setJobLocationInitial(data) {
        if (data.flowType == 'edit') {
            setTimeout(function () {
                that.setState({
                    locationList: data.EditData['Locations'],
                    jobName: data.EditData['Name'],
                    jobPriotity: data.EditData['Priority'],
                    collectTrackBy: data.EditData['UseTrackBys'],
                    assignUser: data.EditData['AssignedTo'] != null ? data.EditData['AssignedTo'].Id : '',
                });
                that.addLocationAllocatedClass();
            }, 0);

        } else {
            setTimeout(function () {
                that.setState({
                    locationList: [],
                    jobName: '',
                    jobPriotity: 8,
                    assignUser: '',
                    collectTrackBy: true
                });
                that.addLocationAllocatedClass();
            }, 0);
        }
    }

    initJobLocationGrid(data) {
        var locationJobGrid = {
            dataSource: {
                data: data.flowType == 'edit' ? data.EditData['Locations'] : [],
                schema: {
                    model: {
                        id: "Id",
                        fields: { Name: { type: "string" } }
                    }
                },
            },
            change: function (arg) {
                var selected = $.map(this.select(), function (item) {
                    return $(item).text();
                });
                that.setState({
                    removeLocationDisabled: selected.length == 0 ? true : false
                });
            },
            // selectable: "multiple, row",
            scrollable: {
                virtual: true,
                height: 280
            },
        };
        var LocationJobGridColumns = {
            columns: [{
                field: "Name",
                title: 'job locations(Drag To Order)',
                width: 120,
                wmsid: 1,
                filterable: false
            }, {
                field: "Id",
                title: "Id",
                width: 120,
                wmsid: 2,
                hidden: true,
                filterable: true,
            }]
        };
        var finalOptionsObj = kendoGridJobLocation.getGridOptions(LocationJobGridColumns, locationJobGrid, '[{}]');
        $("#jobLocationGrid").kendoGrid(finalOptionsObj);
    }

    createJob() {
        this.setState({
            createJobGif: true,
        });

        var today = new Date();
        var submitData;
        if (this.state.sharedCycleCount.flowType == 'create') {
            var locations = [];
            this.state.locationList.filter(function (item) {
                locations.push({
                    Id: item.Id
                });
            });

            submitData = {
                "facilityUri": this.state.sharedCycleCount.warehouseSelection.uri,
                "auditView": {
                    "Name": this.state.jobName,
                    "Notes": "audit 1 notes",
                    "Priority": this.state.jobPriotity,
                    "UseTrackBys": this.state.collectTrackBy,
                    "Locations": locations
                }
            };
            if (this.state.assignUser != "") {
                submitData.auditView.AssignedTo = {
                    "Id": this.state.assignUser
                };
            }
        } else {
            var assignUserObj;
            this.state.sharedCycleCount.userOption.filter(function (item) {
                if (item.Id == that.state.assignUser) {
                    assignUserObj = item;
                }
            });
            submitData = {
                "Name": this.state.jobName,
                "Notes": "audit 1 notes",
                "AssignedTo": assignUserObj,
                "Priority": this.state.jobPriotity,
                "UseTrackBys": this.state.collectTrackBy,
                "Locations": that.state.locationList,
                "EditUri": this.state.sharedCycleCount.EditData.EditUri,
                "Status": 0,
                "NumberOfLocations": this.state.locationList.length
            };
        }

        cyclecountService.createJob(submitData, this.state.sharedCycleCount.flowType).then(function (response) {
            that.setState({
                createJobGif: false,
            });
            if (typeof response != "undefined") {

                var message = that.state.sharedCycleCount.flowType == 'create' ? that.translate('Label_Cycle_Count_Job_Create_Success') : that.translate('Label_Cycle_Count_Job_Update_Success');
                GlobalService.notification('success', message);

                that.setState({
                    locationList: [],
                    jobName: '',
                    jobPriotity: 8,
                    collectTrackBy: true,
                    assignUser: '',
                });
                that.clearFilters();

                that.setState({
                    sharedCycleCount: Object.assign(
                        {},
                        that.state.sharedCycleCount,
                        { flowType: 'create' }
                    ),
                });
                emitter.eventBus.sendEvent('reloadJobGrid');
            }
        }, function (response) {
            // GlobalService.notification('error', response);
            that.setState({ createJobGif: false });
        });
    }

    contextMenuSet() {
        var that = this;
        var menu = $("#cycleCountLocationGridMenu").kendoContextMenu({
            orientation: 'vertical',
            target: '#cycleCountLocationGrid',
            filter: "tbody tr:not(.k-grid-edit-row):not(.wms-selection-group)",
            animation: {
                open: {
                    effects: "fadeIn"
                },
                duration: 500
            },
            select: function (e) {
                var row = e.target;
                var grid = $('#cycleCountLocationGrid').data('kendoGrid');
                var text = $(e.item).children(".k-link").text();
                var dataItem = grid.dataItem($(e.target).closest('tr'));
                var custom = $(e.item).children(".k-link").parent().attr("data-wmsid");
                backup = _.cloneDeep(dataItem);
                if (custom == "addLocation") {
                    that.addLocation();
                }
            },
            open: function (e) {
                var row = e.target;
                var grid = $('#cycleCountLocationGrid').data('kendoGrid');
                var dataItem = grid.dataItem($(e.target).closest('tr'));
                var page = grid.dataSource.page();
                var length = grid.dataSource.view().length;
                var col = grid.cellIndex($(e.target).closest('td'));
                var row2 = $(e.target).closest('tr').index();
                kendoGridJobLocation.onRightClick(e, page, length, row2, col, dataItem, grid.dataSource.data(), 'Id', function () {
                    var currentSelections = kendoGridJobLocation.getNumberOfSelections();
                    var locationList = that.state.locationList;
                    var locationExist = locationList.filter((location) => {
                        return dataItem['Id'] == location.Id;
                    });
                    if (locationExist.length > 0) {
                        $("li[data-wmsid = 'addLocation']").addClass('wms-disabled-look');
                    } else {
                        $("li[data-wmsid = 'addLocation']").removeClass('wms-disabled-look');
                    }
                });
                // that.contextMenuSelection();
            }
        });



        $("#jobLocationGridMenu").kendoContextMenu({
            orientation: 'vertical',
            target: '#jobLocationGrid',
            filter: "tbody tr:not(.k-grid-edit-row):not(.wms-selection-group)",
            animation: {
                open: {
                    effects: "fadeIn"
                },
                duration: 500
            },
            select: function (e) {
                var row = e.target;
                var grid = $('#jobLocationGrid').data('kendoGrid');
                var text = $(e.item).children(".k-link").text();
                var dataItem = grid.dataItem($(e.target).closest('tr'));
                var custom = $(e.item).children(".k-link").parent().attr("data-wmsid");
                backup = _.cloneDeep(dataItem);
                if (custom == "removeLocation") {
                    that.removeElement();
                }

            },
            open: function (e) {
                var row = e.target;
                var grid = $('#jobLocationGrid').data('kendoGrid');
                var dataItem = grid.dataItem($(e.target).closest('tr'));
                var page = grid.dataSource.page();
                var length = grid.dataSource.view().length;
                var col = grid.cellIndex($(e.target).closest('td'));
                var row2 = $(e.target).closest('tr').index();

                // selection not remove when multiple recored select and open context menu
                var selectedrow = $("#jobLocationGrid").find("tbody tr.k-state-selected");
                if (selectedrow.length == 0 || selectedrow.length == 1) {
                    kendoGridLocation.onRightClick(e, page, length, row2, col, dataItem, grid.dataSource.data(), 'Id', function () {
                    });
                }

            }
        });
    }

    groupContextMenuSet() {
        var that = this;
        var menu = $("#cycleCountLocationGridGroupMenu").kendoContextMenu({
            orientation: 'vertical',
            target: '#cycleCountLocationGrid',
            filter: "tbody tr.wms-selection-group",
            animation: {
                open: {
                    effects: 'fadein'
                },
                duration: 2500
            },
            select: function (e) {
                var custom = $(e.item).children(".k-link").parent().attr("data-wmsid");
                if (custom == "addLocation") {
                    that.addLocation();
                }
            },
            open: function (e) {
                var selectedLocation = kendoGridJobLocation.getSelections();
                var locationList = that.state.locationList;
                $("li[data-wmsid = 'addLocation']").addClass('wms-disabled-look');
                for (var i = 0; i < selectedLocation.length; i++) {
                    var locationExist = locationList.filter((location) => {
                        return selectedLocation[i] == location.Id;
                    });
                    if (locationExist.length == 0) {
                        $("li[data-wmsid = 'addLocation']").removeClass('wms-disabled-look');
                    }
                }
            }
        });
    }

    searchJobLocation() {
        cyclecountService.locationGridSearchInfoSend(this.state.filter).then(function (response) {
            if (response.status == 204 || response.status == 200) {
                $('#cycleCountLocationGrid').data('kendoGrid').dataSource.read();
            }
        }, function (response) {
            console.log("Grid w sort ", response);
        });
        //     var dataArr = new Array();
        //     if (this.state.filter.Location) {
        //         dataArr.push({
        //             field: "Name",
        //             operator: KendoWms.getFilterOperatorByIndex(this.state.IndexOptionsLocation),
        //             value: this.state.filter.Location
        //         });
        //     }
        //     if (this.state.filter.LocationType) {
        //         dataArr.push({
        //             field: "LocationType",
        //             operator: "IsEqualTo",
        //             value: this.state.filter.LocationType
        //         });
        //     }
        //     if (this.state.filter.LastCountedDate) {
        //         dataArr.push({
        //             field: "LastCounted",
        //             operator: "IsEqualTo",
        //             value: this.state.filter.LastCountedDate
        //         });
        //     }
        //     var dataSource = $("#cycleCountLocationGrid").data("kendoGrid").dataSource;
        //     dataSource.filter(dataArr);
    }

    clearFilters() {
        cyclecountService.locationGridSearchInfoClear().then(function (response) {
            if (response.status == 204 && _ismounted == true) {
                that.setState({
                    filter: Object.assign(
                        {},
                        that.state.filter,
                        {
                            IndexOptionsLocation: 0,
                            LocationType: 0,
                            Location: null,
                            LastCountedDate: null,
                            ShowLastCountedDate: true,
                            ShowLocationType: true,
                            ShowLocation: true,
                        }
                    ),
                });
                that.filterHandleActiveLocation({ target: { checked: false } });
                $('#cycleCountLocationGrid').data('kendoGrid').dataSource.read();
                // var dataSource = $("#cycleCountLocationGrid").data("kendoGrid").dataSource;
                // dataSource.filter([]);
                // $("form.k-filter-menu button[type='reset']").trigger("click");
            }
        }, function (response) {
            console.log("Grid w sort ", response);
        });
    }

    toggleCheckbox(event) {
        that.setState({ [event.target.name]: !that.state[event.target.name] });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    filterHandleChange(event) {
        this.setState({
            filter: Object.assign(
                {},
                this.state.filter,
                { [event.target.name]: event.target.value }
            ),
        });
    }
    filterHandleActiveLocation = (e) => {
        this.setState({ inActiveLocation: e.target.checked });
        var grid = $('#cycleCountLocationGrid').data('kendoGrid');
        var dataSource = grid.dataSource;
        var filterArray = [];
        if (dataSource.filter()) {
            filterArray = dataSource.filter().filters;
        }
        for (let i = 0; i < filterArray.length; i++) {
            const element = filterArray[i];
            if (element.field == 'Deactivated') {
                filterArray.splice(i, 1);
            }
        }
        if (e.target.checked == false) {
            filterArray.push({
                field: "Deactivated",
                operator: "eq",
                value: false
            });
        }
        dataSource.filter(filterArray);
    }

    initLocationGrid(facilityId) {
        var that = this;
        var tempOptionsLocationJobGrid = {
            dataSource: {
                pageSize: 25,
                serverPaging: true,
                serverSorting: true,
                serverFiltering: true,
                type: "aspnetmvc-ajax",
                transport: {
                    read: {
                        url: "/WebUI/Inventory/EditAudits/AuditLocationsGridRead",
                        type: "POST",
                        data: {
                            facilityId: facilityId
                        },
                        dataType: 'JSON'
                    },
                    destroy: {
                        url: "/WebUI/Inventory/EditAudits/AuditLocationsGridRead",
                        type: "GET",
                        dataType: "JSON"
                    },
                    parameterMap: function (options, operation) {
                        if (operation !== "read" && options.models) {
                            return {
                                models: JSON.stringify(options.models)
                            };
                        }
                    },
                },
                change: function (data) {
                    // console.log("success");
                },
                error: function (xhr, error) {
                    var errorMessage = "Caught an unknown error attempting to call LocationsGridRead";
                    if (xhr) {
                        if (xhr.xhr) {
                            if (xhr.xhr.responseText) {
                                errorMessage = xhr.xhr.responseText;
                            }
                        }
                    }
                    var errorTitle = xhr.errorThrown || that.translate('Label_Error');
                },
                schema: commanFunction.cycleCountJobLocationGridSchema(),
            },
            scrollable: {
                virtual: true,
                height: 280
            }, columnMenu: {
                sortable: false
            },
            columnMenuInit: function (e) {
                var menu = e.container.find(".k-menu");
                var filterMenu = e.container.find(".k-filter-menu");
                // Set class to filter form of grit to reset specific grid column
                if (!filterMenu.hasClass('cycleCountLocationGridFilterForm')) {
                    filterMenu.addClass('cycleCountLocationGridFilterForm');
                }
            },
            selectable: true,
            sortable: {
                mode: "multiple",
                allowUnsort: true,
                showIndexes: true
            },
            resizable: true,
            reorderable: true,
            filterable: true,
            pageable: false,
            editable: "popup",
            sort: function (e) {
                e.preventDefault();
                var sortedField = e.sort.field;
                GlobalService.sortDataset(that.state.sortArrayGrid, e.sort);
                this.dataSource.sort(that.state.sortArrayGrid);
                var finalSubmission = kendoGridJobLocation.prepareGridOptionsForStorage($("#cycleCountLocationGrid").data("kendoGrid").getOptions().columns, that.state.sortArrayGrid);
            }, columnShow: function (e) {
            },
            columnHide: function (e) {
            },
            columnReorder: function (e) {
            },
            columnResize: function (e) {
            },
            dataBinding: function (e) {
            },
            dataBound: function (e) {
                if (typeof $("#cycleCountLocationGrid").data("kendoGrid") != 'undefined') {
                    kendoGridJobLocation.repaintSelections();
                }
                if (that.state.initialjobLocationGrid == 0) {
                    var grid = $('#cycleCountLocationGrid').data('kendoGrid');
                    grid.table.on('click', function (e) {
                        var gridData = grid.dataSource.view();
                        var dataItem = grid.dataItem($(e.target).closest('tr'));
                        var currentPage = grid.dataSource.page();
                        var currentNumberOfItems = grid.dataSource.view().length;
                        var row = $(e.target).closest('tr').index();
                        var col = grid.cellIndex($(e.target).closest('td'));

                        kendoGridJobLocation.clickEventHandleSelection(e, currentPage, currentNumberOfItems, row, col, dataItem, grid.dataSource.data(), 'Id', function () {
                            // once we removed the synchronous call this gets called before the end of the of the element
                            var currentSelections = kendoGridJobLocation.getNumberOfSelections();
                            currentSelections == 1 ? that.state.DisplayShowEditButton = true : that.state.DisplayShowEditButton = false;
                            currentSelections >= 1 ? that.state.DisplayShowActiveDelete = true : that.state.DisplayShowActiveDelete = false;



                            var myresult3 = kendoGridJobLocation.getKeys();
                            if (myresult3[0] == 'undefined') {
                                that.state.DisplayShowEditButton = false;
                            }
                            // for check selected value add in locationgrid or not
                            var selectedLocation = kendoGridJobLocation.getSelections();
                            var locationList = that.state.locationList;
                            that.setState({
                                addLocationDisabled: true
                            });
                            for (var i = 0; i < selectedLocation.length; i++) {
                                var locationExist = locationList.filter((location) => {
                                    return selectedLocation[i] == location.Id;
                                });
                                if (locationExist.length == 0) {
                                    that.setState({
                                        addLocationDisabled: false
                                    });
                                }
                            }
                        });
                    });
                    that.setState({
                        initialjobLocationGrid: 1
                    });
                }
                that.addLocationAllocatedClass();
            }
        };
        this.state.tempOptionsLocationJobGridColumns = {
            columns: commanFunction.cycleCountJobLocationGridColumns(this.LocationJob_GridHeaders, this.translate)
        };
        var finalOptionsObj = kendoGridJobLocation.getGridOptions(this.state.tempOptionsLocationJobGridColumns, tempOptionsLocationJobGrid, '[{}]');
        $("#cycleCountLocationGrid").kendoGrid(finalOptionsObj);
        this.filterHandleActiveLocation({ target: { checked: false } });
        $("#cycleCountLocationGridResetMenu").kendoMenu();

    }
    eventClickResetGrid() {

        that.state.initialjobLocationGrid = 0;
        var column = {
            columns: commanFunction.cycleCountJobLocationGridColumns(that.LocationJob_GridHeaders, that.translate)
        };
        var grid = $("#cycleCountLocationGrid").data("kendoGrid");
        grid.setOptions(column);
        grid.dataSource.sort({});
        $('#cycleCountLocationGrid').data('kendoGrid').refresh();
        kendoGridJobLocation.clearSelections(function () {
            kendoGridJobLocation.repaintSelections();
        });
        that.setState({
            addLocationDisabled: true
        });
    }
    eventClickClearFilters() {
        $("form.k-filter-menu button[type='reset']").trigger("click");
    }
    eventClickClearSorts() {
        var grid = $('#cycleCountLocationGrid').data('kendoGrid');
        grid.dataSource.sort({});
        // kendo.ui.progress($('#cycleCountJobsGrid'), true);
        // var finalSubmission = KendoGridFW.prepareGridOptionsForStorage($("." + that.state.windowId + " #cycleCountJobsGrid").data("kendoGrid").getOptions().columns, []);
        // GlobalService.setGridOptions(gridName, finalSubmission).then((response) => {
        //     kendo.ui.progress($('#cycleCountJobsGrid'), false);
        // }, (error) => {

        // });
    }
    render() {
        return (
            <div className="cyclecount-form-section">
                <div className="location-form-section">
                    <div>
                        <div className="wms_bottom_18">
                            <WmsButton
                                label={this.translate('Label_Search')}
                                wmsSelector={this.state.selector + 'FormSearchButton'}
                                onClickFunc={this.searchJobLocation}
                                icon='fa-search' />
                            <WmsButton
                                label={this.translate('Label_Clear_Filters')}
                                wmsSelector={this.state.selector + 'FormResetButton'}
                                onClickFunc={this.clearFilters}
                                buttonType={'yellow'}
                                icon='fa-minus' />
                            <WmsButton
                                label={this.translate('Label_Exit')}
                                wmsSelector={this.state.selector + 'FormExit'}
                                onClickFunc={this.props.closeCyclecountForm}
                                buttonType={'yellow'}
                                icon='fa-times-circle' />
                        </div>
                    </div>
                    <WmsContainer>

                        <div className="inner-filter-section">
                            <div className="form-group wms-input-group-wrapper">
                                <WmsDropdown
                                    id="IndexOptionsLocation"
                                    name="IndexOptionsLocation"
                                    label={this.translate('Label_Contains')}
                                    wmsSelector={this.state.selector + "IndexOptionsLocation"}
                                    value={this.state.filter.IndexOptionsLocation}
                                    onChangeFunc={this.filterHandleChange}
                                    options={this.state.optionConatins}
                                    valueField='value'
                                    textField='description'
                                    parentClassName="select_box"
                                />
                                <WmsInput
                                    inputName="Location"
                                    wmsSelector={this.state.selector + "Location"}
                                    value={this.state.filter.Location}
                                    onChangeFunc={this.filterHandleChange}
                                    label={this.translate('Label_Location_Name')}
                                    valueType={"letters"}
                                    parentClassName="input_box" />
                            </div>
                            <WmsDropdown
                                id="LocationType"
                                name="LocationType"
                                label={this.translate('Label_Cycle_count_Location_Type')}
                                wmsSelector={this.state.selector + "LocationType"}
                                value={this.state.filter.LocationType}
                                onChangeFunc={this.filterHandleChange}
                                options={this.state.optionLocationType}
                                valueField='Value'
                                textField='Name'
                                parentClassName="form_group" />

                        </div>
                        <div className="inner-filter-section">
                            <WmsDateTimePicker
                                id={'LastCountedDate'}
                                name='LastCountedDate'
                                label={this.translate('Label_Cycle_count_Last_Counted')}
                                wmsSelector={this.state.selector + "LastCountedDate"}
                                onChangeFunc={this.filterHandleChange}
                                value={this.state.filter.LastCountedDate}
                                format="MMM dd" onlyDate={true} />
                            <WmsCheckBox
                                id={'inActiveLocation'}
                                name={'inActiveLocation'}
                                wmsSelector={this.state.selector + 'inActiveLocation'}
                                onChangeFunc={this.filterHandleActiveLocation}
                                label={this.translate('Label_Cycle_count_Inactive_Locations')}
                                value={this.state.inActiveLocation}
                                checked={this.state.inActiveLocation} />
                        </div>
                        {/* <div className={style.wms_checkbox_group}>
                            <label>
                                <input
                                    className={style.option_input}
                                    type="checkbox"
                                    name="excludeLocations"
                                    value={this.state.excludeLocations}
                                    checked={this.state.excludeLocations}
                                    onChange={this.toggleCheckbox} />
                                {this.translate('Label_Cycle_count_Job_locations')}
                            </label>
                        </div> */}
                    </WmsContainer>
                    {/* Location grid */}
                    {/* <div className="wms-area-content">
                        <div className="wms-toolbar" >
                            <div className="wms-toolbar-grid reset-btn">

                                <ul id="cycleCountLocationGridResetMenu"
                                    k-orientation="'horizontal'" className="GridMenu">
                                    <li className="GridMenuTopLink">
                                        <span></span>
                                        <ul>
                                            <li onClick={this.eventClickResetGrid}>
                                                <span>{this.translate('Label_Reset_Grid')}</span>
                                            </li>
                                            <li onClick={this.eventClickClearFilters}>
                                                <span>{this.translate('Label_Clear_Column_Filters')}</span>
                                            </li>
                                            <li onClick={this.eventClickClearSorts}>
                                                <span>{this.translate('Label_Clear_Sort')}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div> */}

                    {/* <div className="wms-area-content-darker without-bg"> */}
                    <ul id="cycleCountLocationGridMenu">
                        <li className={style.wms_context_row} data-wmsid="addLocation" data-wms-selector={this.state.selector + "AddJobLocation"}>Add Location</li>
                    </ul>
                    <ul id="cycleCountLocationGridGroupMenu">
                        <li className={style.wms_context_row} data-wmsid="addLocation">Add Location</li>
                    </ul>
                    <ul id="jobLocationGridMenu">
                        <li className={style.wms_context_row} data-wmsid="removeLocation">Remove Location</li>
                    </ul>
                    <div className="wms-grid-wrapper">
                        <div className="wms-toolbar-holder">
                            <WmsButton
                                label={'Add Location'}
                                wmsSelector={this.state.selector + 'FormAddLocation'}
                                onClickFunc={this.addLocation}
                                disabled={this.state.addLocationDisabled}
                                icon="fa-plus" />
                            <div className="wms-toolbar-grid reset-btn">
                                <ul
                                    id="cycleCountLocationGridResetMenu"
                                    k-orientation="'horizontal'"
                                    className="GridMenu"
                                >
                                    <li className="GridMenuTopLink">
                                        <span></span>
                                        <ul>
                                            <li onClick={this.eventClickResetGrid}>
                                                <span>{this.translate("Label_Reset_Grid")}</span>
                                            </li>
                                            <li onClick={this.eventClickClearFilters}>
                                                <span>
                                                    {this.translate("Label_Clear_Column_Filters")}
                                                </span>
                                            </li>
                                            <li onClick={this.eventClickClearSorts}>
                                                <span>{this.translate("Label_Clear_Sort")}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="title">
                            <label>
                                {this.translate('Label_Cycle_count_Location_Detail')}
                            </label>
                        </div>
                        <div className="wms-grid-holder">
                            <div
                                className="no-josh"
                                id="cycleCountLocationGrid"
                                data-wms-selector={this.state.selector + "LocationGrid"}></div>
                        </div>

                    </div>
                </div>
                {/* create form html start */}
                <div className="job-form-section">
                    <div className={style.wms_button_belt}>
                        <WmsButton
                            label={
                                this.state.sharedCycleCount.flowType == 'create' ?
                                    this.translate('Label_Cycle_count_Create_Job') :
                                    this.translate('Label_Cycle_count_Edit_Job')
                            }
                            wmsSelector={this.state.selector + 'CreateJob'}
                            onClickFunc={this.createJob}
                            loading={this.state.createJobGif}
                            disabled={this.state.jobName == '' || this.state.jobPriotity == '' || this.state.locationList.length == 0 || this.state.createJobGif}
                            showToolTip={true}
                            tooltipText={this.state.jobName == '' || this.state.jobPriotity == '' ? this.translate('Label_Cycle_Count_Required_tooltip') : this.state.locationList.length == 0 ? this.translate('Label_Cycle_Count_Required_Location_tooltip') : ''}
                            icon={this.state.sharedCycleCount.flowType == 'create' ? 'fa-plus' : 'fa-floppy-o'} />
                    </div>
                    <WmsContainer>
                        <WmsInput
                            inputName="jobName"
                            wmsSelector={this.state.selector + "jobName"}
                            value={this.state.jobName}
                            onChangeFunc={this.handleChange}
                            label={this.translate('Label_Cycle_count_Job_Name')}
                            valueType={"letters"}
                            required={true} />

                        {this.state.Rights.hasOwnProperty('mobile.audit') && (<WmsDropdown
                            id="assignUser"
                            name="assignUser"
                            label={"Assigned To"}
                            wmsSelector={this.state.selector + "jobAssignTo"}
                            value={this.state.assignUser}
                            onChangeFunc={this.handleChange}
                            options={this.state.sharedCycleCount.userOption}
                            valueField='Id'
                            textField='Name'
                            /* required={this.state.assignUser == ''} */
                            parentClassName="form_group" />)}

                        <WmsDropdown
                            id="jobPriotity"
                            name="jobPriotity"
                            label={this.translate('Label_Cycle_count_Job_Priority')}
                            wmsSelector={this.state.selector + "jobPriotity"}
                            value={this.state.jobPriotity}
                            onChangeFunc={this.handleChange}
                            options={this.state.jobPriorityOption}
                            valueField='value'
                            textField='text'
                            required={true}
                            parentClassName="form_group" />



                        <WmsCheckBox
                            id={'collectTrackBy'}
                            name={'collectTrackBy'}
                            wmsSelector={this.state.selector + "collectTrackBy"}
                            onChangeFunc={this.toggleCheckbox}
                            label={this.translate('Label_Cycle_count_Collect_TrackBy')}
                            checked={this.state.collectTrackBy}
                            value={this.state.collectTrackBy} />


                    </WmsContainer>

                    {/*
                    <WmsButton
                        label={this.translate('Label_Cycle_count_Remove_Location')}
                        wmsSelector={this.state.selector+'RemoveLocation'}
                        buttonType={'yellow'}
                        onClickFunc={this.removeElement}
                        disabled={this.state.locationList.length == 0 || this.state.removeLocationDisabled}
                        icon='fa-minus' />

                        */}
                    <div className="wms-grid-wrapper">
                        <div className="title">
                            <label title="Right click over locations below to remove">Right click over locations below to remove</label>
                        </div>
                        <div className="wms-grid-holder">
                            <div className="no-josh" id="jobLocationGrid" data-wms-selector={this.state.selector + "JobLocationGrid"}></div>
                            <div className={style.wms_area_content}>
                                <div className={style.wms_table_content_wrapper}>
                                    {/* <div className={style.wms_table_header}>
                                    <label>{this.translate('Label_Name')}</label>
                                </div>
                                <div id="playlist" className={style.wms_playlist}>
                                    <ul id="sortable-basic">
                                        {this.state.locationList.map((Option, index) => (
                                            <li key={Option.Id} className={"sortable"}>{Option.Name} <span onClick={() => this.removeElement(index)}><i className="fa fa-times-circle-o" aria-hidden="true"></i></span></li>
                                        ))}
                                    </ul>

                                </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}