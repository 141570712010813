import React, { useState, useEffect } from "react";
import WmsGrid from "../components/WmsGrid/WmsGrid";
import * as GridConfig from "./audit-inventory-grid-configuration";
import ManageAuditInventoryModal from "./manage-audit-inventory.modal.component";
import { StringWms } from "../../../assets/ts/StringWms";
import AuditInventoryReviewAuditJobModal from "./modals/audit-inventory-review-audit-job-modal.component";
interface Props {
    selector: string;
    windowId: string;
    facId: number;
    facUri: string;
    auditGridData: any;
    userOptions: any;
    locationTypes: any;
    getAuditOptions: any;
}

declare var window: any;
const AuditInventoryGrid: React.FC<Props> = (props: Props): any => {
    const defaultColumns = GridConfig.cycleCountJobsGridColumns(
        StringWms.convertSringToOption(window.translate("Audit_Inventory_GrideHeaders"))
    );
    const [schema] = useState(GridConfig.cycleCountJobsGridSchema());
    const [gridData, setGridData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(0);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [auditType, setAuditType] = useState("Create");
    const [isShowManageAuditInventoryModal, setIsShowManageAuditInventoryModal] = useState(false);
    const [isReviewAuditJobModelOpen, setReviewAuditJobModelOpen] = useState(false);
    const [isReviewButtonDisabled, setIsReviewButtonDisabled] = useState(true);
    const [auditCountsUris, setAuditCountUris] = useState([]);
    const [isEditButtonEnable, setIsEditButtonEnable] = useState(false);

    useEffect(() => {
        setGridData(props.auditGridData);
    }, [props.auditGridData, gridData]);

    const selectRow = (KendoGrid) => {
        let currentSelections = KendoGrid.getNumberOfSelections();
        if (currentSelections === 1) {
            var tmpIsEditButtonEnable = KendoGrid.valueExists("Status", "0");
            let data = {};
            let tempKey = KendoGrid.getKeys();
            data = KendoGrid.getItem(tempKey);
            setSelectedRowData(data);
            setIsEditButtonEnable(tmpIsEditButtonEnable);
        } else {
            setSelectedRowData(null);
        }
        setSelectedRow(currentSelections);
        if (currentSelections > 0) {
            let tempIsReviewButtonDisabled = false;
            let tempAuditCountsUris: Array<string> = [];
            KendoGrid.getValues().forEach((gridValue: any) => {
                tempAuditCountsUris.push(gridValue.CountsUri);
                if (parseInt(gridValue.Status) !== 2) {
                    tempIsReviewButtonDisabled = true;
                }
            });
            setIsReviewButtonDisabled(tempIsReviewButtonDisabled);
            setAuditCountUris(tempAuditCountsUris);
        }
    };

    const openReviewAuditJobModel = () => {
        setReviewAuditJobModelOpen(true);
    };
    const onCreateAuditInventoryClick = () => {
        setAuditType("Create");
        setIsShowManageAuditInventoryModal(true);
    };
    const onEditAuditInventoryClick = () => {
        setAuditType("Edit");
        setIsShowManageAuditInventoryModal(true);
    };
    const onCloseManageAuditInventoryModal = () => {
        setIsShowManageAuditInventoryModal(false);
    };

    return (
        <div className={`audit-inventory-grid`}>
            <div className={"wms-audit-inventory-grid-holder"}>
                <WmsGrid
                    id={props.selector + "Grid"}
                    wmsSelector={props.selector + "Grid"}
                    gridKeySelection={"EditUri"}
                    gridName={props.selector + "Grid"}
                    resetButtonLabel={"Options"}
                    isAlignIndexesByProperty={true}
                    virtual={false}
                    defaultColumns={defaultColumns}
                    columns={defaultColumns}
                    schema={schema}
                    showGridSummary={false}
                    onSelection={selectRow}
                    clickEventOfContextMenu={() => null}
                    shawCheckbox={false}
                    showGridResult={false}
                    staticGridData={gridData}
                    showSortColumns={false}
                    onDataBound={() => {}}
                    emptyRecoredText={window.translate("Window_Title_Label_Warehouse_selection_Require")}
                    menu={[
                        {
                            "name": window.translate("Label_Create"),
                            "value": [],
                            "disabled": props.facId === 0,
                            "icon": "fa fa-plus",
                            "color": "green",
                            "clickFunc": onCreateAuditInventoryClick,
                            "wmsSelector": props.selector + "CreateJob",
                            "isContextMenu": false,
                        },
                        {
                            "name": window.translate("Label_Edit"),
                            "value": [],
                            "disabled": !isEditButtonEnable,
                            "icon": "fa fa-pencil",
                            "color": "green",
                            "clickFunc": onEditAuditInventoryClick,
                            "wmsSelector": props.selector + "EditJob",
                            "isContextMenu": false,
                        },
                        {
                            "name": window.translate("Label_Review"),
                            "value": [],
                            "disabled": selectedRow === 0 || isReviewButtonDisabled,
                            "icon": "fa fa-tasks",
                            "color": "green",
                            "clickFunc": openReviewAuditJobModel,
                            "wmsSelector": props.selector + "ReviewJob",
                            "isContextMenu": false,
                        },
                        {
                            "name": window.translate("Label_Close"),
                            "value": [],
                            "disabled": selectedRow === 0,
                            "icon": "fa fa-times-circle",
                            "color": "green",
                            "clickFunc": () => {},
                            "wmsSelector": props.selector + "CloseJob",
                            "isContextMenu": false,
                        },
                    ]}
                />
                {isShowManageAuditInventoryModal && (
                    <ManageAuditInventoryModal
                        facId={props.facId}
                        facUri={props.facUri}
                        isOpenManageAuditInventoryModal={isShowManageAuditInventoryModal}
                        onCloseManageAuditInventoryModal={onCloseManageAuditInventoryModal}
                        wmsSelector={props.selector}
                        windowId={props.windowId}
                        userOptions={props.userOptions}
                        locationTypes={props.locationTypes}
                        getAuditOptions={props.getAuditOptions}
                        selectedRowData={selectedRowData}
                        auditType={auditType}
                    />
                )}
            </div>
            {isReviewAuditJobModelOpen && (
                <AuditInventoryReviewAuditJobModal
                    selector={props.selector}
                    auditCountsUris={auditCountsUris}
                    facilityUri={props.facUri}
                    selectedRowData={selectedRowData}
                    isOpen={isReviewAuditJobModelOpen}
                    onReviewAuditJobModalClose={() => {
                        setReviewAuditJobModelOpen(false);
                    }}
                />
            )}
        </div>
    );
};
AuditInventoryGrid.propTypes = {};

AuditInventoryGrid.defaultProps = {};

export default React.memo(AuditInventoryGrid);
