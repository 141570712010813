import React from 'react';

import WmsButton from './../../../components/WmsButton/WmsButton.jsx';
import WmsDropdown from './../../../components/WmsDropdown/WmsDropdown.jsx';
import WmsModel from './../../../components/WmsModel/WmsModel.jsx';
import WmsCollapse from './../../../components/WmsCollapse/WmsCollapse.jsx';
import WmsCheckBox from './../../../components/WmsCheckBox/WmsCheckBox.jsx';
import WmsDateTimePicker from './../../../components/WmsDateTimePicker/WmsDateTimePicker.jsx';
import { FedexEulaAgreementComponent } from './fedex-eula-agreement.component.jsx';
import { FedexEditComponent } from './fedex-edit.component.jsx';
import { UpsEditComponent } from './ups-edit.component.jsx';
import { UpsEulaAgreementComponent } from './ups-eula-agreement.component.jsx';
import WmsInput from './../../../components/WmsInput/WmsInput.jsx';
import style from './new-carrier-connection.scss';
var axios = require('axios');

//services
import * as GlobalService from './../../../global_services';
import * as carrierService from './../../small-parcel-settings.services.jsx';
import { EndiciaAccountInfoComponent } from './endicia-account-info.component.jsx';
import { DHLeCommerceAccountInfoComponent } from './dhl-eCommerce-account-info.component.jsx';
import { DHLExpressAccountInfoComponent } from './dhl-express-account-info.component.jsx';
import { AustraliaPostAccountInfoComponent } from './australia-post-account-info.component.jsx';
import { StampsAccountInfoComponent } from './stamps-account-info.component.jsx';
import { APCAccountInfoComponent } from './apc-account-info.component.jsx';
import { FirstMileInfoComponent } from './first-mile-info.component.jsx';
import { GlobegisticsInfoComponent } from './globegistics-info.component.jsx';
import { RrDonnelleyInfoComponent } from './rrDonnelley-info.component.jsx';
import { CanadaPostComponent } from './canada-post.component.jsx';
import { OnTraceComponent } from './on-trac.component.jsx';
import { SekoComponent } from './seko.component.jsx';
import { AmazonComponent } from './amazon.component.jsx';
import { CanparAccountInfoComponent } from './canpar-account-info.component.jsx';
import { PurolatorAccountInfoComponent } from './purolator-account-info.component.jsx';
import { DHLExpressAUAccountInfoComponent } from './dhl-express-au-account-info.component.jsx';
import { USPSBukuShipAccountInfoComponent } from './usps-bukuship-account-info.component.jsx';
import { PitneyBowesStandardInfoComponent } from './pitney-bowes-standard-info-component.jsx';
import { LandmarkAccountInfoComponent } from './landmark-account-info.component.jsx';
import { StallionAccountInfoComponent } from './stallion-account-info.component.jsx';
import { ParcllAccountInfoComponent } from './parcll-account-info.component.jsx';
import { EpostGlobalAccountInfoComponent } from './epostGlobal-account-info.component.jsx';
import { PitneyBowesExpeditedInfoComponent } from './pitney-bowes-expedited-info-component.jsx';
import { PitneyBowesCrossBorderInfoComponent } from './pitney-bowes-cross-border-info-component.tsx';
import { PassportInfoComponent } from './passport-info.component.tsx';
import { SendleInfoComponent } from './sendle-info.component.tsx';
import { TuskInfoComponent } from './tusk-info.component.tsx';
import { FlexportInfoComponent } from './flexport-info.component.tsx';
import { LasershipInfoComponent } from './lasership-info.component.tsx';
import { UpsmiAccountInfoComponent } from './upsmi-account-info.component.jsx';
import FedExSmartPostAccountInfoComponent from './fedex-smartpost-info-component';
import UpsSurePostAccountInfoComponent from './ups-surepost-account-info.component';

export class NewCarrierConnectionModel extends React.Component {

    constructor(props) {

        super(props);

        this.translate = window.translate;
        this.$rootScope = window;
        this.selector = this.props.selector + "Carrier";
        this.rights = GlobalService.GetRights();
        this.optionsCountry = GlobalService.GetCountries();
        this.customerOptions = GlobalService.getCustomerList();
        this.facilityOption = GlobalService.getFacilityList(false);
        this.isEnableProvider = this.rights.hasOwnProperty('Shipping.SmallParcelEnabled') && this.rights['Integrations.SmallParcelPartner'] == 'ParcelApi';
        this.carrierElementClass = this.isEnableProvider ? "new-connection-four-elements" : "new-connection-three-elements";

        this.selectedProviderObj = [];

        this.state = {
            selectedContentType: '',
            selectedFacilityIndex: '',
            selectedCustomerIndex: '',
            selectedCarrierIndex: '',
            selectedProviderIndex: '',
            selectedCarrier: '',
            carrierConnectionBtnLoader: false,
            currentStep: 1,
            totalSteps: 0,
            currentCarrierImgUrl: '',
            currentLoginLabel: 'Login',
            carrierOptions: [],
            providerOptions: [],
            pickupTypeOptions: [],
            smartPostHubOptions: [],
            smartPostEndorsementOptions: [],
            mailInnovationsEndorsementOptions: [],
            optionsState: [],
            currentCarrierObj: {},
            isOpenPitneyBowesModel: false,
            pitneyBowesUsername: '',
            pitneyBowesPassword: '',
            pitneyBowesErrorMessage: '',
            pitneyBowesCrossBorderDisabledStatus: true,
            isAuthUrl: false
        };
    }
    componentDidMount() {

        this.getCarrierOptions();
        if (this.props.sharedCarrierConnectionFlow == 'edit') {

            kendo.ui.progress($('#newCarrierConnection'), true);
            carrierService.getCarrierDetail(this.props.sharedSelectedCarrierAccountData.ShipEngineId).then((response) => {
                // response.data.CarrierName = 'UPS';
                if (this.isEnableProvider) {
                    response.data.Provider = this.props.sharedSelectedCarrierAccountData.Provider;
                }
                this.setState({
                    currentCarrierObj: response.data
                });
                this.setCarrierOptions(response.data.CarrierName);

            }).finally(() => {
                kendo.ui.progress($('#newCarrierConnection'), false);
            });

        }
    }
    getCarrierOptions = () => {
        var carrierOptions = this.props.sharedShipEngineCarrier;
        var arrrOfRemoveCarrieresList = ["DHL Express UK", "DHL Express Canada", "FedEx UK", "DPD", "Asendia", "Newgistics", "Royal Mail", "Lone Star Overnight", "Less-Than-Load", "Walmart Fedex"];

        if (!this.rights.hasOwnProperty('UxPrototypingDirectivesPBCBDS')) {
            arrrOfRemoveCarrieresList.push('Pitney Bowes Cross-Border');
        }
        _.remove(carrierOptions, obj => arrrOfRemoveCarrieresList.includes(obj.Carrier));
        this.setState({
            carrierOptions: carrierOptions
        });
    }
    handleChangeEvent = (e) => {
        let name = e.target.name;
        if (e.target.type == 'checkbox') {
            this.setState({ [name]: !this.state[name] });
        } else {
            this.setState({ [name]: e.target.value });
        }
        // if(name == 'selectedContentType' ){
        //     this.setState({selectedFacilityIndex:''});
        // }

        if (name == 'selectedCarrierIndex') {
            if (e.target.value) {
                let tmpSelectedCarrier = this.state.carrierOptions[e.target.value].Carrier;

                this.setCarrierOptions(tmpSelectedCarrier);

                // Check If Flexport Carrier Then Select Direct To Carrier Provider
                if(tmpSelectedCarrier === 'Flexport') {
                    this.setState({ selectedProviderIndex: 'flexport' });
                } else {
                    this.setState({ selectedProviderIndex: '' });
                }

                this.setState({ currentCarrierObj: {} });
            } else {
                this.setState({
                    selectedProviderIndex: '',
                    providerOptions: []
                });
            }
        }
        if(name == 'selectedProviderIndex') {
            if(this.state.selectedCarrier == 'UPS' && e.target.value == 'shipEngine') {
                this.setState({ totalSteps: 3 });
            }
            if(this.state.selectedCarrier == 'UPS' && e.target.value != 'shipEngine') {
                this.setState({ totalSteps: 4 });
            }
        }
    }
    setCarrierOptions = async (tmpSelectedCarrier) => {


        let totalSteps = 2;
        let currentStep = 1;
        let currentCarrierImgUrl = '3PL_Logo_Orange.png';
        let currentLoginLabel = 'Login';
        let providerOptions = [];
        if (this.isEnableProvider && tmpSelectedCarrier != "USPS" && tmpSelectedCarrier != "ePost Global" && tmpSelectedCarrier != "Landmark" && tmpSelectedCarrier != "PARCLL" && tmpSelectedCarrier != "Pitney Bowes Cross-Border" && tmpSelectedCarrier != "Stallion Express" && tmpSelectedCarrier != "UPS Mail Innovations" && tmpSelectedCarrier != "FedEx SmartPost" && tmpSelectedCarrier != "UPS SurePost" && tmpSelectedCarrier != "Flexport") {
            providerOptions = [
                {
                    DisplayName: "ShipEngine",
                    Code: "shipEngine"
                }
            ];
        }

        if (tmpSelectedCarrier == 'Endicia') {
            totalSteps = 2;
            currentCarrierImgUrl = 'endicia-logo.png';
            currentLoginLabel = 'Endicia Account';
        } else if (tmpSelectedCarrier == 'FedEx') {
            if (this.isEnableProvider && !this.rights.hasOwnProperty("UxPrototypingDirectivesHidePitneyBuku")) {
                providerOptions.push({
                    DisplayName: "BUKU Ship",
                    Code: "bukuShip"
                }/*, {
                    DisplayName: "Pitney Bowes",
                    Code: "pitneyBowes"
                }*/);
            }
            totalSteps = 3;
            currentCarrierImgUrl = 'FedEx-logo.png';
        } else if (tmpSelectedCarrier == 'PitneyBowesStd') {
            providerOptions = [
                {
                    DisplayName: "Pitney Bowes",
                    Code: "pitneyBowes"
                }
            ];
            totalSteps = 2;
            currentCarrierImgUrl = 'pitney-bowes-standard-logo.png';
        } else if (tmpSelectedCarrier == 'Stamps.com') {
            totalSteps = 2;
            currentCarrierImgUrl = 'Stamps.com-logo.png';
            currentLoginLabel = 'Stamps Account';
        } else if (tmpSelectedCarrier == 'APC') {
            totalSteps = 2;
            currentCarrierImgUrl = 'APC-logo.png';
        } else if (tmpSelectedCarrier == 'USPS') {
            if (this.isEnableProvider) {
                if (!this.rights.hasOwnProperty('UxPrototypingDirectivesHidePitneyBuku')) {
                    providerOptions = [
                        {
                            DisplayName: "Pitney Bowes Expedited",
                            Code: "pitneyBowes"
                        }
                    ];
                }
                if (!this.rights.hasOwnProperty('UxPrototypingDirectivesHidePitneyBuku') && !this.rights.hasOwnProperty("UxPrototypingDirectivesHideUSPSProviders")) {
                    providerOptions.push(
                        {
                            DisplayName: "BUKU Ship",
                            Code: "bukuShip"
                        }
                    );
                }
                if (!this.rights.hasOwnProperty("UxPrototypingDirectivesHideUSPSProviders")) {
                    providerOptions.push(
                        {
                            DisplayName: "Endicia",
                            Code: "Endicia"
                        },
                        {
                            DisplayName: "Stamps.com",
                            Code: "Stamps.com"
                        }
                    );
                }
            }
        } else if (tmpSelectedCarrier == 'UPS') {
            if (this.isEnableProvider && !this.rights.hasOwnProperty("UxPrototypingDirectivesHidePitneyBuku")) {
                providerOptions.push({
                    DisplayName: "BUKU Ship",
                    Code: "bukuShip"
                }/*, {
                    DisplayName: "Pitney Bowes",
                    Code: "pitneyBowes"
                }*/);
            }
            totalSteps = 4;
            currentCarrierImgUrl = 'UPS-logo.png';
        } else if (tmpSelectedCarrier == 'DHL eCommerce') {
            if (this.isEnableProvider && !this.rights.hasOwnProperty("UxPrototypingDirectivesHidePitneyBuku")) {
                providerOptions.push({
                    DisplayName: "BUKU Ship",
                    Code: "bukuShip"
                });
            }
            totalSteps = 2;
            currentCarrierImgUrl = 'dhl-ecommerce.png';
        } else if (tmpSelectedCarrier == 'DHL Express') {
            if (this.isEnableProvider) {
                providerOptions.push({
                    DisplayName: "BUKU Ship",
                    Code: "bukuShip"
                });
            }
            totalSteps = 2;
            currentCarrierImgUrl = 'dhl-express.png';
        } else if (tmpSelectedCarrier == 'Australia Post') {
            totalSteps = 2;
            currentCarrierImgUrl = 'australia-post.png';
        } else if (tmpSelectedCarrier == 'Purolator') {
            totalSteps = 2;
            currentCarrierImgUrl = 'purolator-seeklogo.com.png';
        } else if (tmpSelectedCarrier == 'DHL Express Australia') {
            totalSteps = 2;
            currentCarrierImgUrl = 'DHLExpressAU.png';
        } else if (tmpSelectedCarrier == 'Firstmile') {
            if (this.isEnableProvider) {
                providerOptions.push({
                    DisplayName: "BUKU Ship",
                    Code: "bukuShip"
                });
            }
            totalSteps = 2;
            currentCarrierImgUrl = 'First Mile.png';
        } else if (tmpSelectedCarrier == 'Globegistics') {
            totalSteps = 2;
            currentCarrierImgUrl = 'Globegistics.png';
        } else if (tmpSelectedCarrier == 'RR Donnelley') {
            totalSteps = 2;
            currentCarrierImgUrl = 'rrDonnelley.png';
        } else if (tmpSelectedCarrier == 'Canada Post') {
            totalSteps = 2;
            currentCarrierImgUrl = 'canada-post-logo.jpg';
        } else if (tmpSelectedCarrier == 'OnTrac') {
            totalSteps = 2;
            currentCarrierImgUrl = 'ontrac.png';
        } else if (tmpSelectedCarrier == 'SEKO') {
            totalSteps = 2;
            currentCarrierImgUrl = 'seko.png';
        } else if (tmpSelectedCarrier == 'Amazon Buy') {
            totalSteps = 2;
            currentCarrierImgUrl = 'amazon.png';
        } else if (tmpSelectedCarrier == 'Canpar') {
            totalSteps = 2;
            currentCarrierImgUrl = 'canpar.png';
        } else if (tmpSelectedCarrier == 'ePost Global' || tmpSelectedCarrier == 'Landmark' || tmpSelectedCarrier == 'PARCLL' || tmpSelectedCarrier == 'Stallion Express' || tmpSelectedCarrier == 'UPS Mail Innovations' || tmpSelectedCarrier == 'FedEx SmartPost' || tmpSelectedCarrier == 'UPS SurePost') {
            totalSteps = 2;
            if (this.isEnableProvider) {
                providerOptions.push({
                    DisplayName: "BUKU Ship",
                    Code: "bukuShip"
                });
            }
            if(tmpSelectedCarrier == 'PARCLL') {
                currentCarrierImgUrl = 'parcll-logo.png';
            }
            if(tmpSelectedCarrier == 'ePost Global') {
                currentCarrierImgUrl = 'ePost-global.png';
            }
            if(tmpSelectedCarrier == 'Landmark') {
                currentCarrierImgUrl = 'landmark.png';
            }
            if(tmpSelectedCarrier == 'Stallion Express') {
                currentCarrierImgUrl = 'StallionExpressLogo.png';
            }
            if(tmpSelectedCarrier == 'UPS Mail Innovations') {
                currentCarrierImgUrl = 'UPS-logo.png';
            }
            if(tmpSelectedCarrier == 'FedEx SmartPost') {
                currentCarrierImgUrl = 'FedEx-logo.png';
            }
            if(tmpSelectedCarrier == 'UPS SurePost') {
                currentCarrierImgUrl = 'UPS-logo.png';
            }
        } else if (tmpSelectedCarrier == 'Pitney Bowes Cross-Border') {
            totalSteps = 2;
            providerOptions = [
                {
                    DisplayName: "Pitney Bowes",
                    Code: "pitneyBowes"
                }
            ];
            currentCarrierImgUrl = 'pitney-bowes-standard-logo.png';
        } else if (tmpSelectedCarrier === 'Passport') {
            totalSteps = 2;
            currentCarrierImgUrl = 'passport-shipping-logo.png';
        } else if (tmpSelectedCarrier === 'Sendle') {
            totalSteps = 2;
            currentCarrierImgUrl = 'sendle-logo.png';
        } else if (tmpSelectedCarrier === 'Tusk Logistics') {
            totalSteps = 2;
            currentCarrierImgUrl = 'tusk-logo.png';
        } else if (tmpSelectedCarrier === 'Flexport') {
            totalSteps = 2;
            currentCarrierImgUrl = 'flexport.png';
            providerOptions = [
                {
                    DisplayName: "Flexport",
                    Code: "flexport"
                }
            ];
        } else if (tmpSelectedCarrier === 'Lasership') {
            totalSteps = 2;
            currentCarrierImgUrl = 'lasership-logo.png';
        }

        let optionsForSettings = GlobalService.getDropdownOptionsForSettings(tmpSelectedCarrier);

        this.setState({
            currentCarrierImgUrl: currentCarrierImgUrl,
            totalSteps: totalSteps,
            currentLoginLabel: currentLoginLabel,
            selectedCarrier: tmpSelectedCarrier,
            pickupTypeOptions: optionsForSettings.pickupType,
            smartPostHubOptions: optionsForSettings.smartPostHub,
            smartPostEndorsementOptions: optionsForSettings.smartPostEndorsement,
            mailInnovationsEndorsementOptions: optionsForSettings.mailInnovationsEndorsement,
            currentStep: currentStep,
            providerOptions: providerOptions
        });
    }

    handleCarrierObjChange = (e) => {

        let tmpCurrentCarrierObj = Object.assign({}, this.state.currentCarrierObj);
        let tmpName = e.target.name;

        if (e.target.type == 'checkbox') {
            tmpCurrentCarrierObj[tmpName] = !tmpCurrentCarrierObj[tmpName];
            if (tmpName == "UseNegotiatedRates" && this.props.sharedCarrierConnectionFlow == 'edit' && this.state.selectedCarrier == 'UPS' && tmpCurrentCarrierObj[tmpName] == false) {
                tmpCurrentCarrierObj["InvoiceDate"] = "";
                tmpCurrentCarrierObj["InvoiceNumber"] = "";
                tmpCurrentCarrierObj["ControlId"] = "";
                tmpCurrentCarrierObj["InvoiceAmount"] = "";
            }

        } else {
            tmpCurrentCarrierObj[tmpName] = e.target.value;
        }

        if (this.state.selectedCarrier === "Pitney Bowes Cross-Border") {
            if (tmpName === "shipmentType") {
                tmpCurrentCarrierObj["cbdsDeliverySolution"] = "";
                tmpCurrentCarrierObj["clientFacilityId"] = undefined;
                tmpCurrentCarrierObj["cbdsHub"] = "";
                tmpCurrentCarrierObj["clientId"] = undefined;
            }
            this.setState({ pitneyBowesCrossBorderDisabledStatus: this.handlePitneyBowesDisabledStatus(tmpCurrentCarrierObj) });
        }
        if (tmpName == 'CountryCode') {
            this.setState({ optionsState: GlobalService.GetRegions(e.target.value) });
        }
        this.setState({ currentCarrierObj: tmpCurrentCarrierObj });
    }

    handlePitneyBowesDisabledStatus = (currentCarrierObject) => {
        let isMissingSomeRequiredProperty = true;
        const { shipmentType, cbdsDeliverySolution, cbdsHub, clientId, nickname, clientFacilityId } = currentCarrierObject;
        if (!shipmentType || !nickname) return isMissingSomeRequiredProperty;

        if (["DOMESTIC", "RETURN"].includes(currentCarrierObject?.shipmentType)) {
            isMissingSomeRequiredProperty = !(cbdsDeliverySolution)
        }
        if (currentCarrierObject?.shipmentType === "OUTBOUND") {
            const isPitneyStandardDeliverySolution = cbdsDeliverySolution === "PICKUP_STANDARDLABEL";
            const isMissingRequiredPropertiesForPbStandard = !(cbdsDeliverySolution && cbdsHub && clientId && clientFacilityId);
            const isMissingRequiredPropertiesForPbOutbound = !(cbdsDeliverySolution && cbdsHub);

            isMissingSomeRequiredProperty = isPitneyStandardDeliverySolution ? isMissingRequiredPropertiesForPbStandard : isMissingRequiredPropertiesForPbOutbound
        }
        return isMissingSomeRequiredProperty
    }

    movePreviousStep = () => {
        let prvStep = this.state.currentStep - 1;
        if (this.state.isOpenPitneyBowesModel) {
            prvStep = this.state.currentStep;
        }
        this.setState({
            currentStep: prvStep,
            isOpenPitneyBowesModel: false,
            isAuthUrl: false
        });
    }

    moveNextStep = async() => {
        var isOpenPitneyBowesModel = false;
        let nxtStep = this.state.currentStep + 1;

        let selectedProviderIndex = this.state.selectedProviderIndex;
        this.selectedProviderObj = _.find(this.state.providerOptions, function (o) {
            return o.Code == selectedProviderIndex
        });
        let isAlreadyLoggedIn = false;
        if (
            !this.state.isOpenPitneyBowesModel &&
            this.state.currentStep == 1 &&
            (
                selectedProviderIndex == "pitneyBowes" || selectedProviderIndex == "bukuShip"
            )
        ) {
            this.setState({ carrierConnectionBtnLoader: true });
            await carrierService.getProviderDetail(selectedProviderIndex).then((response) => {
                if (!response.data) {
                    nxtStep = this.state.currentStep;
                    isOpenPitneyBowesModel = true;
                }/* else if (this.state.selectedCarrier == "USPS" && selectedProviderIndex == "pitneyBowes") {
                    isAlreadyLoggedIn = true;
                }*/
            }).finally(() => {
                this.setState({ carrierConnectionBtnLoader: false });
            });
        }
        if (isAlreadyLoggedIn) {
            this.addCarrier();
        } else {
            if (this.state.selectedCarrier == "USPS") {
                let totalSteps = 2;
                let currentCarrierImgUrl = '3PL_Logo_Orange.png';
                let currentLoginLabel = 'Login';
                if (selectedProviderIndex == "Stamps.com") {
                    currentCarrierImgUrl = 'Stamps.com-logo.png';
                    currentLoginLabel = 'Stamps Account';
                } else if (selectedProviderIndex == "pitneyBowes") {
                    currentCarrierImgUrl = 'pitney-bowes-standard-logo.png';
                    currentLoginLabel = 'Pitney Bowes Expedited Account';
                } else if (selectedProviderIndex == "Endicia") {
                    currentCarrierImgUrl = 'endicia-logo.png';
                    currentLoginLabel = 'Endicia Account';
                } else if (selectedProviderIndex == "bukuShip") {
                    currentCarrierImgUrl = 'buku-ship.png';
                    currentLoginLabel = 'BukuShip Account';
                }
                this.setState({
                    currentCarrierImgUrl: currentCarrierImgUrl,
                    totalSteps: totalSteps,
                    currentLoginLabel: currentLoginLabel,
                });
            }
            this.setState({
                currentStep: nxtStep,
                isOpenPitneyBowesModel: isOpenPitneyBowesModel
            });
        }
    }

    updateCarrier = () => {

        this.setState({ carrierConnectionBtnLoader: true });

        carrierService.updateCarrier(this.state.currentCarrierObj).then((response) => {

            GlobalService.notification('success', this.translate('Label_Carrier_Update_Success'));

            this.props.onResetSelectionAndGridRefresh();
            this.props.closeCarrierConnection();

        }).finally(() => {
            this.setState({ carrierConnectionBtnLoader: false });
        });
    }

    addCarrier = () => {

        this.setState({ carrierConnectionBtnLoader: true });
        let tmpCurrentCarrierObj = Object.assign({}, this.state.currentCarrierObj);
        let carrierObj = this.state.carrierOptions[this.state.selectedCarrierIndex];
        let facilityObj = this.facilityOption[this.state.selectedFacilityIndex];
        let customerObj = this.customerOptions[this.state.selectedCustomerIndex];

        tmpCurrentCarrierObj.CarrierName = carrierObj.Carrier;
        tmpCurrentCarrierObj.CarrierUri = carrierObj.Uri;

        if (this.isEnableProvider) {
            tmpCurrentCarrierObj.Provider = this.state.selectedProviderIndex;
            if (tmpCurrentCarrierObj.CarrierName == "USPS") {
                if (this.state.selectedProviderIndex == "Stamps.com") {
                    tmpCurrentCarrierObj.Provider = "shipEngine";
                    tmpCurrentCarrierObj.CarrierName = "Stamps.com";
                } else if (this.state.selectedProviderIndex == "Endicia") {
                    tmpCurrentCarrierObj.Provider = "shipEngine";
                    tmpCurrentCarrierObj.CarrierName = "Endicia";
                }  else if (this.state.selectedProviderIndex == "bukuShip") {
                    tmpCurrentCarrierObj.CarrierName = "Buku";
                } else if (this.state.selectedProviderIndex == "pitneyBowes") {
                    tmpCurrentCarrierObj.CarrierName = "PitneyBowesExp";
                    if(!tmpCurrentCarrierObj.ShipperRatePlan) {
                        tmpCurrentCarrierObj.ShipperRatePlan = "";
                    }
                    if(!tmpCurrentCarrierObj.IntegratorCarrierId) {
                        tmpCurrentCarrierObj.IntegratorCarrierId = "";
                    }
                    if(!tmpCurrentCarrierObj.MinimalAddressValidation) {
                        tmpCurrentCarrierObj.MinimalAddressValidation = false;
                    }
                }
            }
        }

        if (facilityObj && this.state.selectedContentType == 'Warehouse Account') {
            tmpCurrentCarrierObj.FacilityId = facilityObj.id;
            tmpCurrentCarrierObj.FacilityUri = facilityObj.uri;
        } else {
            tmpCurrentCarrierObj.CustomerId = customerObj.id;
            tmpCurrentCarrierObj.CustomerUri = customerObj.uri;
        }


        if (tmpCurrentCarrierObj.CarrierName === "APC") {
            tmpCurrentCarrierObj.AccountNumber = tmpCurrentCarrierObj.Username;
        } else if (tmpCurrentCarrierObj.CarrierName === "Firstmile") {
            tmpCurrentCarrierObj.AccountNumber = tmpCurrentCarrierObj.MailerId;
        } else if (tmpCurrentCarrierObj.CarrierName === "Globegistics") {
            tmpCurrentCarrierObj.AccountNumber = tmpCurrentCarrierObj.Username;
        } else if (tmpCurrentCarrierObj.CarrierName === "Purolator") {
            tmpCurrentCarrierObj.AccountNumber = tmpCurrentCarrierObj.ApiKey;
        } else if (tmpCurrentCarrierObj.CarrierName === "RR Donnelley") {
            tmpCurrentCarrierObj.AccountNumber = tmpCurrentCarrierObj.Username;
        } else if (tmpCurrentCarrierObj.CarrierName === "SEKO") {
            tmpCurrentCarrierObj.AccountNumber = tmpCurrentCarrierObj.AccessKey;
        } else if (tmpCurrentCarrierObj.CarrierName === "ePost Global") {
            tmpCurrentCarrierObj.AccountNumber = tmpCurrentCarrierObj.ApiToken;
        } else if (tmpCurrentCarrierObj.CarrierName === "Landmark") {
            tmpCurrentCarrierObj.AccountNumber = tmpCurrentCarrierObj.Username;
        } else if (tmpCurrentCarrierObj.CarrierName === "PitneyBowesStd") {
            if(!tmpCurrentCarrierObj.MinimalAddressValidation) {
                tmpCurrentCarrierObj.MinimalAddressValidation = false;
            }
        } else if (tmpCurrentCarrierObj.CarrierName === "Pitney Bowes Cross-Border") {
            tmpCurrentCarrierObj.cbdsHub = tmpCurrentCarrierObj.cbdsHub || undefined;
            tmpCurrentCarrierObj.cbdsDeliverySolution = tmpCurrentCarrierObj.cbdsDeliverySolution || undefined;
            if(!tmpCurrentCarrierObj.MinimalAddressValidation) {
                tmpCurrentCarrierObj.MinimalAddressValidation = false;
            }
        } else if (tmpCurrentCarrierObj.CarrierName === "Lasership") {
            tmpCurrentCarrierObj.AccountNumber = tmpCurrentCarrierObj.WebServicesKey;
        }

        carrierService.addCarrier(tmpCurrentCarrierObj).then((response) => {
            // Check Auth URL Exist
            if (response && response.data.AuthUrl && (response.status == 200 || response.status == 204)) {
                this.setState({
                    carrierConnectionBtnLoader: false,
                    isAuthUrl: true
                });
                window.open(response.data.AuthUrl, "_blank", "width=800,height=750,top=200,left=560,resizable=yes");
            } else if (response && !response.data.AuthUrl && (response.status == 200 || response.status == 204)) {
                if(response && response.data.ShipEngineId) {
                    // Update Ship Engine Settings
                    tmpCurrentCarrierObj.ShipEngineId = response.data.ShipEngineId;
                    if(tmpCurrentCarrierObj.CarrierName == "UPS" || tmpCurrentCarrierObj.CarrierName == "FedEx" && tmpCurrentCarrierObj.Provider == "shipEngine") {
                        carrierService.updateCarrier(tmpCurrentCarrierObj);
                    }
                }
                GlobalService.notification('success', this.translate('New_carrier_Connection_Success'));
                this.props.onResetSelectionAndGridRefresh();
                setTimeout(() => {
                    this.props.closeCarrierConnection();
                }, 10);
            } else {
                GlobalService.notification('error', this.translate('Authorize_With_Failed'));
                this.setState({ carrierConnectionBtnLoader: false });
            }
        }).finally(() => {
            this.setState({ carrierConnectionBtnLoader: false });
        });
    }

    checkConnectionAuthorized = () => {

        this.setState({ carrierConnectionBtnLoader: true });
        let tmpCurrentCarrierObj = Object.assign({}, this.state.currentCarrierObj);
        let carrierObj = this.state.carrierOptions[this.state.selectedCarrierIndex];
        let facilityObj = this.facilityOption[this.state.selectedFacilityIndex];
        let customerObj = this.customerOptions[this.state.selectedCustomerIndex];

        tmpCurrentCarrierObj.CarrierName = carrierObj.Carrier;
        tmpCurrentCarrierObj.CarrierUri = carrierObj.Uri;
        tmpCurrentCarrierObj.Provider = this.state.selectedProviderIndex;
        tmpCurrentCarrierObj.SyncWithProvider = "shipEngine";

        if (facilityObj && this.state.selectedContentType == 'Warehouse Account') {
            tmpCurrentCarrierObj.FacilityId = facilityObj.id;
            tmpCurrentCarrierObj.FacilityUri = facilityObj.uri;
        } else {
            tmpCurrentCarrierObj.CustomerId = customerObj.id;
            tmpCurrentCarrierObj.CustomerUri = customerObj.uri;
        }

        carrierService.addCarrier(tmpCurrentCarrierObj).then((response) => {

            if(response && response.data.ShipEngineId) {
                // Update Ship Engine Settings
                tmpCurrentCarrierObj.ShipEngineId = response.data.ShipEngineId;
                carrierService.updateCarrier(tmpCurrentCarrierObj);
            }
            GlobalService.notification('success', this.translate('New_carrier_Connection_Success'));
            this.props.onResetSelectionAndGridRefresh();

            setTimeout(() => {
                this.props.closeCarrierConnection();
            }, 10);

        }).finally(() => {
            this.setState({
                carrierConnectionBtnLoader: false,
                isAuthUrl: false
            });
        });
    }

    handelPitneyBowesChangeEvent = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    addPitneyBowesAccout = () => {
        let providerAccountObj = {};
        if (this.state.selectedProviderIndex == "pitneyBowes") {
            providerAccountObj = {
                Username: this.state.pitneyBowesUsername,
                Password: this.state.pitneyBowesPassword,
                Provider: this.state.selectedProviderIndex,
            }
        } else if (this.state.selectedProviderIndex == "bukuShip") {
            providerAccountObj = {
                Username: this.state.providerUsername,
                OrganizationNumber: this.state.providerOrganizationNumber,
                ConnectToken: this.state.providerConnectToken,
                Provider: this.state.selectedProviderIndex,
            }
        }
        this.setState({
            carrierConnectionBtnLoader: true,
            pitneyBowesErrorMessage: ''
        });
        carrierService.createProviderAccount(providerAccountObj).then((response) => {
            if (response.status == 200 || response.status == 204 ) {
                this.moveNextStep();
                // if (this.state.selectedCarrier == "USPS" && this.state.selectedProviderIndex == "pitneyBowes") {
                //     this.addCarrier();
                // } else {
                //     this.moveNextStep();
                // }
            } else {
                if (
                    response &&
                    response.response.status == 403
                ) {
                    this.setState({
                        pitneyBowesErrorMessage: this.translate('Label_incorrect_pitney_bowes_account')
                    });
                }
            }
        }).finally(() => {
            this.setState({ carrierConnectionBtnLoader: false });
        });
    }

    onClosePitneyBowesModel = () => {
        this.setState({
            isOpenPitneyBowesModel: false
        });
    }

    onClickCreatePitneyAccount = () => {
        if (this.state.selectedProviderIndex == "pitneyBowes") {
            window.open('https://www.pbshippingmerchant.pitneybowes.com/home?developerID=95490479', '_blank');
        } else {
            window.open('https://www.bukuship.com/extensiv', '_blank');
        }
    }

    render() {

        let carrierAccountInformationSection = () => {
            switch (this.state.selectedCarrier) {
                case 'Endicia':
                    return <EndiciaAccountInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'DHL eCommerce':
                    return <DHLeCommerceAccountInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                        currentLoginLabel={this.state.currentLoginLabel}
                    />;
                case 'DHL Express':
                    return <DHLExpressAccountInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Australia Post':
                    return <AustraliaPostAccountInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'PitneyBowesStd':
                    return <PitneyBowesStandardInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Stamps.com':
                    return <StampsAccountInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'APC':
                    return <APCAccountInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Purolator':
                    return <PurolatorAccountInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                        currentLoginLabel={this.state.currentLoginLabel}
                    />;
                case 'DHL Express Australia':
                    return <DHLExpressAUAccountInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                        currentLoginLabel={this.state.currentLoginLabel}
                    />;
                case 'Firstmile':
                    return <FirstMileInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Globegistics':
                    return <GlobegisticsInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'RR Donnelley':
                    return <RrDonnelleyInfoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Canada Post':
                    return <CanadaPostComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'OnTrac':
                    return <OnTraceComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'SEKO':
                    return <SekoComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Amazon Buy':
                    return <AmazonComponent
                        selector={this.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Canpar':
                    return <CanparAccountInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'USPS':
                    switch (this.state.selectedProviderIndex) {
                        case 'pitneyBowes':
                            return <PitneyBowesExpeditedInfoComponent
                                selector={this.selector}
                                currentCarrierObj={this.state.currentCarrierObj}
                                handleCarrierObjChange={this.handleCarrierObjChange}
                            />;
                        case "Stamps.com":
                            return <StampsAccountInfoComponent
                                selector={this.selector}
                                currentCarrierObj={this.state.currentCarrierObj}
                                handleCarrierObjChange={this.handleCarrierObjChange}
                            />
                        case "Endicia":
                            return <EndiciaAccountInfoComponent
                                selector={this.selector}
                                currentCarrierObj={this.state.currentCarrierObj}
                                handleCarrierObjChange={this.handleCarrierObjChange}
                            />
                        case "bukuShip":
                            return <USPSBukuShipAccountInfoComponent
                                selector={this.selector}
                                currentCarrierObj={this.state.currentCarrierObj}
                                handleCarrierObjChange={this.handleCarrierObjChange}
                                optionsState={this.state.optionsState}
                                optionsCountry={this.optionsCountry}
                            />;
                        default:
                            return <div className="default-section">
                                We are currently building out the connection user interface for this carrier and expect to complete it soon. If you need this carrier set up immediately, please contact your Customer Success Manager to begin the process.
                            </div>;
                    }
                case 'Landmark':
                    return <LandmarkAccountInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'ePost Global':
                    return <EpostGlobalAccountInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'PARCLL':
                    return <ParcllAccountInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Pitney Bowes Cross-Border':
                    return <PitneyBowesCrossBorderInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Stallion Express':
                    return <StallionAccountInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'UPS Mail Innovations':
                        return <UpsmiAccountInfoComponent
                            selector={this.state.selector}
                            currentCarrierObj={this.state.currentCarrierObj}
                            handleCarrierObjChange={this.handleCarrierObjChange}
                        />;
                case 'FedEx SmartPost':
                    return <FedExSmartPostAccountInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                        optionsState={this.state.optionsState}
                        optionsCountry={this.optionsCountry}
                    />;
                case 'UPS SurePost':
                    return <UpsSurePostAccountInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Passport':
                    return <PassportInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Sendle':
                    return <SendleInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Tusk Logistics':
                    return <TuskInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Flexport':
                    return <FlexportInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                case 'Lasership':
                    return <LasershipInfoComponent
                        selector={this.state.selector}
                        currentCarrierObj={this.state.currentCarrierObj}
                        handleCarrierObjChange={this.handleCarrierObjChange}
                    />;
                default:
                    return <div className="default-section">
                        We are currently building out the connection user interface for this carrier and expect to complete it soon. If you need this carrier set up immediately, please contact your Customer Success Manager to begin the process.
                    </div>;
            }
        };
        return (

            <WmsModel
                id={'newCarrierConnection'}
                title={
                    this.state.isOpenPitneyBowesModel ? this.translate('Label_Add_Pitney_Bowes_Carrier_Model').replace(new RegExp('{' + 'provider' + '}', 'gi'), this.selectedProviderObj ? this.selectedProviderObj.DisplayName : '') :
                    (this.props.sharedCarrierConnectionFlow == 'create' ? this.translate('Title_New_carrier_Connection_Model') :
                        (this.state.selectedCarrier == 'FedEx' ? this.translate('Title_carrier_EDIT_FEDEX_ACCOUNT') : this.translate('Title_carrier_EDIT_UPS_ACCOUNT')))
                }
                width={800}
                height={750}
                class='new-carrier-connection-wrapper'
                onCloseFunc={this.props.closeCarrierConnection}
                isOpen={this.props.isOpenCarrierConnectionModel}
                customClass='wms-model-new-ui-wrapper'>
                <div className="model-content-wrapper">
                    {/* First step */}
                    {this.props.sharedCarrierConnectionFlow == 'edit' && (
                        <div className="connection-step-block">
                            <div className="image-section">
                                <img src={'assets/images/' + (this.state.selectedCarrier == 'FedEx' ? 'FedEx-logo.png' : 'UPS-logo.png')} />
                            </div>
                            {this.state.selectedCarrier == 'FedEx' &&
                                <FedexEditComponent
                                    selector={this.selector}
                                    currentCarrierObj={this.state.currentCarrierObj}
                                    handleCarrierObjChange={this.handleCarrierObjChange} />
                            }
                            {this.state.selectedCarrier == 'UPS' &&
                                <UpsEditComponent
                                    selector={this.selector}
                                    currentCarrierObj={this.state.currentCarrierObj}
                                    handleCarrierObjChange={this.handleCarrierObjChange} />
                            }
                        </div>
                    )}
                    {this.props.sharedCarrierConnectionFlow == 'create' && this.state.currentStep == 1 && !this.state.isOpenPitneyBowesModel && (
                        <div className={"wms-carrier-connection " + this.carrierElementClass}>
                            <WmsCollapse
                                id={'carrierSelectConnection'}
                                headerLabel={this.translate("Label_Carrier_Select")}
                                showCollapseText={false}
                                showCollapseIcon={false} >

                                <WmsDropdown
                                    id="selectedContentType"
                                    name="selectedContentType"
                                    label={this.translate('Label_Connection_Type')}
                                    wmsSelector={this.selector + "contentType"}
                                    onChangeFunc={this.handleChangeEvent}
                                    value={this.state.selectedContentType}
                                    options={[{
                                        'Text': 'Warehouse Account',
                                        'Value': 'Warehouse Account',
                                    }, {
                                        'Text': 'Customer Account',
                                        'Value': 'Customer Account',
                                    }]}
                                    valueField='Value'
                                    textField='Text'
                                    parentClassName='form_group'
                                    blankFirstOption={true}
                                    extraWrapperClass={"new-connection-first-element"} />
                                {/* <div className={this.state.selectedContentType == 'Customer Account' ? 'disabled-facility-opt':''}> */}
                                {this.state.selectedContentType == 'Customer Account' ? (
                                    <WmsDropdown
                                        id="selectedCustomerIndex"
                                        name="selectedCustomerIndex"
                                        label={this.translate('FindOrders_Lbl_Customer')}
                                        wmsSelector={this.selector + "customer"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={this.state.selectedCustomerIndex}
                                        options={this.customerOptions}
                                        valueField='id'
                                        textField='name'
                                        parentClassName='form_group'
                                        applyIndex={true}
                                        blankFirstOption={true}
                                        extraWrapperClass={"new-connection-second-element"} />
                                ) : (
                                    <WmsDropdown
                                        id="selectedFacilityIndex"
                                        name="selectedFacilityIndex"
                                        label={this.translate('Label_Warehouse_Text')}
                                        wmsSelector={this.selector + "facility"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={this.state.selectedFacilityIndex}
                                        options={this.facilityOption}
                                        valueField='id'
                                        textField='name'
                                        parentClassName='form_group'
                                        applyIndex={true}
                                        blankFirstOption={true}
                                        extraWrapperClass={"new-connection-second-element"} />
                                )}
                                {/* </div> */}

                                <WmsDropdown
                                    id="selectedCarrierIndex"
                                    name="selectedCarrierIndex"
                                    label={this.translate('Label_Carrier')}
                                    wmsSelector={this.selector + "carrier"}
                                    onChangeFunc={this.handleChangeEvent}
                                    value={this.state.selectedCarrierIndex}
                                    options={this.state.carrierOptions}
                                    valueField='Carrier'
                                    textField='DisplayName'
                                    parentClassName='form_group'
                                    applyIndex={true}
                                    blankFirstOption={true}
                                    extraWrapperClass={"new-connection-third-element"} />

                                {this.isEnableProvider &&
                                    <WmsDropdown
                                        id="selectedProviderIndex"
                                        name="selectedProviderIndex"
                                        label={this.translate('Label_Provider')}
                                        wmsSelector={this.selector + "service"}
                                        onChangeFunc={this.handleChangeEvent}
                                        value={this.state.selectedProviderIndex}
                                        options={this.state.providerOptions}
                                        valueField='Code'
                                        textField='DisplayName'
                                        parentClassName='form_group'
                                        applyIndex={false}
                                        blankFirstOption={true}
                                        disabled={this.state.selectedCarrierIndex == '' ? true : false}
                                        extraWrapperClass={"new-connection-third-element"} />
                                }

                            </WmsCollapse>
                        </div>
                    )}

                    { this.state.currentStep == 1 && this.state.isOpenPitneyBowesModel && (
                        <div className="pitney-bowes-connection-step-block">
                            <div className="image-section">
                                { this.state.selectedProviderIndex && this.state.selectedProviderIndex == "pitneyBowes" ? (
                                    <img src={'assets/images/pitney-bowes-logo.png'} />
                                ) : this.state.selectedProviderIndex && this.state.selectedProviderIndex == "bukuShip" && (
                                    <img src={'assets/images/buku-ship.png'} />
                                ) }
                            </div>
                            <WmsCollapse
                                id={'pitneyBowesConnectionLoginDetail'}
                                headerLabel={this.translate("Label_Account_Login")}
                                showCollapseText={false}
                                showCollapseIcon={false} >
                                    <div className="title">
                                        <div className="wms-sprite fa fa-exclamation-triangle"></div>
                                        { this.state.selectedProviderIndex && this.state.selectedProviderIndex == "pitneyBowes" ? (
                                            <span>{this.translate("Label_pitney_bowes_account_connect_new")}</span>
                                        ) : this.state.selectedProviderIndex && this.state.selectedProviderIndex == "bukuShip" && (
                                            <span>{this.translate("Label_pitney_bowes_account_connect").replace(new RegExp('{' + 'carrier_account' + '}', 'gi'), this.state.carrierOptions[this.state.selectedCarrierIndex] ? this.state.carrierOptions[this.state.selectedCarrierIndex].Carrier : '').replace(new RegExp('{' + 'provider' + '}', 'gi'), this.selectedProviderObj ? this.selectedProviderObj.DisplayName : '')}</span>
                                        ) }
                                    </div>
                                    {this.state.pitneyBowesErrorMessage != '' &&
                                        <div className="pitneyBoweserror">
                                            <div className="wms-sprite fa fa-exclamation-triangle"></div>
                                            <span>{ this.state.pitneyBowesErrorMessage }</span>
                                        </div>
                                    }

                                    {
                                        this.state.selectedProviderIndex == "pitneyBowes" ?
                                            <div className="pitney-bowes-middel-wrapper">
                                                <div className='pitney-bowes-credentials'>
                                                    <WmsInput
                                                        id='pitney-bowes-username'
                                                        inputName='pitneyBowesUsername'
                                                        autoComplete='off'
                                                        wmsSelector={this.selector + 'pitneyBowesUsername'}
                                                        onChangeFunc={this.handelPitneyBowesChangeEvent}
                                                        label={this.translate('Label_Username')}
                                                        value={this.state.pitneyBowesUsername}
                                                    />
                                                    <WmsInput
                                                        inputType='password'
                                                        id='pitney-bowes-password'
                                                        inputName='pitneyBowesPassword'
                                                        autoComplete="new-password"
                                                        wmsSelector={this.selector + 'pitneyBowesPassword'}
                                                        onChangeFunc={this.handelPitneyBowesChangeEvent}
                                                        label={this.translate('Login_Lbl_Password')}
                                                        value={this.state.pitneyBowesPassword}
                                                    />
                                                </div>
                                                <div className="pitney-bowes-seprator-wrapper">
                                                    <div className="pitney-bowes-seprator-word">OR</div>
                                                </div>
                                                <div className='pitney-bowes-create-account-btn'>
                                                    <WmsButton
                                                        label={this.translate('Label_new_pitney_bowes_account').replace(new RegExp('{' + 'provider' + '}', 'gi'), this.selectedProviderObj ? this.selectedProviderObj.DisplayName : '')}
                                                        buttonType='green'
                                                        wmsSelector={this.selector + 'pitneyBowesCreateAccount'}
                                                        onClickFunc={this.onClickCreatePitneyAccount}
                                                        icon='fa-plus' />
                                                </div>
                                            </div>
                                        :
                                        <div className="pitney-bowes-middel-wrapper">
                                            <div className='pitney-bowes-credentials'>
                                                <WmsInput
                                                    id='providerUsername'
                                                    inputName='providerUsername'
                                                    autoComplete='off'
                                                    wmsSelector={this.selector + 'providerUsername'}
                                                    onChangeFunc={this.handelPitneyBowesChangeEvent}
                                                    label={this.translate('Label_Username')}
                                                    value={this.state.providerUsername}
                                                />
                                                <WmsInput
                                                    inputType='text'
                                                    id='providerOrganizationNumber'
                                                    inputName='providerOrganizationNumber'
                                                    wmsSelector={this.selector + 'providerOrganizationNumber'}
                                                    onChangeFunc={this.handelPitneyBowesChangeEvent}
                                                    label={this.translate('Lbl_Organization_Number')}
                                                    value={this.state.providerOrganizationNumber}
                                                />
                                                <WmsInput
                                                    inputType='text'
                                                    id='providerConnectToken'
                                                    inputName='providerConnectToken'
                                                    wmsSelector={this.selector + 'providerConnectToken'}
                                                    onChangeFunc={this.handelPitneyBowesChangeEvent}
                                                    label={this.translate('Lbl_Connect_Token')}
                                                    value={this.state.providerConnectToken}
                                                />
                                            </div>
                                            <div className="pitney-bowes-seprator-wrapper">
                                                <div className="pitney-bowes-seprator-word">OR</div>
                                            </div>
                                            <div className='pitney-bowes-create-account-btn'>
                                                <WmsButton
                                                    label={this.translate('Label_new_pitney_bowes_account').replace(new RegExp('{' + 'provider' + '}', 'gi'), this.selectedProviderObj ? this.selectedProviderObj.DisplayName : '')}
                                                    buttonType='green'
                                                    wmsSelector={this.selector + 'pitneyBowesCreateAccount'}
                                                    onClickFunc={this.onClickCreatePitneyAccount}
                                                    icon='fa-plus' />
                                            </div>
                                        </div>
                                    }
                            </WmsCollapse>
                        </div>
                    ) }

                    {/* Second step */}
                    {this.state.currentStep == 2 && (
                        <div className="connection-step-block">
                            <div className={"image-section " + (this.state.selectedCarrier == 'Amazon Buy' ? 'img-amazon' : '')}>
                                <img src={'assets/images/' + this.state.currentCarrierImgUrl} />
                            </div>

                            {this.state.selectedCarrier == 'FedEx' || (this.state.selectedCarrier == 'UPS' && this.state.selectedProviderIndex != "shipEngine") ? (
                                <WmsCollapse
                                    id={'carrierAgreementSection'}
                                    headerLabel={this.translate("Label_Agreement")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >
                                    {this.state.selectedCarrier == 'FedEx' ?
                                        <FedexEulaAgreementComponent /> :
                                        <UpsEulaAgreementComponent />
                                    }
                                    <WmsCheckBox
                                        id={'agreeToEula'}
                                        name={"AgreeToEula"}
                                        wmsSelector={this.selector + 'agreeToEula'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Agree_Terms')}
                                        checked={this.state.currentCarrierObj.AgreeToEula == true}
                                        isRounded={true}
                                        value={this.state.currentCarrierObj.AgreeToEula}
                                    />

                                </WmsCollapse>
                            ) : this.state.selectedCarrier == 'UPS' && this.state.selectedProviderIndex == "shipEngine" ? (
                                <WmsCollapse
                                    id={'carrierAccountInformation'}
                                    headerLabel={this.translate("Label_Account_Information")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >
                                    <WmsInput
                                        id='nickname'
                                        inputName='Nickname'
                                        wmsSelector={this.selector + 'nickname'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Account_Nick_Name')}
                                        extraWrapperClass="wms-input-city-box"
                                        required={true}
                                        value={this.state.currentCarrierObj.Nickname} />

                                    <WmsInput
                                        id='accountNumber'
                                        inputName='AccountNumber'
                                        wmsSelector={this.selector + 'accountNumber'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Account_Number')}
                                        extraWrapperClass="wms-input-city-box"
                                        required={true}
                                        value={this.state.currentCarrierObj.AccountNumber} />

                                    <WmsDropdown
                                        id="accountCountryCode"
                                        name="AccountCountryCode"
                                        label={this.translate('Label_Account_Country')}
                                        wmsSelector={this.selector + "accountCountryCode"}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        value={this.state.currentCarrierObj.AccountCountryCode}
                                        options={this.optionsCountry}
                                        valueField='CountryCode'
                                        textField='Name'
                                        parentClassName='form_group'
                                        required={true}
                                        extraWrapperClass="wms-dropdown-account-country-box"
                                    />

                                    <WmsInput
                                        id='accountPostalCode'
                                        inputName='AccountPostalCode'
                                        wmsSelector={this.selector + 'accountzip'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Account_Zip')}
                                        required={true}
                                        value={this.state.currentCarrierObj.AccountPostalCode}
                                        extraWrapperClass="wms-input-account-zip-box"
                                    />
                                </WmsCollapse>
                            ) : (
                                <div>
                                    {carrierAccountInformationSection()}
                                </div>
                            )}
                        </div>
                    )}

                    {/* Third step */}
                    {this.state.currentStep == 3 && (
                        <div className="connection-step-block">
                            <div className="image-section">
                                <img src={'assets/images/' + this.state.currentCarrierImgUrl} />
                            </div>

                            {(this.state.selectedCarrier == 'FedEx' || (this.state.selectedCarrier == 'UPS' && this.state.selectedProviderIndex != 'shipEngine')) && (
                                <WmsCollapse
                                    id={'carrierCompoanyInformation'}
                                    headerLabel={this.translate("Label_Company_Information")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >

                                    <div className='display-inline'>
                                        <WmsInput
                                            id='firstName'
                                            inputName='FirstName'
                                            wmsSelector={this.selector + 'firstName'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Warehouse_First_Name')}
                                            extraWrapperClass={this.state.selectedCarrier == 'UPS' ? 'ups-first-name wms-input-names-box' : 'wms-input-names-box'}
                                            value={this.state.currentCarrierObj.FirstName} />

                                        <WmsInput
                                            id='lastName'
                                            inputName='LastName'
                                            wmsSelector={this.selector + 'lastName'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Warehouse_Last_Name')}
                                            extraWrapperClass="wms-input-title-box"
                                            value={this.state.currentCarrierObj.LastName} />
                                    </div>

                                    {this.state.selectedCarrier == 'UPS' &&
                                        <div className='display-inline ups-section'>
                                            <WmsInput
                                                id='contactTitle'
                                                inputName='ContactTitle'
                                                wmsSelector={this.selector + 'contactTitle'}
                                                onChangeFunc={this.handleCarrierObjChange}
                                                label={this.translate('Label_Title')}
                                                extraWrapperClass="wms-input-title-box"
                                                value={this.state.currentCarrierObj.ContactTitle} />
                                        </div>
                                    }

                                    <WmsInput
                                        id='company'
                                        inputName='Company'
                                        wmsSelector={this.selector + 'company'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Company_Name')}
                                        extraWrapperClass={this.state.selectedCarrier == 'UPS' ? 'ups-company-name wms-input-names-box' : 'wms-input-names-box'}
                                        value={this.state.currentCarrierObj.Company}
                                    />

                                    <WmsInput
                                        id='phone'
                                        inputName='Phone'
                                        wmsSelector={this.selector + 'phoneNumber'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Warehouse_PhoneNumber')}
                                        extraWrapperClass="wms-input-title-box"
                                        value={this.state.currentCarrierObj.Phone}
                                        valueType="number"
                                    />

                                    <WmsInput
                                        id='email'
                                        inputName='Email'
                                        wmsSelector={this.selector + 'emailAddress'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Email_Address')}
                                        extraWrapperClass="wms-input-address-box"
                                        value={this.state.currentCarrierObj.Email}
                                    />

                                    <WmsInput
                                        id='address1'
                                        inputName='Address1'
                                        wmsSelector={this.selector + 'address1'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Address_1')}
                                        extraWrapperClass="wms-input-address-half-box"
                                        value={this.state.currentCarrierObj.Address1}
                                    />
                                    <WmsInput
                                        id='address2'
                                        inputName='Address2'
                                        wmsSelector={this.selector + 'address2'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Address_2')}
                                        extraWrapperClass="wms-input-address-half-box"
                                        value={this.state.currentCarrierObj.Address2}
                                    />

                                    <WmsInput
                                        id='city'
                                        inputName='City'
                                        wmsSelector={this.selector + 'city'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Warehouse_City')}
                                        extraWrapperClass="wms-input-city-box"
                                        value={this.state.currentCarrierObj.City}
                                    />

                                    <WmsDropdown
                                        id="state"
                                        name="State"
                                        label={this.translate('Label_Costomer_State_Province')}
                                        wmsSelector={this.selector + "state'"}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        disabled={this.state.currentCarrierObj.CountryCode ? false : true}
                                        value={this.state.currentCarrierObj.State}
                                        options={this.state.optionsState}
                                        valueField='RegionCode'
                                        textField='Name'
                                        parentClassName='form_group'
                                        extraWrapperClass="wms-dropdown-state-box"
                                    />

                                    <WmsInput
                                        id='postalCode'
                                        inputName='PostalCode'
                                        wmsSelector={this.selector + 'zip'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Account_Zip_Postal_code')}
                                        extraWrapperClass="wms-input-zip-postal-code-box"
                                        value={this.state.currentCarrierObj.PostalCode}
                                    />

                                    <WmsDropdown
                                        id="countryCode"
                                        name="CountryCode"
                                        label={this.translate('Label_Country')}
                                        wmsSelector={this.selector + "country"}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        value={this.state.currentCarrierObj.CountryCode}
                                        options={this.optionsCountry}
                                        valueField='CountryCode'
                                        textField='Name'
                                        parentClassName='form_group'
                                        extraWrapperClass="wms-dropdown-country-box" />


                                </WmsCollapse>
                            )}

                            {(this.state.selectedCarrier == 'FedEx' || (this.state.selectedCarrier == 'UPS' && this.state.selectedProviderIndex != 'shipEngine')) && (
                                <WmsCollapse
                                    id={'carrierAccountInformation'}
                                    headerLabel={this.translate("Label_Account_Information")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >
                                    <WmsInput
                                        id='nickname'
                                        inputName='Nickname'
                                        wmsSelector={this.selector + 'nickname'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Account_Nick_Name')}
                                        extraWrapperClass="wms-input-city-box"
                                        value={this.state.currentCarrierObj.Nickname} />
                                    <WmsInput
                                        id='accountNumber'
                                        inputName='AccountNumber'
                                        wmsSelector={this.selector + 'accountNumber'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Account_Number')}
                                        extraWrapperClass="wms-input-city-box"
                                        required={true}
                                        value={this.state.currentCarrierObj.AccountNumber} />
                                    {this.state.selectedCarrier == 'UPS' && (
                                        <div>
                                            <WmsDropdown
                                                id="accountCountryCode"
                                                name="AccountCountryCode"
                                                label={this.translate('Label_Account_Country')}
                                                wmsSelector={this.selector + "accountCountryCode"}
                                                onChangeFunc={this.handleCarrierObjChange}
                                                value={this.state.currentCarrierObj.AccountCountryCode}
                                                options={this.optionsCountry}
                                                valueField='CountryCode'
                                                textField='Name'
                                                parentClassName='form_group'
                                                extraWrapperClass="wms-dropdown-account-country-box"
                                            />

                                            <WmsInput
                                                id='accountPostalCode'
                                                inputName='AccountPostalCode'
                                                wmsSelector={this.selector + 'accountzip'}
                                                onChangeFunc={this.handleCarrierObjChange}
                                                label={this.translate('Label_Account_Zip')}
                                                value={this.state.currentCarrierObj.AccountPostalCode}
                                                extraWrapperClass="wms-input-account-zip-box"
                                            />
                                        </div>
                                    )}
                                    {this.state.selectedCarrier != 'FedEx' && (
                                        <WmsCheckBox
                                            id={'invoiceReceivedInPast90Days'}
                                            name={"InvoiceReceivedInPast90Days"}
                                            wmsSelector={this.selector + 'chkInvoice'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Invoice')}
                                            checked={this.state.currentCarrierObj.InvoiceReceivedInPast90Days == true}
                                            isRounded={true}
                                            value={this.state.currentCarrierObj.InvoiceReceivedInPast90Days}
                                        />
                                    )}
                                </WmsCollapse>
                            )}

                            {this.state.selectedCarrier == 'FedEx' && (
                                <WmsCollapse
                                    id={'carrierSettings'}
                                    headerLabel={this.translate("Label_Settings")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >

                                    <div className="dropdown_section">
                                        <WmsDropdown
                                            id="pickupType"
                                            name="PickupType"
                                            label={this.translate('Label_Pickup_Type')}
                                            wmsSelector={this.selector + "PickupType"}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            value={this.state.currentCarrierObj.PickupType}
                                            options={this.state.pickupTypeOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />

                                        <WmsDropdown
                                            id="smartPostHub"
                                            name="SmartPostHub"
                                            label={this.translate('Label_Smart_Post_Hub')}
                                            wmsSelector={this.selector + "SmartPostHub"}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            value={this.state.currentCarrierObj.SmartPostHub}
                                            options={this.state.smartPostHubOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />
                                        <WmsDropdown
                                            id="smartPostEndorsement"
                                            name="SmartPostEndorsement"
                                            label={this.translate('Label_Smart_Post_Endorsement')}
                                            wmsSelector={this.selector + "SmartPostEndorsement"}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            value={this.state.currentCarrierObj.SmartPostEndorsement}
                                            options={this.state.smartPostEndorsementOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />
                                    </div>

                                    <div className="radio_section">
                                        <WmsCheckBox
                                            id={'isPrimaryAccount'}
                                            name={"IsPrimaryAccount"}
                                            wmsSelector={this.selector + 'PrimaryAccount'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Primary_Account')}
                                            checked={this.state.currentCarrierObj.IsPrimaryAccount == true}
                                            isRounded={true}
                                        />
                                    </div>
                                </WmsCollapse>
                            )}

                            {this.state.selectedCarrier == 'UPS' && this.state.selectedProviderIndex == 'shipEngine' && (
                                <WmsCollapse
                                    id={'carrierSettings'}
                                    headerLabel={this.translate("Label_Settings")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >

                                    <div className="dropdown_section">
                                        <WmsDropdown
                                            id="pickupType"
                                            name="PickupType"
                                            label={this.translate('Label_Pickup_Type')}
                                            wmsSelector={this.selector + "PickupType"}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            value={this.state.currentCarrierObj.PickupType}
                                            options={this.state.pickupTypeOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />
                                    </div>

                                    <div className="mail_section">
                                        <WmsDropdown
                                            id="mailInnovationsEndorsement"
                                            name="MailInnovationsEndorsement"
                                            label={this.translate('Label_Mail_Innovations_Endorsement')}
                                            wmsSelector={this.selector + "MailInnovationsEndorsement"}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            value={this.state.currentCarrierObj.MailInnovationsEndorsement}
                                            options={this.state.mailInnovationsEndorsementOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />

                                        <WmsInput
                                            id='mailInnovationsCostCenter'
                                            inputName='MailInnovationsCostCenter'
                                            wmsSelector={this.selector + 'MailInnovationsCostCenter'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Mail_Innovations_Cost_Center')}
                                            value={this.state.currentCarrierObj.MailInnovationsCostCenter}
                                        />
                                    </div>

                                    <div className="mail_section">
                                        <WmsInput
                                            id='mailInnovationsCustomerId'
                                            inputName='MailInnovationsCustomerId'
                                            wmsSelector={this.selector + 'MailInnovationsCustomerId'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Mail_Innovations_Customer_Id')}
                                            value={this.state.currentCarrierObj.MailInnovationsCustomerId}
                                            regularExp={/(^[0-9]+$|^$)/}
                                            maxLength={6}
                                        />

                                        <WmsInput
                                            id='mailInnovationsCustomerGuid'
                                            inputName='MailInnovationsCustomerGuid'
                                            wmsSelector={this.selector + 'MailInnovationsCustomerGuid'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Mail_Innovations_Customer_Guid')}
                                            value={this.state.currentCarrierObj.MailInnovationsCustomerGuid}
                                        />
                                    </div>

                                    <div className="radio_section">
                                        <WmsCheckBox
                                            id={'useOrderNumberOnMailInnovationsLabels'}
                                            name={"UseOrderNumberOnMailInnovationsLabels"}
                                            wmsSelector={this.selector + 'UseOrderNumberOnMailInnovationsLabels'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Order_Number_On_Mail_Innovations_Labels')}
                                            checked={this.state.currentCarrierObj.UseOrderNumberOnMailInnovationsLabels == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'useConsolidationServices'}
                                            name={"UseConsolidationServices"}
                                            wmsSelector={this.selector + 'UseConsolidationServices'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Consolidation_Services')}
                                            checked={this.state.currentCarrierObj.UseConsolidationServices == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'useNegotiatedRates'}
                                            name={"UseNegotiatedRates"}
                                            wmsSelector={this.selector + 'UseNegotiatedRates'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Negotiated_Rates')}
                                            checked={this.state.currentCarrierObj.UseNegotiatedRates == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'useCarbonNeutralShippingProgram'}
                                            name={"UseCarbonNeutralShippingProgram"}
                                            wmsSelector={this.selector + 'UseCarbonNeutralShippingProgram'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Carbon_Neutral_Shipping_Program')}
                                            checked={this.state.currentCarrierObj.UseCarbonNeutralShippingProgram == true}
                                            isRounded={true}
                                        />


                                        <WmsCheckBox
                                            id={'useGroundFreightPricing'}
                                            name={"UseGroundFreightPricing"}
                                            wmsSelector={this.selector + 'UseGroundFreightPricing'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Ground_Freight_Pricing')}
                                            checked={this.state.currentCarrierObj.UseGroundFreightPricing == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'isPrimaryAccount'}
                                            name={"IsPrimaryAccount"}
                                            wmsSelector={this.selector + 'PrimaryAccount'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Primary_Account')}
                                            checked={this.state.currentCarrierObj.IsPrimaryAccount == true}
                                            isRounded={true}
                                        />
                                    </div>
                                </WmsCollapse>
                            )}
                        </div>
                    )}

                    {/* Fourth step */}
                    {this.state.currentStep == 4 && (
                        <div className="connection-step-block">
                            <div className="image-section">
                                <img src={'assets/images/' + this.state.currentCarrierImgUrl} />
                            </div>
                            <WmsCollapse
                                id={'carrierInvoiceInformation'}
                                headerLabel={this.translate("Label_Invoice_Information")}
                                showCollapseText={false}
                                showCollapseIcon={false} >
                                <WmsDateTimePicker
                                    id='invoiceDate'
                                    name='InvoiceDate'
                                    label={this.translate('Label_Invoice_Date')}
                                    onChangeFunc={this.handleCarrierObjChange}
                                    value={this.state.currentCarrierObj.InvoiceDate} />
                                <div className="wms-invoice-block">
                                    <WmsInput
                                        id='invoiceNumber'
                                        inputName='InvoiceNumber'
                                        wmsSelector={this.selector + 'invoiceNumber'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Invoice_Number')}
                                        value={this.state.currentCarrierObj.InvoiceNumber}
                                        valueType="alphanumeric" />

                                    <WmsInput
                                        id='controlId'
                                        inputName='ControlId'
                                        wmsSelector={this.selector + 'controlId'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Control_Id')}
                                        value={this.state.currentCarrierObj.ControlId} />
                                    <WmsInput
                                        id='invoiceAmount'
                                        inputName='InvoiceAmount'
                                        wmsSelector={this.selector + 'invoiceAmount'}
                                        onChangeFunc={this.handleCarrierObjChange}
                                        label={this.translate('Label_Invoice_Total')}
                                        value={this.state.currentCarrierObj.InvoiceAmount}
                                        valueType="number"
                                        regularExp={/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/} />
                                </div>
                            </WmsCollapse>

                            {this.state.selectedCarrier == 'UPS' && (
                                <WmsCollapse
                                    id={'carrierSettings'}
                                    headerLabel={this.translate("Label_Settings")}
                                    showCollapseText={false}
                                    showCollapseIcon={false} >

                                    <div className="dropdown_section">
                                        <WmsDropdown
                                            id="pickupType"
                                            name="PickupType"
                                            label={this.translate('Label_Pickup_Type')}
                                            wmsSelector={this.selector + "PickupType"}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            value={this.state.currentCarrierObj.PickupType}
                                            options={this.state.pickupTypeOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />
                                    </div>

                                    <div className="mail_section">
                                        <WmsDropdown
                                            id="mailInnovationsEndorsement"
                                            name="MailInnovationsEndorsement"
                                            label={this.translate('Label_Mail_Innovations_Endorsement')}
                                            wmsSelector={this.selector + "MailInnovationsEndorsement"}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            value={this.state.currentCarrierObj.MailInnovationsEndorsement}
                                            options={this.state.mailInnovationsEndorsementOptions}
                                            valueField='value'
                                            textField='name'
                                            blankFirstOption={true}
                                        />

                                        <WmsInput
                                            id='mailInnovationsCostCenter'
                                            inputName='MailInnovationsCostCenter'
                                            wmsSelector={this.selector + 'MailInnovationsCostCenter'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Mail_Innovations_Cost_Center')}
                                            value={this.state.currentCarrierObj.MailInnovationsCostCenter}
                                        />
                                    </div>

                                    <div className="mail_section">
                                        <WmsInput
                                            id='mailInnovationsCustomerId'
                                            inputName='MailInnovationsCustomerId'
                                            wmsSelector={this.selector + 'MailInnovationsCustomerId'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Mail_Innovations_Customer_Id')}
                                            value={this.state.currentCarrierObj.MailInnovationsCustomerId}
                                            regularExp={/(^[0-9]+$|^$)/}
                                            maxLength={6}
                                        />

                                        <WmsInput
                                            id='mailInnovationsCustomerGuid'
                                            inputName='MailInnovationsCustomerGuid'
                                            wmsSelector={this.selector + 'MailInnovationsCustomerGuid'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Mail_Innovations_Customer_Guid')}
                                            value={this.state.currentCarrierObj.MailInnovationsCustomerGuid}
                                        />
                                    </div>

                                    <div className="radio_section">
                                        <WmsCheckBox
                                            id={'useOrderNumberOnMailInnovationsLabels'}
                                            name={"UseOrderNumberOnMailInnovationsLabels"}
                                            wmsSelector={this.selector + 'UseOrderNumberOnMailInnovationsLabels'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Order_Number_On_Mail_Innovations_Labels')}
                                            checked={this.state.currentCarrierObj.UseOrderNumberOnMailInnovationsLabels == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'useConsolidationServices'}
                                            name={"UseConsolidationServices"}
                                            wmsSelector={this.selector + 'UseConsolidationServices'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Consolidation_Services')}
                                            checked={this.state.currentCarrierObj.UseConsolidationServices == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'useNegotiatedRates'}
                                            name={"UseNegotiatedRates"}
                                            wmsSelector={this.selector + 'UseNegotiatedRates'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Negotiated_Rates')}
                                            checked={this.state.currentCarrierObj.UseNegotiatedRates == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'useCarbonNeutralShippingProgram'}
                                            name={"UseCarbonNeutralShippingProgram"}
                                            wmsSelector={this.selector + 'UseCarbonNeutralShippingProgram'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Carbon_Neutral_Shipping_Program')}
                                            checked={this.state.currentCarrierObj.UseCarbonNeutralShippingProgram == true}
                                            isRounded={true}
                                        />


                                        <WmsCheckBox
                                            id={'useGroundFreightPricing'}
                                            name={"UseGroundFreightPricing"}
                                            wmsSelector={this.selector + 'UseGroundFreightPricing'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Use_Ground_Freight_Pricing')}
                                            checked={this.state.currentCarrierObj.UseGroundFreightPricing == true}
                                            isRounded={true}
                                        />

                                        <WmsCheckBox
                                            id={'isPrimaryAccount'}
                                            name={"IsPrimaryAccount"}
                                            wmsSelector={this.selector + 'PrimaryAccount'}
                                            onChangeFunc={this.handleCarrierObjChange}
                                            label={this.translate('Label_Primary_Account')}
                                            checked={this.state.currentCarrierObj.IsPrimaryAccount == true}
                                            isRounded={true}
                                        />
                                    </div>
                                </WmsCollapse>
                            )}
                        </div>
                    )}
                </div>

                <div className="footer-btn-holder">

                    <WmsButton
                        label={this.translate('Label_Close')}
                        buttonType='orange'
                        wmsSelector={this.selector + 'exitNewConnection'}
                        onClickFunc={this.props.closeCarrierConnection}
                        icon='fa-times-circle-o' />
                    {this.props.sharedCarrierConnectionFlow == 'create' && (
                        <WmsButton
                            label={this.translate('Label_Previous')}
                            wmsSelector={this.selector + 'previous'}
                            onClickFunc={this.movePreviousStep}
                            icon='fa-caret-left'
                            disabled={this.state.currentStep == 1 && !this.state.isOpenPitneyBowesModel} />
                    )}
                    {!this.state.isAuthUrl && this.props.sharedCarrierConnectionFlow == 'create' && (
                        <WmsButton
                            label={
                                ( this.state.isOpenPitneyBowesModel ? this.translate('Label_Carrier_Account_Login') : (this.state.totalSteps == this.state.currentStep) ?
                                    this.translate('Label_Authorize_With') + this.state.selectedCarrier :
                                    this.translate('Label_Next')
                                )
                            }
                            wmsSelector={this.selector + 'next'}
                            onClickFunc={
                                ( this.state.isOpenPitneyBowesModel ? this.addPitneyBowesAccout : (this.state.totalSteps == this.state.currentStep) ?
                                    this.addCarrier :
                                    this.moveNextStep
                                )
                            }
                            parentClassName={this.state.currentStep == 2 && this.state.selectedCarrier == "PitneyBowesStd" && this.state.selectedProviderIndex == "pitneyBowes"}
                            loading={this.state.carrierConnectionBtnLoader}
                            disabled={
                                (
                                    this.state.currentStep == 1 && !this.state.isOpenPitneyBowesModel && (
                                        !this.state.selectedContentType ||
                                        (
                                            (
                                                this.state.selectedContentType == 'Warehouse Account' &&
                                                !this.state.selectedFacilityIndex
                                            ) || (
                                                this.state.selectedContentType == 'Customer Account' &&
                                                !this.state.selectedCustomerIndex
                                            )
                                        )
                                        ||
                                        !this.state.selectedCarrierIndex
                                        ||
                                        (this.isEnableProvider && !this.state.selectedProviderIndex)
                                    )
                                ) ||
                                (
                                    this.state.currentStep == 1 && this.state.isOpenPitneyBowesModel && (
                                        (
                                            this.state.selectedProviderIndex == 'pitneyBowes' && (
                                                !this.state.pitneyBowesUsername ||
                                                !this.state.pitneyBowesPassword
                                            )
                                        ) || (
                                            this.state.selectedProviderIndex == 'bukuShip' && (
                                                !this.state.providerUsername ||
                                                !this.state.providerOrganizationNumber ||
                                                !this.state.providerConnectToken
                                            )
                                        )
                                    )
                                ) ||
                                (
                                    this.state.currentStep == 2 && (
                                        (
                                            this.state.selectedCarrier == 'FedEx' ||
                                            (this.state.selectedCarrier == 'UPS' && this.state.selectedProviderIndex != "shipEngine")
                                        ) &&
                                        (typeof this.state.currentCarrierObj.AgreeToEula == 'undefined' || this.state.currentCarrierObj.AgreeToEula == false)
                                    )
                                ) ||
                                (
                                    this.state.currentStep == 2 && (
                                        (
                                            this.state.selectedCarrier == 'UPS' && this.state.selectedProviderIndex == "shipEngine"
                                        ) &&
                                        (
                                            !this.state.currentCarrierObj.Nickname ||
                                            !this.state.currentCarrierObj.AccountNumber ||
                                            !this.state.currentCarrierObj.AccountCountryCode ||
                                            !this.state.currentCarrierObj.AccountPostalCode
                                        )
                                    )
                                ) ||
                                (
                                    this.state.currentStep == 2 && (
                                        this.state.selectedCarrier != 'FedEx' &&
                                        this.state.selectedCarrier != 'UPS' &&
                                        this.state.selectedCarrier != 'DHL eCommerce' &&
                                        this.state.selectedCarrier != 'DHL Express' &&
                                        this.state.selectedCarrier != 'Australia Post' &&
                                        this.state.selectedCarrier != 'APC' &&
                                        this.state.selectedCarrier != 'DHL Express Australia' &&
                                        this.state.selectedCarrier != 'Firstmile' &&
                                        this.state.selectedCarrier != 'Globegistics' &&
                                        this.state.selectedCarrier != 'RR Donnelley' &&
                                        this.state.selectedCarrier != 'Canada Post' &&
                                        this.state.selectedCarrier != 'OnTrac' &&
                                        this.state.selectedCarrier != 'SEKO' &&
                                        this.state.selectedCarrier != 'Amazon Buy' &&
                                        this.state.selectedCarrier != 'Canpar' &&
                                        this.state.selectedCarrier != 'Purolator' &&
                                        this.state.selectedCarrier != 'PitneyBowesStd' &&
                                        this.state.selectedCarrier != 'USPS' &&
                                        this.state.selectedCarrier != 'Landmark' &&
                                        this.state.selectedCarrier != 'PARCLL' &&
                                        this.state.selectedCarrier != 'UPS Mail Innovations' &&
                                        this.state.selectedCarrier != 'ePost Global' &&
                                        this.state.selectedCarrier != 'Pitney Bowes Cross-Border' &&
                                        this.state.selectedCarrier != 'Stallion Express' &&
                                        this.state.selectedCarrier != 'FedEx SmartPost' &&
                                        this.state.selectedCarrier != 'UPS SurePost' &&
                                        this.state.selectedCarrier != 'Passport' &&
                                        this.state.selectedCarrier != 'Sendle' &&
                                        this.state.selectedCarrier != 'Tusk Logistics' &&
                                        this.state.selectedCarrier != 'Flexport' &&
                                        this.state.selectedCarrier != 'Lasership'
                                    ) &&
                                    (
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.Passphrase ||
                                        !this.state.currentCarrierObj.Nickname
                                    )
                                ) ||
                                (
                                    this.state.currentStep == 2 && this.state.selectedCarrier == 'USPS' && (
                                        this.state.selectedProviderIndex == "Endicia" || this.state.selectedProviderIndex == "Stamps.com"
                                    ) &&
                                    (
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.Passphrase ||
                                        !this.state.currentCarrierObj.Nickname
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'USPS' && this.state.selectedProviderIndex == 'pitneyBowes' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'USPS' && this.state.selectedProviderIndex == 'bukuShip' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.FirstName ||
                                        !this.state.currentCarrierObj.LastName ||
                                        !this.state.currentCarrierObj.Company ||
                                        !this.state.currentCarrierObj.Phone ||
                                        !this.state.currentCarrierObj.Email ||
                                        !this.state.currentCarrierObj.Address1 ||
                                        !this.state.currentCarrierObj.City ||
                                        !this.state.currentCarrierObj.State ||
                                        !this.state.currentCarrierObj.PostalCode ||
                                        !this.state.currentCarrierObj.CountryCode
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'DHL eCommerce' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.ClientId ||
                                        !this.state.currentCarrierObj.ApiSecret ||
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.PickupNumber ||
                                        !this.state.currentCarrierObj.DistributionCenter
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'DHL Express' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccountNumber
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Australia Post' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.ApiKey ||
                                        !this.state.currentCarrierObj.ApiSecret
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'APC' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.Username ||
                                        !this.state.currentCarrierObj.Passphrase
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Canada Post' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.ApiKey ||
                                        !this.state.currentCarrierObj.ApiSecret
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Purolator' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.Passphrase ||
                                        !this.state.currentCarrierObj.ApiKey
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Firstmile' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.MailerId ||
                                        !this.state.currentCarrierObj.Password
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'SEKO' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccessKey
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'DHL Express Australia' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccountNumber
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'RR Donnelley' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.Username ||
                                        !this.state.currentCarrierObj.Password
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'OnTrac' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.Password
                                    )
                                ) ||
                                (this.state.currentStep == 2 && this.state.selectedCarrier == 'Globegistics' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.Username ||
                                        !this.state.currentCarrierObj.Password
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Amazon Buy' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.Password ||
                                        !this.state.currentCarrierObj.Email
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Canpar' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.Username ||
                                        !this.state.currentCarrierObj.Password
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == "PitneyBowesStd" && this.state.selectedProviderIndex == "pitneyBowes" &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.accountNumber ||
                                        !this.state.currentCarrierObj.carrierFacilityId ||
                                        !this.state.currentCarrierObj.clientFacilityId ||
                                        !this.state.currentCarrierObj.nonDeliveryDefault
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Landmark' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.ClientId ||
                                        !this.state.currentCarrierObj.Username ||
                                        !this.state.currentCarrierObj.Password
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'PARCLL' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.ApiKey ||
                                        !this.state.currentCarrierObj.HubLocation ||
                                        !this.state.currentCarrierObj.AccountNumber
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'UPS Mail Innovations' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.Username ||
                                        !this.state.currentCarrierObj.Password ||
                                        !this.state.currentCarrierObj.MailInnovationsCostCenter ||
                                        !this.state.currentCarrierObj.MailInnovationsEndorsement
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'ePost Global' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.ApiToken
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Pitney Bowes Cross-Border' &&
                                    (
                                        this.state.pitneyBowesCrossBorderDisabledStatus
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Stallion Express' &&
                                    (
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.ApiKey
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'FedEx SmartPost' &&
                                    (
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.FirstName ||
                                        !this.state.currentCarrierObj.LastName ||
                                        !this.state.currentCarrierObj.Company ||
                                        !this.state.currentCarrierObj.Email ||
                                        !this.state.currentCarrierObj.ContactTitle ||
                                        !this.state.currentCarrierObj.Phone ||
                                        !this.state.currentCarrierObj.City ||
                                        !this.state.currentCarrierObj.State ||
                                        !this.state.currentCarrierObj.PostalCode ||
                                        !this.state.currentCarrierObj.CountryCode ||
                                        !this.state.currentCarrierObj.Address1 ||
                                        !this.state.currentCarrierObj.HubId ||
                                        !this.state.currentCarrierObj.ShippingAddress1 ||
                                        !this.state.currentCarrierObj.ShippingCity ||
                                        !this.state.currentCarrierObj.ShippingState ||
                                        !this.state.currentCarrierObj.ShippingPostalCode ||
                                        !this.state.currentCarrierObj.ShippingCountryCode
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'UPS SurePost' &&
                                    (
                                        !this.state.currentCarrierObj.AccountNumber ||
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.UserId ||
                                        !this.state.currentCarrierObj.Password
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Passport' &&
                                    (
                                        !this.state.currentCarrierObj.ApiKey ||
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.Company
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Sendle' &&
                                    (
                                        !this.state.currentCarrierObj.ApiKey ||
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.UserId
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Tusk Logistics' &&
                                    (
                                        !this.state.currentCarrierObj.ApiKey ||
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.AccountNumber
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Flexport' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.ApiToken ||
                                        !this.state.currentCarrierObj.InjectionLocation
                                    )
                                ) || (this.state.currentStep == 2 && this.state.selectedCarrier == 'Lasership' &&
                                    (
                                        !this.state.currentCarrierObj.Nickname ||
                                        !this.state.currentCarrierObj.WebServicesId ||
                                        !this.state.currentCarrierObj.WebServicesKey ||
                                        !this.state.currentCarrierObj.CustomerBranch ||
                                        !this.state.currentCarrierObj.Address1 ||
                                        !this.state.currentCarrierObj.City ||
                                        !this.state.currentCarrierObj.State ||
                                        !this.state.currentCarrierObj.Phone ||
                                        !this.state.currentCarrierObj.Email ||
                                        !this.state.currentCarrierObj.PostalCode ||
                                        !this.state.currentCarrierObj.CriticalPullTime ||
                                        !this.state.currentCarrierObj.CriticalEntryTime
                                    )
                                ) || (this.state.currentStep == 3 &&
                                        (this.state.selectedCarrier == 'FedEx' || (this.state.selectedCarrier == 'UPS' && this.state.selectedProviderIndex != 'shipEngine')) &&
                                        !this.state.currentCarrierObj.AccountNumber )


                            }
                            icon={this.state.isOpenPitneyBowesModel ? 'fa-sign-in' : 'fa-caret-right'} />

                    )}
                    {this.state.isAuthUrl && (
                        <WmsButton
                            label={'Check ' + this.state.selectedCarrier + ' Connection'}
                            wmsSelector={this.selector + 'CheckConnectionAuthorized'}
                            onClickFunc={this.checkConnectionAuthorized}
                            icon="fa fa-check"
                            loading={this.state.carrierConnectionBtnLoader}
                        />
                    )}
                    {this.props.sharedCarrierConnectionFlow == 'edit' && (
                        <WmsButton
                            label={this.translate('Label_Save')}
                            wmsSelector={this.selector + 'EditCarriersave'}
                            onClickFunc={this.updateCarrier}
                            icon="fa-floppy-o"
                            loading={this.state.carrierConnectionBtnLoader}
                            disabled={
                                (this.state.selectedCarrier == 'UPS' && this.state.currentCarrierObj.Provider != 'shipEngine' && this.state.currentCarrierObj.UseNegotiatedRates && (
                                    !this.state.currentCarrierObj.InvoiceDate ||
                                    !this.state.currentCarrierObj.InvoiceNumber ||
                                    !this.state.currentCarrierObj.ControlId ||
                                    !this.state.currentCarrierObj.InvoiceAmount
                                ))
                            }
                        />
                    )}

                </div>
            </WmsModel>
        );
    }
}