
import React from 'react';

// component
import WmsDropdown from './../components/WmsDropdown/WmsDropdown.jsx';
import { LocationsGrid } from './locations-grid.component.jsx';
import { LocationsForm } from './locations-form.component.jsx';

import style from './locations.scss';

import * as locationService from './locations.services.jsx';

export class locationsHolder extends React.Component {

    constructor(props) {

        super(props);
        
        this.translate = window.translate;
        this.$rootScope = window;

        this.state = {
            facilityOption: [],
            windowId: this.$rootScope.ChildBulletin.Data.Window,
            selectedFacilityId: '',
            sharedLocationEditUri: null,
            sharedLocationOptions: null,
            reloadGrid: '',
            receiptObj: '' // for reopen receipt after save location if  it's use
        }
    }

    componentDidMount() {
        var that = this;
        // open create location form from edit receipt > add line item
        document.addEventListener("showLocation", this.onOpenLocationFromReceipt);
        kendo.ui.progress($('#' + this.state.windowId), true);
        locationService.initialFindLocations().then((response) => {

            response.ManageLocationsOptions.BillingTypes.unshift({
                "Name": this.translate('Label_None'),
                "Value": null
            });
            this.setState({
                sharedLocationOptions: response.ManageLocationsOptions,
                sharedLocationFilterData: response.ManageLocationsSearchInfo,
                facilityOption: response.GetCustomerFacilityList
            });

            if (this.$rootScope.ChildBulletin.Data.receiptObj) {
                this.setState({
                    selectedFacilityId: this.$rootScope.ChildBulletin.Data.receiptObj.FacilityId,
                    receiptObj: this.$rootScope.ChildBulletin.Data.receiptObj
                });
                this.onCreateLocation();
            }


        }).finally(() => {
            kendo.ui.progress($('#' + this.state.windowId), false);
        });
    }
    onOpenLocationFromReceipt = (e) => {
        this.setState({
            selectedFacilityId: e.detail.receiptObj.FacilityId,
            receiptObj: e.detail.receiptObj,
        });
        this.onCreateLocation();
    }
    componentWillUnmount() {
        document.removeEventListener("showLocation", this.onOpenLocationFromReceipt);
    }
    onFaciltiySelectChange = (param_facilityId) => {
        this.setState({ selectedFacilityId: param_facilityId });
    }

    onCreateLocation = () => {
        this.setState({ sharedLocationEditUri: '' });
    }

    onEditLocation = (param_location_edituri) => {
        this.setState({ sharedLocationEditUri: param_location_edituri });
    }

    onExitLocationForm = () => {
        this.setState({
            sharedLocationEditUri: null,
            reloadGrid: ''
        });
    }

    onReloadGrid = (basecall) => {
        this.setState({ reloadGrid: basecall });
    }

    render() {
        return (
            <div id="location-holder-react" >
                <div className="locations-main-content">
                            <LocationsGrid
                                onCreateLocation={this.onCreateLocation}
                                onEditLocation={this.onEditLocation}
                                sharedLocationFilterData={this.state.sharedLocationFilterData}
                                selectedFacilityId={this.state.selectedFacilityId}
                                reloadGrid={this.state.reloadGrid}
                                windowId={this.state.windowId}
                                sharedFacilityOption={this.state.facilityOption}
                                onFaciltiySelectChange={this.onFaciltiySelectChange}
                            />

                    {this.state.sharedLocationEditUri != null && this.state.selectedFacilityId != '' && (
                            <LocationsForm
                                sharedLocationEditUri={this.state.sharedLocationEditUri}
                                sharedLocationOptions={this.state.sharedLocationOptions}
                                selectedFacilityId={this.state.selectedFacilityId}
                                onExitLocationForm={this.onExitLocationForm}
                                onReloadGrid={this.onReloadGrid}
                                windowId={this.state.windowId}
                                receiptObj={this.state.receiptObj}
                            />
                    )}
                </div>
            </div >
        )

    }
}